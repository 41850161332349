import React, { useEffect, useState } from 'react';
import { Badge, Button, Checkbox, Col, DatePicker, Divider, Flex, Form, Input, InputNumber, Popconfirm, Radio, Row, Select, Space, Steps, Switch, Table, Tag, Typography, notification } from 'antd';
import { CheckCircleFilled, EditOutlined, ProfileOutlined, ProfileTwoTone, SolutionOutlined, UserOutlined, WarningFilled } from '@ant-design/icons';
import mcPaymentImage from '../../assets/images/mc_payment.svg';
import '../../css/container.css';
import '../../css/SmallComponents.css';
import httpCall from '../../utils/api';
import { useFormik } from 'formik';
import PageTitle from '../common/PageTitle';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import EditableCell from '../common/EditableCell';

const MCPayment = () => {
    const [vendorNameOptions, setVendorNameOptions] = useState([]);    
	const [api, contextHolder] = notification.useNotification();
    const [current, setCurrent] = useState(0);
    const [purchaseOrderList, setPurchaseOrderList] = useState([]);    
    const [selectedPurchaseOrderList, setSelectedPurchaseOrderList] = useState([]);    
    const [paymentOptions, setPaymentOptions] = useState([]);
    const [accountList, setAccountList] = useState([]);
    const [loading, setLoading] = useState(false);    

    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState('');
	const isEditing = (record) => record.purchase_order_no === editingKey;

    const steps = [
        {
          title: 'Select Vendor',
          content: 'First-content',
          icon: <UserOutlined />
        },
        {
          title: 'Select Bills To Pay',
          content: 'Second-content',
          icon: <SolutionOutlined />
        },
        {
          title: 'View Payment Summary',
          content: 'Last-content',
        },
    ];

    const prev = () => {
        setCurrent(current - 1);
    };

    const next = () => {
        setCurrent(current + 1);
    };

    const edit = function(record){        
		form.setFieldsValue({
            paid_mc_amount: 0,
			...record,
		});
		setEditingKey(record.purchase_order_no);
	};

	const cancel = () => {
		setEditingKey('');
	};

    const totalPendingMCAmountPerVendor = (totalPendingPayments) => {
        return totalPendingPayments.reduce((acc, next) => acc + parseFloat(next.po_mc_amount || 0), 0).toFixed(3);
    }

    const totalMCAmountPaidForSelectedBills = () => {
        return purchaseOrderList.reduce((acc, next) => acc + parseFloat(next.paid_mc_amount || 0), 0);
    }

    const totalMCAmountToPay = () => {
        return parseFloat(formik.values.amount_1_received || 0);
    }

    const update = async (key) => {
		try {
			const row = await form.validateFields();
            const updated_paid_mc_amount = parseFloat(row.paid_mc_amount || 0);
            const poToUpdate = purchaseOrderList.find(po => po.purchase_order_no === key);            
            if(poToUpdate.po_mc_amount < updated_paid_mc_amount) {
                openErrorNotification('Cannot exceeds payment amount');
                cancel();
                return;
            }
            let paidTotal = totalMCAmountPaidForSelectedBills();
            paidTotal = paidTotal - poToUpdate.paid_mc_amount + updated_paid_mc_amount;            

            if(totalMCAmountToPay() < paidTotal) {
                openErrorNotification('Total Paid MC Amount Exceeds Customer Payment');
                cancel();
                return;
            }
            purchaseOrderList.forEach(po => {
                if(po.purchase_order_no === key) {                           
                    po.paid_mc_amount = row.paid_mc_amount;
                    po.pending_mc_amount = po.po_mc_amount - row.paid_mc_amount;
                }
            });
            setPurchaseOrderList([...purchaseOrderList]);
            cancel();
		} catch (errInfo) {
			console.log('Validate Failed:', errInfo);
		}
	};

    const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
        icon: item.icon,
    }));

    const openErrorNotification = (description) => {
		api.error({
		  message: 'Error !',
		  duration: 4,		  
		  description: description,
		});
	};

    const formik = useFormik({
		initialValues: {
            mc_receipt_no: '1',
            payment_mode_1: 'Cash',
            account_1: '',
            vendor_id: '',
            vendor_name: '',        
            mc_receipt_date: moment().format('YYYY-MM-DD'),
            amount_1_received: '',         
            total_paid_amount: '',
            total_pending_amount: ''               
		},
		onSubmit: (values, {resetForm}) => {
            console.log(values);
            values['mcPaymentList'] = selectedPurchaseOrderList;            
            createPurchasePayment(values);
		},
	});

    useEffect(() => {
        formik.setFieldValue('total_paid_amount', totalMCAmountPaidForSelectedBills());
    }, [purchaseOrderList]);

    useEffect(() => {
        fetchPaymentOptions();
        fetchNextPaymentNo(); 
        fetchVendorList();
        fetchAccountList();      
	}, []);

    useEffect(() => {
        if(formik.values.vendor_id) {
            fetchPurchaseOrdersByVendor(formik.values.vendor_id);
        }        
    }, [formik.values.vendor_id]);

    const fetchAccountList = () => {
		setLoading(true);
		httpCall({
			method: 'GET',
			url: '/api/account/list',
		})
			.then((response) => {
				if (response) {
					setAccountList(response?.data.map(acc => ({label: `${acc.bank_name} - ${acc.account_no}`, value: acc.account_no})));
				} else {
					console.error(
						'Error retrieving account list',
						response.message
					);
				}
				setLoading(false);
			})
			.catch((error) => {
				console.error('Error retrieving account list', error);
				setLoading(false);
			});
	};

    const openSuccessNotification = (description) => {
		api.open({
		  message: 'Success !',
		  duration: 2,		  
		  description: description,
		  icon: <CheckCircleFilled style={{ color: '#808000' }} />,		  
		});
	};

    const fetchPaymentOptions = () => {
		httpCall({
			method: 'GET',
			url: '/api/payment/list?filter=true',
		})
			.then((response) => {
                console.log(response?.data)
                setPaymentOptions(response?.data);
			})
			.catch((error) => {
				console.error('Error retrieving payment list:', error);
			});
	};

    const fetchNextPaymentNo = () => {
		httpCall({
			method: 'GET',
			url: '/api/purchase/getMCPurchasePaymentNr',
		})
			.then((response) => {
				if (response) {
                    formik.setFieldValue('mc_receipt_no', response.data);
				} else {
					console.error(
						'Error retrieving mc payment details',
						response.message
					);
				}
			})
			.catch((error) => {
				console.error('Error retrieving mc payment details', error);
			});
	};

    const fetchVendorList = () => {
		httpCall({
			method: 'GET',
			url: '/api/vendor/get',
		})
			.then((response) => {
				if (response) {
                    setVendorNameOptions(response?.data.map(c => ({label: c.company_name, value: c.id})))
				} else {
					console.error(
						'Error retrieving vendor list:',
						response.message
					);
				}
			})
			.catch((error) => {
				console.error('Error retrieving vendor list:', error);
			});
	};

    const createPurchasePayment = (values) => {
		httpCall({
			method: 'POST',
			url: '/api/purchase/mcReceipt',
            payload: values
		})
			.then((response) => {
				if (response) {
                    openSuccessNotification('MC Payment Created Successfully !')
				} else {
					console.error(
						'Error creating mc payment:',
						response.message
					);
				}
			})
			.catch((error) => {
				console.error('Error creating mc payment:', error);
			});
	};

    const fetchPurchaseOrdersByVendor = (id) => {
		httpCall({
			method: 'GET',
			url: '/api/purchase/getMCPurchaseOrderInfo/' + id,
		})
			.then((response) => {
                if(response.status === 204) {
                    formik.setFieldValue('total_pending_amount', 'No Pending Amount :)');
                } else {
                    const po = response?.data;
                    if(po?.pendingMCPayments?.length === 0) {
                        formik.setFieldValue('total_pending_amount', 'No Pending Amount :)');
                    } else {
                        const list = po?.pendingMCPayments.map(po =>({
                            purchase_order_no: po.purchase_order_no,
                            po_mc_amount: po.pending_mc_amount,
                            paid_mc_amount: 0,
                            pending_mc_amount: po.pending_mc_amount
                        }));
                        setPurchaseOrderList([...list]);
                        formik.setFieldValue('total_pending_amount', totalPendingMCAmountPerVendor([...list]));
                    }                   
                }
			})
			.catch((error) => {
				openErrorNotification(`Error retrieving recepits per vendor`);
			});
	};

    const purchasePaymentColumns = [
        {
			title: 'Purchase Nr',
			dataIndex: 'purchase_order_no',
			key: 'purchase_order_no',
			align: 'center',
			width: 150
		},
        {
			title: 'MC Amount',
			dataIndex: 'po_mc_amount',
			key: 'po_mc_amount',		
			align: 'center',
			width: 150
		},
        {
			title: 'Paid MC Amount',
			dataIndex: 'paid_mc_amount',
			key: 'paid_mc_amount',		
			align: 'center',
            editable: true,
			width: 150
		},
        {
			title: 'Action',
			key: 'action',
			align: 'center',
			width: 150,
			render: (_, record) => {
				const editable = isEditing(record);
				return editable ? (
					<span>
						<Typography.Link
							onClick={() => update(record.purchase_order_no)}
							style={{
								marginRight: 8,
							}}
						> Save
						</Typography.Link>
						<Popconfirm title="Sure to cancel?" onConfirm={cancel}>
							<a>Cancel</a>
						</Popconfirm>
					</span>
				) : (
                    <EditOutlined
                    style={{
                        cursor: 'pointer',
                        fontSize: '20px',
                        color: '#08c',
                    }}
                    onClick={() => edit(record)}
                />
		    	);
            }		
		},
        {
			title: 'Status',
			dataIndex: 'status',
			key: 'status',		
			align: 'center',
			width: 150,
            render: (_, record) => {
                return record.po_mc_amount > record.paid_mc_amount ? (
                    <Tag color='#f50'>PENDING</Tag>
                ) : (<Tag color="#87d068">FULLY PAID</Tag>);
            }
		},
	];

    const step1 = () => {
        return (
            <>
                <Row gutter={16} justify="center" className="gutter-row">
                    <Col span={24} className="gutter-box">
                        <div style={{textAlign: 'right'}}>
                            <span className="hintText color-black">MC Payment No</span>                            
                        </div> 
                        <Input
                            type="text"
                            id="mc_receipt_no"
                            disabled={true}
                            variant="borderless"
                            style={{ width: '100%', textAlign: 'right', color: '#000000', paddingLeft: '0px', fontSize: '14px'}}
                            value={formik.values.mc_receipt_no}
                        />                                
                    </Col>
                </Row>
                <Row gutter={16} justify="center" className="gutter-row">
                    <Col span={8} className="gutter-box">
                        <div>
                            <span className="hintText color-black">Vendor</span>
                        </div>                                
                        <Select
                            id="vendor_id"
                            placeholder="Select a Vendor"
                            style={{ width: '100%', height: '38px' }}
                            options={vendorNameOptions}
                            onChange={(e)=> {
                                formik.setFieldValue("vendor_id", e);
                                const vendor = vendorNameOptions.find(c => c.value === e);
                                formik.setFieldValue("vendor_name", vendor.label);
                            }}
                        />
                    </Col> 
                    <Col span={8} className="gutter-box">
                        <div>
                            <span className="hintText color-black">Total Pending MC Amount</span>
                        </div>                                
                        <Input
                            disabled={true}
                            type="text"
                            id="total_pending_amount"
                            style={{ width: '100%', color: 'green', fontSize: '18px', height: '38px'}}
                            value={formik.values['total_pending_amount']}
                        />
                    </Col>
                    <Col span={8} className="gutter-box">
                        <div>
                            <span className="hintText color-black">Payment Date</span>                            
                        </div> 
                        <DatePicker
                            style={{ width: '100%', height: '38px' }}
                            format={{
                                format: 'YYYY-MM-DD',
                                type: 'mask',
                            }}
                            defaultValue={moment()}
                            onChange={(date, dateString) => {
                                formik.setFieldValue("mc_receipt_date", dateString);  
                            }}
                        />                             
                    </Col>                  
                </Row>
                <Row gutter={16} justify="end" className="gutter-row">
                    <Col span={8} className="gutter-box">
                        <div>
                            <span className="hintText color-black" style={{fontWeight: '600'}}>PAYMENT MODE 1</span>                            
                        </div> 
                        <Radio.Group 
                            style={{marginTop: '5px', fontSize:' 16px'}}
                            onChange={(e) => {formik.setFieldValue('payment_mode_1', e.target.value)}} value={formik.values.payment_mode_1}>
                            {paymentOptions.map((option, index) => <Radio key={index} style={{fontSize:'13px', fontFamily:'poppins'}} value={option.mop}>{option.mop}</Radio>)}                
                        </Radio.Group>
                    </Col>
                    <Col span={8} className="gutter-box">
                        <div>
                            <span className="hintText color-black">Bank</span>                            
                        </div> 
                        <Select
                            id="account_1"
                            disabled={formik.values.payment_mode_1 === 'Cash'}
                            placeholder="Select an Account"
                            style={{ width: '75%', height: '38px' }}
                            options={accountList}
                            onChange={(e)=> {
                                formik.setFieldValue("account_1", e);
                            }}
                        />
                    </Col>
                    <Col span={8} className="gutter-box">
                        <div>
                            <span className="hintText color-black">Payment 1</span>
                        </div>                                
                        <Input
                            type="text"
                            prefix="₹"
                            placeholder='0.00'
                            id="amount_1_received"
                            style={{ width: '100%', height: '38px'}}
                            value={formik.values['amount_1_received']}
                            onChange={formik.handleChange}
                        />  
                    </Col>
                </Row>
            </>
        )
    }

    const step2 = () => {        
        return purchaseOrderTable(purchaseOrderList);
    };

    const step3 = () => {
        return (
            <>
                <Row gutter={16} justify="center" className="gutter-row">
                    <Col span={12} className="gutter-box">
                        <div style={{textAlign: 'right', height: '40px', backgroundColor: '#d3d3d3', border: '1px solid #d3d3d3', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <span style={{fontFamily: 'poppins', fontSize: '15px', fontWeight: '700'}}>MC PAYMENT SUMMARY</span>                
                        </div>
                    </Col>
                </Row>
                <Row gutter={16} justify="center" className="gutter-row">
                    <Col span={10} className="gutter-box">
                        <Row gutter={16} className="gutter-row">
                            <Col span={12} style={{textAlign: 'left'}} className="gutter-box">
                                <div style={{fontSize: '14px', paddingBottom: '8px', fontWeight: '600'}}>Payment No</div>
                                <span>{formik.values.mc_receipt_no}</span>
                            </Col>
                            <Col span={12} style={{textAlign: 'right'}} className="gutter-box">
                                <div style={{fontSize: '14px', paddingBottom: '8px', fontWeight: '600'}}>Payment Date</div>
                                <span>{moment(formik.values.mc_receipt_date).format('YYYY-MM-DD')}</span>
                            </Col>
                        </Row>
                        <Row gutter={16} className="gutter-row">
                            <Col span={24} style={{textAlign: 'left'}} className="gutter-box">
                                <div style={{fontSize: '14px', paddingBottom: '8px', fontWeight: '600'}}>Vendor Name</div>
                                <span>{formik.values.vendor_name}</span>
                            </Col>
                        </Row>
                        <Row gutter={16} justify="center" className="gutter-row" style={{marginTop: '20px'}}>
                            <Col span={12} style={{fontSize: '14px', fontWeight: '700', textAlign: 'center', border: '1px solid #d3d3d3'}} className="gutter-box">
                                <span>PO Number</span>
                            </Col>
                            <Col span={12} style={{fontSize: '14px', fontWeight: '700', textAlign: 'center', border: '1px solid #d3d3d3'}} className="gutter-box">
                                <span>Paid MC Amount</span>
                            </Col>
                        </Row>
                        {selectedPurchaseOrderList.map(invoice => (
                            <Row gutter={16} justify="center" className="gutter-row">
                                <Col span={12} style={{textAlign: 'center', border: '1px solid #d3d3d3'}} className="gutter-box">
                                    <span>{invoice.purchase_order_no}</span>
                                </Col>
                                <Col span={12} style={{textAlign: 'center', border: '1px solid #d3d3d3'}} className="gutter-box">
                                    <span>{invoice.paid_mc_amount}</span>
                                </Col>
                            </Row>
                        ))}
                        <Row gutter={16} justify="center" className="gutter-row" style={{marginTop: '1px'}}>
                            <Col span={12} style={{fontSize: '15px', fontWeight: '700', textAlign: 'center', border: '1px dotted #d3d3d3'}} className="gutter-box">
                                <span>Total MC Amount</span>
                            </Col>
                            <Col span={12} style={{fontSize: '15px', fontWeight: '700', textAlign: 'center', border: '1px dotted #d3d3d3'}} className="gutter-box">
                                <span>{formik.values.total_paid_amount}</span>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        )
    }

    const rowSelection = {
        hideSelectAll: true,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedPurchaseOrderList(selectedRows);
        },
        onSelect: (record, selected, selectedRows) => {
            if(selected) {
                let possibleMCAmount = 0;
                let isPending = false;
                const totalAmountToPay = totalMCAmountToPay();
                const totalAmountPaidForSelectedBills = totalMCAmountPaidForSelectedBills();
                const selectedPaidMCAmount = record.po_mc_amount;

                if(totalAmountToPay < (totalAmountPaidForSelectedBills + selectedPaidMCAmount)) {
                    openErrorNotification('Total Paid Amount Exceeds, So Updating Amount');
                    possibleMCAmount = totalAmountToPay - totalAmountPaidForSelectedBills;
                    isPending = true;
                }

                purchaseOrderList.forEach(po => {
                    if(po.purchase_order_no === record.purchase_order_no) { 
                        if(isPending) {
                            po.paid_mc_amount = possibleMCAmount;
                            po.pending_mc_amount = po.po_mc_amount - possibleMCAmount;
                        } else {
                            po.paid_mc_amount = po.po_mc_amount;
                            po.pending_mc_amount = 0;
                        }
                    }
                });                
                setPurchaseOrderList([...purchaseOrderList]);
            } else {
                purchaseOrderList.forEach(po => {
                    if(po.purchase_order_no === record.purchase_order_no) {
                        po.paid_mc_amount = 0;
                        po.pending_mc_amount = po.po_mc_amount;
                    }
                })
                setPurchaseOrderList([...purchaseOrderList]);
            }            
        }, 
    };

    const mergedColumns = purchasePaymentColumns.map((col) => {
		if (!col.editable) {
		  return col;
		}
		return {
		  ...col,
		  onCell: (record) => ({
			record,
			inputType: 'text',
			dataIndex: col.dataIndex,            
			title: col.title,
			editing: isEditing(record),
		  }),
		};
	});

    const purchaseOrderTable = (datasoure) => {
        return datasoure.length > 0 ? (
            <>
                <Row gutter={16} className="gutter-row sales-list">
                    <Col span={24} className="gutter-box">
                    <Form form={form} component={false}>
                        <Table                       
                            rowSelection={{type: 'checkbox', ...rowSelection}}
                            components={{
                                body: {
                                    cell: EditableCell,
                                },
                            }}
                            dataSource={datasoure}
                            columns={mergedColumns}
                            pagination={false}
                            rowKey="purchase_order_no"
                            rowClassName="editable-row"
                            size='small'
                            bordered="true"						
                            scroll={{ y: 250 }}
                            style={{fontFamily: 'Poppins', fontSize: '18px', height: '250px'}}/>
                    </Form>

                    </Col>
                </Row>
            </>
        ) : null
    }

    const createNewFormSection = () => {
		return (
			<>
                {/* <Row gutter={16} justify="center" className="gutter-row">
                    <Col span={24} className="gutter-box">
                        <div style={{textAlign: 'right', height: '40px', backgroundColor: '#F5F5F5', border: '1px solid #d3d3d3', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <span style={{fontFamily: 'poppins', fontSize: '15px', fontWeight: '700'}}>VENDOR PAYMENT</span>                
                        </div>
                    </Col>
                </Row> */}
                <Row gutter={16} justify="center" className="gutter-row">
                    <Col span={24} className="gutter-box">
                        <Steps current={current} items={items} style={{margin: '15px 0px'}} />
                    </Col>
                </Row>
                <Row gutter={16} justify={"center"} className="gutter-row">
                    <Col span={24}>
                        {current === 0 && step1()}
                        {current === 1 && step2()}
                        {current === 2 && step3()}
                    </Col>
               </Row>
               <Row gutter={16} className="gutter-row">
                            <Col span={2} className="gutter-box">
                            {current > 0 && (<Button 
                                    type='primary' 
                                    style={{marginTop: '15px', width: '100%'}} 
                                    onClick={() => {
                                        prev()
                                    }}
                                    >
                                        Back
                                </Button>)}
                            </Col>
                            <Col span={2} offset={20} className="gutter-box">
                            {current < steps.length - 1 && (<Button 
                                    type='primary' 
                                    style={{marginTop: '15px', width: '100%'}} 
                                    onClick={() => {
                                        if(current === 0) {
                                            if(formik.values.vendor_id === '') {
                                                openErrorNotification('Please select a vendor to proceed');
                                                return;
                                            }
                                            const amount_1_received = parseFloat(formik.values.amount_1_received || 0);
                                            if(1 > amount_1_received) {
                                                openErrorNotification('Minimum Payment Should be > 1 INR');
                                                return;
                                            }
                                        }
                                        if(current === 1) {
                                           if(selectedPurchaseOrderList.length === 0) {
                                                openErrorNotification('No bills selected for payment...');
                                                return;
                                           }
                                           const poList = purchaseOrderList.filter(po => po.paid_mc_amount > 0);
                                           setPurchaseOrderList(poList);
                                        }
                                        next()
                                    }}
                                    >
                                        Next
                                </Button>)}
                            </Col>
                        </Row>
			</>
		);
	};

	return (
		<>
		<PageTitle
			imagesrc={mcPaymentImage}
			pageTitle="MC Payment"
            buttonList={[{
                buttonName: 'Back',
                className: 'btn-back',
                url: '/slj/mc-receipt'
            }]}
		/>
		    {contextHolder}
            <div className="content-area">
                <div className="formAreaStyle">
                    {/* <Badge.Ribbon text="Vendor Payment" placement='start' color="volcano"> */}
                        <div>
                            { createNewFormSection() }
                        </div>
                    {/* </Badge.Ribbon> */}
                </div>
            </div>
			<div className="formSubmitArea">
				<div className="formSubmitInnerArea">
					<Button
						type="primary"
						size="large"
						style={{ width: '170px', backgroundColor: 'grey' }}
						onClick={formik.handleReset}
					>
						Reset Fields
					</Button>
					<Button
						type="primary"
						size="large"
						style={{ width: '170px', backgroundColor: '#1f43e5' }}
						onClick={formik.handleSubmit}
					>
						Submit
					</Button>
				</div>
			</div>
		</>
	);


}

export default MCPayment;