import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Popconfirm, Row, Space, Spin, Table, notification } from 'antd';
import PageTitle from '../common/PageTitle';
import SalesImage from '../../assets/images/estimate.svg';
import columnSearch from '../common/columnSearch';
import httpCall from '../../utils/api';
import '../../css/container.css';
import '../../css/SmallComponents.css';
import '../../css/Vendor.css';
import { CheckCircleFilled, DeleteOutlined, EditOutlined, EyeOutlined, ProfileOutlined } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';
import moment from 'moment';

const EstimationList = () => {
	const navigate = useNavigate();
	const searchInput = useRef(null);
	const [estimationList, setEstimationList] = useState([]);
	const [api, contextHolder] = notification.useNotification();
	const [loading, setLoading] = useState(false);
	const [itemTypes, setItemTypes] = useState([]);
	const [salesItemList, setSalesItemList] = useState([]);
    const [salesReturnItemList, setSalesReturnItemList] = useState([]);
	const [estimationData, setEstimationData] = useState({});
	const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        removeAfterPrint: true,
    });

	const tableColumnSearch = columnSearch(searchInput);
	
	useEffect(() => {
		setLoading(true);
		fetchQuotationList();
	}, []);

	const fetchQuotationList = () => {
		httpCall({
			method: 'GET',
			url: '/api/sales/estimation/list',
		})
			.then((response) => {
				setLoading(false);
				if (response) {
					setEstimationList(response?.data);
				} else {
					console.error(
						'Error retrieving estimation data:',
						response.message
					);
				}
			})
			.catch((error) => {
				setLoading(false);
				console.error('Error retrieving estimation data:', error);
			});
	};

	const fetchSalesEstimationData = (invoiceno) => {
		httpCall({
			method: 'GET',
			url: '/api/sales/estimation?invoiceno=' + invoiceno,
		})
			.then((response) => {
				setLoading(false);
				if (response) {					
					setItemTypes(response?.data.itemTypes);
					setSalesItemList(response?.data.items);
					setSalesReturnItemList(response?.data.return_items);
					setEstimationData(response?.data.estimationDetails);
					setTimeout(() => handlePrint(), 0);
				} else {
					console.error(
						'Error retrieving sales estimation data:',
						response.message
					);
				}
			})
			.catch((error) => {
				setLoading(false);
				console.error('Error retrieving sales data:', error);
			});
	};

	const openNotification = () => {
		api.open({
		  message: 'Delete Success !',
		  duration: 2,
		  description:
			'Sales Data Successfully, Refreshing Grid. ',
		  icon: <CheckCircleFilled style={{ color: '#808000' }} />,		  
		});
	};

	const handleEdit = (id) => {
		navigate('/slj/estimation/form', { state: { id } })
	};

	const handleDelete = (id) => {
		httpCall({
			method: 'DELETE',
			url: '/api/sales/estimation/delete/all?sales_id='+ id,
		}).then(reponse => {
			openNotification();
			fetchQuotationList();
		}).catch(error => {
			console.log('Error>', error)
		})
	};
  
  	const columnsNew = [
        {
			title: 'Estimation Nr',
			dataIndex: 'invoice_no',
			key: 'invoice_no',
			...tableColumnSearch('invoice_no'),
			align: 'center',
			width: 150,
			sorter: (a, b) => a.invoice_no.localeCompare(b.invoice_no),
			defaultSortOrder: 'ascend', // Default sorting in ascending order
		},
		{
			title: 'Cost Center Name',
			dataIndex: 'cost_center_name',
			key: 'cost_center_name',
			...tableColumnSearch('cost_center_name'),
			align: 'center',
			width: 200,
			sorter: (a, b) => a.cost_center_name.localeCompare(b.cost_center_name),
			defaultSortOrder: 'ascend', // Default sorting in ascending order
		},
		{
			title: 'Customer Name',
			dataIndex: 'customer_name',
			key: 'customer_name',
			align: 'center',
			width: 200,
			sorter: (a, b) => a.customer_name.localeCompare(b.customer_name),
			defaultSortOrder: 'ascend', // Default sorting in ascending order
		},
		{
			title: 'Customer Phone Nr',
			dataIndex: 'mobile_number',
			key: 'mobile_number',
			align: 'center',
			width: 200
		},
		{
			title: 'Total Amount',
			dataIndex: 'total_amount',
			key: 'total_amount',
			align: 'center',
			width: 200,
			render: (text) => parseFloat(text).toFixed(2) // Format to 2 decimal places
		},		
		{
			title: 'Action',
			key: 'action',
			align: 'center',
			width: 150,
			render: (_, record) => 
				estimationList.length >= 1 ? (
						<Space size="middle">				
							<ProfileOutlined style={{cursor: 'pointer', fontSize: '20px', color: '#08c'}} title='Print Estimation' onClick={() => fetchSalesEstimationData(record.invoice_no)} />			
							<EditOutlined style={{cursor: 'pointer', fontSize: '20px', color: '#08c'}} onClick={() => handleEdit(record.invoice_no)} />
							<Popconfirm title="Are you sure you want to delete?" onConfirm={() => handleDelete(record.invoice_no)}>
								<DeleteOutlined style={{fontSize: '20px', color: '#FF6868'}}/>
							</Popconfirm>
						</Space>
				) : null			
		  },
	];

	const quotationTable = () => {
		return (
				<Spin tip="Fetching Data From Server, Please Wait ..." spinning={loading} size="large">
					<Table 
						dataSource={estimationList}
						columns={columnsNew}
						rowKey="invoice_no"
						pagination={{showSizeChanger: true, position: ['bottomCenter'], pageSizeOptions: ['10', '20', '30'], showTotal: handleTotal}}
						style={{fontFamily: 'Poppins'}}/>
				</Spin>
		)
	}


	// const getCurrentRate = (type) => itemTypes.find(it => it.name.toLowerCase() === type.toLowerCase())?.current_rate;
	
    // const salesAmountWOTax = () => parseFloat(estimationData?.items_amount) - salesItemList.reduce((acc, next) => acc + parseFloat(next.tax_amount), 0);

	// const totalTax = () => salesItemList.reduce((acc, next) => acc + parseFloat(next.tax_amount), 0) / 2;

    // const totalOldGoldWeight = () => salesReturnItemList.reduce((acc, next) => parseFloat(acc) + parseFloat(next.weight), 0) || 0;  

	const getPageMargins = () => {
		return `@page { size: A4; margin: 20px !important; }`;
	};

	const getCurrentRate = (type) => itemTypes.find(it => it.name.toLowerCase() === type.toLowerCase())?.current_rate;

const calculateItemAmount = (item) => {
    // Calculate total weight including waste
    const netWeight = parseFloat(item.weight) || 0;
    const wasteAmount = item.waste_unit === '%' 
        ? (netWeight * parseFloat(item.waste || 0)) / 100 
        : parseFloat(item.waste || 0);
    const totalWeight = netWeight + wasteAmount;

    // Calculate base amount
    const rate = parseFloat(item.current_rate) || 0;
    const baseAmount = totalWeight * rate;

    // Add making charges and stone cost
    const mcAmount = parseFloat(item.mc_amount) || 0;
    const stoneCost = parseFloat(item.stone) || 0;
    
    // Calculate total amount before tax
    const preTaxAmount = baseAmount + mcAmount + stoneCost;

    // Calculate tax amounts
    const cgst = (preTaxAmount * parseFloat(item.cgst || 0)) / 100;
    const sgst = (preTaxAmount * parseFloat(item.sgst || 0)) / 100;
    const totalTaxAmount = cgst + sgst;

    // Calculate final amount
    const finalAmount = preTaxAmount + totalTaxAmount;

    return {
        preTaxAmount,
        taxAmount: totalTaxAmount,
        finalAmount
    };
};

const salesAmountWOTax = () => {
    return salesItemList.reduce((acc, item) => {
        const { preTaxAmount } = calculateItemAmount(item);
        return acc + preTaxAmount;
    }, 0);
};

const totalTax = () => {
    return salesItemList.reduce((acc, item) => {
        const { taxAmount } = calculateItemAmount(item);
        return acc + (taxAmount / 2); // Divided by 2 since we need CGST/SGST separately
    }, 0);
};

const totalOldGoldWeight = () => 
    salesReturnItemList.reduce((acc, next) => acc + parseFloat(next.weight || 0), 0);

const calculateFinalAmount = (item) => {
    const netWeight = parseFloat(item.gross_weight || 0) - parseFloat(item.stone_weight || 0);
    const rate = parseFloat(item.current_rate || 0);
    return netWeight * rate;
};


const EstimationReport = () => {
    // Helper calculation functions
    const calculateItemTotal = (item) => {
        const netWeight = (parseFloat(item.gross_weight || 0) - parseFloat(item.stone_weight || 0));
        const wasteAmount = item.waste_unit === '%' 
            ? (netWeight * parseFloat(item.waste || 0)) / 100 
            : parseFloat(item.waste || 0);
        const totalWeight = netWeight + wasteAmount;
        const baseAmount = totalWeight * parseFloat(item.current_rate || 0);
        const mcAmount = parseFloat(item.mc_amount || 0);
        const stoneCost = parseFloat(item.stone || 0);
        
        const subtotal = baseAmount + mcAmount + stoneCost;
        const cgst = (subtotal * parseFloat(item.cgst || 0)) / 100;
        const sgst = (subtotal * parseFloat(item.sgst || 0)) / 100;
        
        return {
            preTaxAmount: subtotal,
            taxAmount: cgst + sgst,
            finalAmount: subtotal + cgst + sgst
        };
    };

    const salesAmountWOTax = () => 
        salesItemList.reduce((acc, item) => acc + calculateItemTotal(item).preTaxAmount, 0);

    const totalTax = () => 
        salesItemList.reduce((acc, item) => acc + (calculateItemTotal(item).taxAmount / 2), 0);

    const totalOldGoldWeight = () => 
        salesReturnItemList.reduce((acc, next) => acc + parseFloat(next.weight || 0), 0);

    const getNetTotal = () => {
        const subTotal = salesAmountWOTax();
        const taxes = totalTax() * 2; // Both CGST and SGST
        const discount = parseFloat(estimationData?.discount || 0);
        const oldGoldValue = totalOldGoldWeight() * parseFloat(getCurrentRate('GOLD') || 0);
        return subTotal + taxes - discount - oldGoldValue;
    };

    return (
        <>
            <div style={{display: 'none'}}>
                <div className='estimation-container' ref={componentRef} style={{border: '1px solid #000000'}}>
                    <style>{getPageMargins()}</style>
						<Row style={{padding: '10px'}}>
							<Col span={20} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
								<span style={{fontSize: '24px', fontWeight: '700'}}>
									{ estimationData?.plant_name }
								</span>
								<span style={{fontSize: '18px', fontWeight: '600'}}>
									{`${estimationData?.address}, ${estimationData?.city}-${estimationData?.zipcode}`}
								</span>
								<span style={{fontSize: '18px', fontWeight: '600'}}>
									Phone : { estimationData?.phone_no }
								</span>
							</Col>
							<Col span={4} style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
								<span className="saleBillText">ESTIMATION</span> 
								<span className="saleBillText">Date : {moment(estimationData?.invoice_date).format('DD/MM/YYYY')}</span> 
								<span className="saleBillText">E.No : {estimationData?.invoice_no}</span> 
								<span className="saleBillText">S.code : {estimationData?.invoice_no_inc}</span> 
							</Col>
                        </Row>
						<Row className="gutter-row" style={{borderTop: '1px solid #000000', padding: '10px 10px'}}>
							<Col span={14}>
								<span style={{fontSize: '18px'}}>
									Customer: { estimationData?.customer_name }
								</span>
							</Col>
							<Col span={10} style={{width: '100%'}}>
								<Row className="gutter-row">
									<Col span={7} style={{display: 'flex', flexDirection:' column', gap: '10px', alignItems: 'end'}}>
										<span className="saleBillText" style={{fontSize: '15px'}}>Gold Rate</span> 
										<span className="saleBillText" style={{fontSize: '15px'}}>Silver Rate</span> 
									</Col>
									<Col span={1} style={{display: 'flex', flexDirection:' column', gap: '10px', alignItems: 'end'}}>
										<span className="saleBillText" style={{fontSize: '15px'}}>:</span> 
										<span className="saleBillText" style={{fontSize: '15px'}}>:</span> 
									</Col>
									<Col span={15} offset={1} style={{display: 'flex', flexDirection:' column', gap: '10px'}}>
										<span className="saleBillText" style={{fontSize: '15px'}}>{getCurrentRate('GOLD')}</span> 
										<span className="saleBillText" style={{fontSize: '15px'}}>{getCurrentRate('SILVER')}</span> 
									</Col>
								</Row>
							</Col>
                        </Row>
						<Row className="gutter-row" style={{borderTop: '1px solid #000000', width: '100%', fontWeight: '600'}}>
							<Col span={2} className='pl-10px pt-5px pb-5px'>
								<span className="saleBillText ">Sno</span> 
							</Col>
							<Col span={5} className='border-left pl-10px pt-5px pb-5px'>
								<span className="saleBillText">Particulars</span> 
							</Col>
							<Col span={2} className='border-left pl-10px pt-5px pb-5px'>
								<span className="saleBillText">Qty</span> 
							</Col>
							<Col span={3} className='border-left pl-10px pt-5px pb-5px'>
								<span className="saleBillText">Weight</span> 
							</Col>
							<Col span={2} className='border-left pl-10px pt-5px pb-5px'>
								<span className="saleBillText">St_wt</span> 
							</Col>
							<Col span={2} className='border-left pl-10px pt-5px pb-5px'>
								<span className="saleBillText">Wst</span> 
							</Col>
							<Col span={3} className='border-left pl-10px pt-5px pb-5px'>
								<span className="saleBillText">MC</span> 
							</Col>
							<Col span={2} className='border-left pl-10px pt-5px pb-5px'>
								<span className="saleBillText">HM/SC</span> 
							</Col>
							<Col span={3} className='border-left pl-10px pt-5px pb-5px'>
								<span className="saleBillText">Amount</span> 
							</Col>
                        </Row>
						{salesItemList?.map((item, index) => (
                        <>
                            <Row key={index} className="gutter-row" style={{
                                borderTop: index === 0 ? '1px solid #000000' : null, 
                                borderBottom: '1px solid #000000', 
                                width: '100%', 
                                height: salesItemList?.length - 1 === index ? '150px' : null
                            }}>
                                <Col span={2} className='pl-10px pt-5px'>
                                    <span className="saleBillText">{index + 1}</span> 
                                </Col>
                                <Col span={5} className='border-left pl-10px pt-5px'>
                                    <span className="saleBillText">{item.item_name === 'GOLD' || 'gold' ? 'New Gold' : 'New Silver'}</span> 
                                </Col>
                                <Col span={2} className='border-left pl-10px pt-5px'>
                                    <span className="saleBillText">{parseFloat(item.pcs || 0)}</span> 
                                </Col>
                                <Col span={3} className='border-left pl-10px pt-5px'>
                                    <span className="saleBillText">{parseFloat(item.weight || 0).toFixed(3)}</span> 
                                </Col>
                                <Col span={2} className='border-left pl-10px pt-5px'>
                                    <span className="saleBillText">{parseFloat(item.stone_weight || 0).toFixed(3)}</span> 
                                </Col>
                                <Col span={2} className='border-left pl-10px pt-5px'>
                                    <span className="saleBillText">{item.waste}{item.waste_unit}</span> 
                                </Col>
                                <Col span={3} className='border-left pl-10px pt-5px'>
                                    <span className="saleBillText">{parseFloat(item.mc_amount || 0).toFixed(2)}</span> 
                                </Col>
                                <Col span={2} className='border-left pl-10px pt-5px'>
                                    <span className="saleBillText">{parseFloat(item.stone || 0).toFixed(2)}</span> 
                                </Col>
                                <Col span={3} className='border-left pt-5px pr-10px' style={{textAlign: 'end'}}>
                                    <span className="saleBillText">
                                        {calculateItemTotal(item).preTaxAmount.toFixed(2)}
                                    </span> 
                                </Col>
                            </Row>

                            <Row className="gutter-row" style={{width: '100%'}}>
                                <Col span={14} style={{display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '10px'}}>
                                    <span className="saleBillText pl-10px" style={{fontSize: '16px', fontWeight: '600'}}>
                                        Old Details
                                    </span> 
                                    <Row className="gutter-row">
                                        <Col span={10} style={{display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'end'}}>
                                            <span className="saleBillText" style={{fontSize: '15px'}}>
                                                &nbsp;&nbsp;OG&nbsp;&nbsp;&nbsp;{totalOldGoldWeight().toFixed(3)}
                                            </span> 
                                            <span className="saleBillText" style={{fontSize: '15px'}}>
                                                Old Gold(Tot)&nbsp;&nbsp;&nbsp;{totalOldGoldWeight().toFixed(3)}&nbsp;Gm
                                            </span> 
                                        </Col>
                                        <Col span={1} style={{display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'end'}}>
                                            <span className="saleBillText" style={{fontSize: '15px'}}>=</span> 
                                            <span className="saleBillText" style={{fontSize: '15px'}}>=</span> 
                                        </Col>
                                        <Col span={12} offset={1} style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                                            <span className="saleBillText" style={{fontSize: '15px'}}>
                                                {(totalOldGoldWeight() * parseFloat(getCurrentRate('GOLD') || 0)).toFixed(2)}
                                            </span> 
                                            <span className="saleBillText" style={{fontSize: '15px'}}>
                                                {(totalOldGoldWeight() * parseFloat(getCurrentRate('GOLD') || 0)).toFixed(2)}
                                            </span> 
                                        </Col>
                                    </Row> 
                                </Col>
                                <Col span={6} style={{display: 'flex', flexDirection: 'column', alignItems: 'end'}}>
                                    <span className="saleBillText" style={{marginTop: '10px'}}>Total</span> 
                                    <span className="saleBillText" style={{marginTop: '3px'}}>CGST : </span> 
                                    <span className="saleBillText" style={{marginTop: '3px'}}>SGST : </span> 
                                    <span className="saleBillText" style={{marginTop: '3px'}}>Discount : (-)</span>                                
                                </Col>
                                <Col span={3} offset={1} className='pr-10px' style={{
                                    borderLeft: '1px solid #000000', 
                                    display: 'flex', 
                                    flexDirection: 'column', 
                                    alignItems: 'end'
                                }}>
                                    <span className="saleBillText" style={{marginTop: '10px'}}>
                                        {salesAmountWOTax().toFixed(2)}
                                    </span> 
                                    <span className="saleBillText" style={{marginTop: '3px'}}>
                                        {totalTax().toFixed(2)}
                                    </span> 
                                    <span className="saleBillText" style={{marginTop: '3px'}}>
                                        {totalTax().toFixed(2)}
                                    </span>
                                    <span className="saleBillText" style={{marginTop: '3px'}}>
                                        {parseFloat(estimationData?.discount || 0).toFixed(2)}
                                    </span>                                
                                </Col>
                            </Row>
                            
                            <Row className="gutter-row" style={{width: '100%', marginBottom: '20px'}}>
                                <Col span={20} style={{display: 'flex', justifyContent: 'end'}}>
                                    <span className="saleBillText" style={{fontSize: '16px', fontWeight: '600', marginTop: '10px'}}>
                                        Net Amount
                                    </span>
                                </Col>
                                <Col span={3} offset={1} className='pr-10px' style={{
                                    borderTop: '1px solid #000000',
                                    borderLeft: '1px solid #000000',
                                    borderBottom: '1px solid #000000',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'end'
                                }}>
                                    <span className="saleBillText" style={{margin: '5px 0px'}}>
                                        {(salesAmountWOTax() + totalTax() * 2).toFixed(2)}
                                    </span>
                                </Col>
                            </Row>

                            <Row className="gutter-row" style={{width: '100%', marginBottom: '50px'}}>
                                <Col span={19} style={{display: 'flex', justifyContent: 'end'}}>
                                    <span className="saleBillText" style={{fontSize: '16px', fontWeight: '600'}}>
                                        Old {totalOldGoldWeight().toFixed(3)} Gm (-)
                                    </span>
                                </Col>
                                <Col span={3} offset={2} style={{display: 'flex', flexDirection: 'column', alignItems: 'end'}}>
                                    <span className="saleBillText pr-10px" style={{fontSize: '16px'}}>
                                        {(totalOldGoldWeight() * parseFloat(getCurrentRate('GOLD') || 0)).toFixed(2)}
                                    </span>
                                </Col>
                            </Row>

                            <Row className="gutter-row" style={{width: '100%'}}>
                                <Col span={19} style={{display: 'flex', justifyContent: 'end'}}>
                                    <span className="saleBillText pt-5px" style={{fontSize: '16px', fontWeight: '600'}}>
                                        Net Total
                                    </span>
                                </Col>
                                <Col span={3} offset={2} style={{
                                    borderTop: '1px solid #000000',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'end'
                                }}>
                                    <span className="saleBillText pr-10px" style={{fontSize: '18px', margin: '5px 0px'}}>
									{Math.round(Number(getNetTotal().toFixed(2)))}
                                    </span>
                                </Col>
                            </Row>
                        </>
                    ))}
                </div>
            </div>
        </>
    );
};

	return (
		<>
			<PageTitle
				imagesrc={SalesImage}
				pageTitle="Estimation List"
				buttonList={[{
					buttonName: 'Create Estimation',
					className: 'btn-create',
					url: '/slj/estimation/form'
				}]}
			/>
			{contextHolder}
			{EstimationReport()}
			{<div className='vendorListArea'>
				{quotationTable()}
			</div>}
		</>
	);
};

const handleTotal = (total, range) => (
	<div style={{fontFamily: 'Poppins', fontSize: '15px'}}><strong>{range[0].toLocaleString()}</strong> to <strong>{range[1].toLocaleString()}</strong> of <strong>{total.toLocaleString()}</strong> records</div>
);

export default EstimationList;
