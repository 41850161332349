import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, DatePicker, Popconfirm, Row, Select, Space, Spin, Table, Tabs, Tag, notification } from 'antd';
import PageTitle from '../common/PageTitle';
import salesreportImage from '../../assets/images/sales_report.svg';
import columnSearch from '../common/columnSearch';
import httpCall from '../../utils/api';
import '../../css/container.css';
import '../../css/SmallComponents.css';
import '../../css/Vendor.css';
import { useFormik } from 'formik';
import moment from 'moment';

const { RangePicker } = DatePicker;

const SaleReport = () => {
	const navigate = useNavigate();
	const [salesOrderList, setSalesOrderList] = useState([]);
	const [salesReceiptList, setSalesReceiptList] = useState([]);
	const [reportData, setReportData] = useState([]);
	const [customerList, setCustomerList] = useState([]);
	const [api, contextHolder] = notification.useNotification();
	const [loading, setLoading] = useState(false);
	
	useEffect(() => {
		setLoading(true);
		fetchCustomerList();
	}, []);

	const formik = useFormik({
		initialValues: {
			customer_id: '',
			from_date: '',
			to_date:''
		},
		onSubmit: (values, {resetForm}) => {

		},
	});

	useEffect(() => {
		if(formik.values.customer_id) {
			fetchSalesOrderList(formik.values.customer_id);
			fetchSalesReceiptList(formik.values.customer_id);
		}
	}, [formik.values.customer_id]);
	
	useEffect(() => {
		if(salesOrderList.length && salesReceiptList.length) {
			setReportData([]);
			setReportData([...salesOrderList, ...salesReceiptList]);
		}
		setLoading(false);
	}, [salesOrderList, salesReceiptList]);

	const fetchSalesOrderList = (customer_id) => {
		httpCall({
			method: 'GET',
			url: '/api/sales/order/report/' + customer_id,
		})
			.then((response) => {
				setLoading(false);
				setSalesOrderList(response?.data);
			})
			.catch((error) => {
				setLoading(false);
				console.error('Error retrieving sales order data:', error);
			});
	};

	const fetchSalesReceiptList = (customer_id) => {
		httpCall({
			method: 'GET',
			url: '/api/sales/receipt/report/' + customer_id,
		})
			.then((response) => {
				setLoading(false);
				setSalesReceiptList(response?.data);
			})
			.catch((error) => {
				setLoading(false);
				console.error('Error retrieving sales data:', error);
			});
	};

	const fetchCustomerList = () => {
		httpCall({
			method: 'GET',
			url: '/api/customer/get',
		})
			.then((response) => {
				if (response) {
                    setCustomerList(response?.data.map(c => ({label: c.company_name, value: c.id})));                    
				} else {
					console.error(
						'Error retrieving customer list:',
						response.message
					);
				}
			})
			.catch((error) => {
				console.error('Error retrieving customer list:', error);
			});
	};

  	const columnsNew = [
        {
			title: 'Date',
			dataIndex: 'date',
			key: 'date',
			align: 'center',
			width: 150,
			sorter: (a, b) => moment(a.date) - moment(b.date),
			defaultSortOrder: 'ascend',
			render: (_, record) => {
				return moment(record.date).format('DD-MM-YYYY');
			}
		},
		{
			title: 'Particulars',
			dataIndex: 'vch_type',
			key: 'vch_type',
			align: 'center',
			width: 300
		},
		{
			title: 'Vch Type',
			dataIndex: 'vch_type',
			key: 'vch_type',
			align: 'center',
			width: 75,
			render: (_, record) => {
				return (
					<>
						<span style={{fontSize: '13px', fontWeight: '700'}}>{record.vch_type.toUpperCase()}</span>
					</>
				)
			}
		},
		{
			title: 'Vch No',
			dataIndex: 'vch_no',
			key: 'vch_no',
			align: 'center',
			width: 100
		},
        {
			title: 'Debit',
			dataIndex: 'debit',
			key: 'debit',
			align: 'center',
			width: 100,
			render: (_, record) => {
				return record.debit > 0 ? record.debit : '';
			}
		},
        {
			title: 'Credit',
			dataIndex: 'credit',
			key: 'credit',
			align: 'center',
			width: 100,
			render: (_, record) => {
				return record.credit > 0 ? record.credit :  '';
			}
		}
	];

	const mergedColumns = React.useMemo(() => {
		return columnsNew.map((col) => ({
		  ...col,
		  onCell: undefined,
		}));
	  }, []);

	return (
		<>
			<PageTitle
				imagesrc={salesreportImage}
				pageTitle="Sales Report"
				buttonList={[]}
			/>
			{contextHolder}
			{<div className='vendorListArea' style={{overflow: 'hidden'}}>
				<Row gutter={16} className="gutter-row">
                    <Col span={12} className="gutter-box">
                        <div>
                            <span className="hintText color-black">Customer</span>
                        </div>                                
                        <Select
                            id="customer_id"
                            showSearch
                            placeholder="Select Customer"
                            style={{ width: '100%', height: '38px' }}
                            options={customerList}
							onChange={(e)=> {
                                formik.setFieldValue("customer_id", e);
                            }}
                        />
                    </Col>
					<Col span={12} className="gutter-box">
						<div>
                            <span className="hintText color-black">Select Data Range</span>
                        </div> 
						<RangePicker style={{height: '38px'}} onChange={(e) => {								
							if(!!e) {
								const start = e[0].format('YYYY-MM-DD');					
								const end = e[1].format('YYYY-MM-DD');
								//fetchReceiptList(`?start=${start}&end=${end}`);
							} else {
								//fetchReceiptList();
							}
						}}/>
					</Col>
				</Row>
				<Spin tip="Fetching Data From Server, Please Wait ..." spinning={loading} size="large">
						<Table 
							dataSource={reportData}
							size='small'
							bordered={true}
							columns={mergedColumns}
							rowKey="vch_no"
							pagination={false}
							style={{fontFamily: 'Poppins'}}/>
				</Spin>
			</div>}
		</>
	);
};

export default SaleReport;
