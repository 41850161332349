import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Popconfirm, Space, Spin, Table, notification } from 'antd';
import PageTitle from '../common/PageTitle';
import inventoryImage from '../../assets/images/inventory_T.svg';
import httpCall from '../../utils/api';
import '../../css/container.css';
import '../../css/SmallComponents.css';
import '../../css/Vendor.css';
import { CheckCircleFilled, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import moment from 'moment';

const StockList = () => {
    const navigate = useNavigate();
    const [stockList, setStockList] = useState([]);    
    const [api, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        setLoading(true);
        fetchStockList();
    }, []);

    const fetchStockList = () => {
        httpCall({
            method: 'GET',
            url: '/api/stock/list',
        })
            .then((response) => {
                setLoading(false);
                setStockList(response?.data);
            })
            .catch((error) => {
                setLoading(false);
                console.error('Error fetching stock list:', error);
            });
    };

    const openNotification = () => {
        api.open({
		  message: 'Delete Success !',
          duration: 2,
		  description:
			'Stock Deleted Successfully, Refreshing Grid. ',
          icon: <CheckCircleFilled style={{ color: '#808000' }} />,
        });
    };


    const columnsNew = [
        {
            title: 'Item Name',
            dataIndex: 'itemname',
            key: 'itemname',
            align: 'center',
            width: 150,
            sorter: (a, b) => a.itemname.localeCompare(b.itemname),
            defaultSortOrder: 'ascend',
        },
        {
            title: 'PCS',
            dataIndex: 'pcs',
            key: 'pcs',
            align: 'center',
            width: 200,
            sorter: (a, b) => Number(a.pcs) - Number(b.pcs),
        },
        {
            title: 'Weight (g)',
            dataIndex: 'weight',
            key: 'weight',
            align: 'center',
            width: 200,
            render: (_, record) => record.weight !== null && record.weight !== undefined ? Number(record.weight).toFixed(2) : 'N/A',
            sorter: (a, b) => Number(a.weight) - Number(b.weight),
        },        
        {
            title: 'Last Updated',
            dataIndex: 'last_updated',
            key: 'last_updated',
            align: 'center',
            width: 200,
            render: (_, record) => moment(record.last_updated).format('DD-MM-YYYY'),
        }
    ];

    const summary = (pageData) => {
        let totalPcs = 0;
        let totalWeight = 0;
        pageData.forEach(({ pcs, weight }) => {
            totalPcs += Number(pcs) || 0;
            totalWeight += Number(weight) || 0;
        });

        return (
            <Table.Summary fixed>
                <Table.Summary.Row>
                    <Table.Summary.Cell index={0} align="center">
                        <strong>Total</strong>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1} align="center">
                        <strong>{totalPcs}</strong>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2} align="center">
                        <strong>{totalWeight.toFixed(2)}</strong>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3} colSpan={2} />
                </Table.Summary.Row>
            </Table.Summary>
        );
    };

    return (
        <>
            <PageTitle
                imagesrc={inventoryImage}
                pageTitle="Stock List"
                buttonList={[]}
            />
            {contextHolder}
            <div className='vendorListArea'>
                <Spin tip="Fetching Data From Server, Please Wait ..." spinning={loading} size="large">
                    <Table 
                        dataSource={stockList}
                        columns={columnsNew}
                        rowKey="item_id"
                        pagination={{
                            showSizeChanger: true,
                            position: ['bottomCenter'],
                            pageSizeOptions: ['10', '20', '30'],
                            showTotal: handleTotal
                        }}
                        scroll={{ y: 570 }}
                        style={{ fontFamily: 'Poppins' }}
                        summary={summary}
                    />
                </Spin>
            </div>
        </>
    );
};

const handleTotal = (total, range) => (
    <div style={{ fontFamily: 'Poppins', fontSize: '15px' }}>
        <strong>{range[0].toLocaleString()}</strong> to <strong>{range[1].toLocaleString()}</strong> of <strong>{total.toLocaleString()}</strong> records
    </div>
);

export default StockList;
