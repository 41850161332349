import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Popconfirm, Space, Spin, Table, Tag, notification } from 'antd';
import PageTitle from '../../common/PageTitle';
import ItemImage from '../../../assets/images/item.png';
import columnSearch from '../../common/columnSearch';
import httpCall from '../../../utils/api';
import '../../../css/container.css';
import '../../../css/SmallComponents.css';
import '../../../css/Vendor.css';
import { CheckCircleFilled, DeleteOutlined, EditOutlined, EyeOutlined, FilterFilled } from '@ant-design/icons';

const ItemDataList = () => {
	const navigate = useNavigate();
	const searchInput = useRef(null);
	const [itemDataList, setItemDataList] = useState([]);
	const tableColumnSearch = columnSearch(searchInput);
	const [api, contextHolder] = notification.useNotification();
	const [loading, setLoading] = useState(false);
	const [itemTypeList, setItemTypeList] = useState([]);
	
	useEffect(() => {
		fetchItemDataList();
		fetchItemTypeList();
	}, []);

	const fetchItemDataList = () => {
		setLoading(true);
		httpCall({
			method: 'GET',
			url: '/api/item/list',
		})
			.then((response) => {
				setLoading(false);
				if (response) {
					setItemDataList(response?.data);
				} else {
					console.error(
						'Error retrieving cost center data:',
						response.message
					);
				}
			})
			.catch((error) => {
				setLoading(false);
				console.error('Error retrieving cost center data:', error);
			});
	};

	const fetchItemTypeList = () => {
		setLoading(true);
		httpCall({
			method: 'GET',
			url: '/api/itemtype/list',
		})
			.then((response) => {
				setLoading(false);
				if (response) {
					setItemTypeList(response?.data.map(it => ({text: it.name, value: it.name})));
				} else {
					console.error(
						'Error retrieving cost center data:',
						response.message
					);
				}
			})
			.catch((error) => {
				setLoading(false);
				console.error('Error retrieving cost center data:', error);
			});
	};

	const openNotification = () => {
		api.open({
		  message: 'Delete Success !',
		  duration: 2,
		  description:
			'Item Data Deleted Successfully, Refreshing Grid. ',
		  icon: <CheckCircleFilled style={{ color: '#808000' }} />,		  
		});
	};

	const handleEdit = (id) => {
		navigate('/slj/item-data/form', { state: { id } })
	};

	const handleDelete = (id) => {
		httpCall({
			method: 'DELETE',
			url: '/api/item/delete/'+ id,
		}).then(reponse => {
			openNotification();
			fetchItemDataList();
		}).catch(error => {
			console.log('Error>', error)
		})
	};
  
  	const columnsNew = [
		{
			title: 'Item Name',
			dataIndex: 'itemname',
			key: 'itemname',
			...tableColumnSearch('itemname'),
			align: 'center',
			width: 300,
			sorter: (a, b) => a.itemname.localeCompare(b.itemname),
			defaultSortOrder: 'ascend',
			sorter: (a, b) => a.itemname.localeCompare(b.itemname),
			defaultSortOrder: 'ascend', // Default sorting in ascending order
		},
		{
			title: 'Unit',
			dataIndex: 'unit',
			key: 'unit',
			align: 'center',
			width: 200,
			sorter: (a, b) => a.unit.localeCompare(b.unit),
			defaultSortOrder: 'ascend', // Default sorting in ascending order
		},	
		{
			title: 'Item Type',
			dataIndex: 'item_type',
			key: 'item_type',
			align: 'center',
			width: 200,
			filters: itemTypeList,
			onFilter: (value, record) => record.item_type === value,
			filterIcon: (filtered) => (
				<FilterFilled
					style={{
						color: filtered ? '#1677ff' : undefined,
						fontSize: '16px'
					}}
				/>
			),
			render: (_, record) => 
				itemDataList.length >= 1 ? (
						<Space size="middle">
							{
								record.item_type === 'GOLD' ? 
									<Tag style={{backgroundColor: '#FFD700'}}>{record.item_type}</Tag>
									:
									<Tag style={{backgroundColor: '#C0C0C0'}}>{record.item_type}</Tag>
							}
							
						</Space>
				) : null	
		},
		{
			title: 'Action',
			key: 'action',
			align: 'center',
			width: 150,
			render: (_, record) => 
				itemDataList.length >= 1 ? (
						<Space size="middle">
							<EyeOutlined style={{cursor: 'pointer', fontSize: '20px', color: '#08c'}}/>
							<EditOutlined style={{cursor: 'pointer', fontSize: '20px', color: '#08c'}} onClick={() => handleEdit(record.itemid)} />
							<Popconfirm title="Are you sure you want to delete?" onConfirm={() => handleDelete(record.itemid)}>
								<DeleteOutlined style={{fontSize: '20px', color: '#FF6868'}}/>
							</Popconfirm>
						</Space>
				) : null			
		  },
	];

	return (
		<>
			<PageTitle
				imagesrc={ItemImage}
				pageTitle="Item Data List"
				buttonList={[{
					buttonName: 'Back',
					className: 'btn-back',
					url: '/slj/items'
				}, {
					buttonName: 'Create Item',
					className: 'btn-create',
					url: '/slj/item-data/form'
				}]}
			/>
			{contextHolder}
			{<div className='vendorListArea'>
			 <Spin tip="Fetching Data From Server, Please Wait ..." spinning={loading} size="large"><Table 
				dataSource={itemDataList}
				columns={columnsNew}
				rowKey="itemid"
				pagination={{showSizeChanger: true, position: ['bottomCenter'], pageSizeOptions: ['10', '20', '30'], showTotal: handleTotal}}
				scroll={{ y: 570 }}
				style={{fontFamily: 'Poppins'}}/> </Spin>
			</div>}
		</>
	);
};

const handleTotal = (total, range) => (
	<div style={{fontFamily: 'Poppins', fontSize: '15px'}}><strong>{range[0].toLocaleString()}</strong> to <strong>{range[1].toLocaleString()}</strong> of <strong>{total.toLocaleString()}</strong> records</div>
);

export default ItemDataList;
