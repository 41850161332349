import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input, Popconfirm, Select, Space, Spin, Table, Typography, notification } from 'antd';
import PageTitle from '../../common/PageTitle';
import CategoryImage from '../../../assets/images/category.png';
import columnSearch from '../../common/columnSearch';
import httpCall from '../../../utils/api';
import '../../../css/container.css';
import '../../../css/SmallComponents.css';
import '../../../css/Vendor.css';
import { CheckCircleFilled, DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import EditableCell from '../../common/EditableCell';

const SubCategoryList = () => {
    const navigate = useNavigate();
    const searchInput = useRef(null);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [subCategoryList, setSubCategoryList] = useState([]);
    const tableColumnSearch = columnSearch(searchInput);
    const [api, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState(false);
    const nameInputRef = useRef(null);
    
    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState('');
    const isEditing = (record) => record.sub_category_id === editingKey;

	useEffect(() => {
        fetchCategoryList();
        fetchSubCategoryList();
    }, []);


	const edit = (record) => {
		form.setFieldsValue({
			sub_category_name: '',
			...record,
		});
		setEditingKey(record.sub_category_id);
	};

	const cancel = () => {
		setEditingKey('');
	};

	const fetchCategoryList = () => {
		setLoading(true);
		httpCall({
			method: 'GET',
			url: '/api/category/list',
		})
			.then((response) => {
				setLoading(false);
				if (response) {
					const categoryList = response?.data;
					setCategoryOptions(categoryList.map(c => ({label: c.category_name, value: c.category_id})));
					console.log(categoryOptions)
				} else {
					setLoading(false);
					console.error(
						'Error retrieving category list',
						response.message
					);
				}
			})
			.catch((error) => {
				console.error('Error retrieving category list', error);
			});
	};

	const fetchSubCategoryList = () => {
		setLoading(true);
		httpCall({
			method: 'GET',
			url: '/api/subcategory/list',
		})
			.then((response) => {
				setLoading(false);
				if (response) {
					setSubCategoryList(response?.data);
				} else {
					console.error(
						'Error retrieving sub category list',
						response.message
					);
				}
			})
			.catch((error) => {
				setLoading(false);
				console.error('Error retrieving sub category list', error);
			});
	};

    const openNotification = (msg, desc) => {
		api.open({
		  message: msg,
		  duration: 2,
		  description: desc,
		  icon: <CheckCircleFilled style={{ color: '#808000' }} />,		  
		});
	};

	
	const handleSave = (selectedCategory) => {
		const subCategoryName = nameInputRef.current.input.value.trim();
	  
		if (!selectedCategory) {
		  api.error({
			message: 'Validation Error',
			description: 'Please select a main product.',
			duration: 2,
		  });
		  return;
		}
		if (!subCategoryName) {
		  api.error({
			message: 'Validation Error',
			description: 'The sub-product name cannot be empty. Please enter a valid name.',
			duration: 2,
		  });
		  return;
		}
	  
		httpCall({
		  method: 'POST',
		  url: '/api/subcategory/create',
		  payload: {
			category_id: selectedCategory,
			sub_category_name: subCategoryName,
			description: '',
			status: 'Active',
			created_by: 1
		  },
		})
		.then((response) => {
		  if (response.data.success) {
				openNotification('Add Success !', 'New Sub Product Added Successfully, Updating Grid. ');
				nameInputRef.current.value = "";
			fetchSubCategoryList(); 
		  }
		})
		.catch((error) => {
		  if (error.response?.status === 400) {
			api.error({
			  message: 'Duplicate Entry',
			  description: error.response.data.message,
			  duration: 2,
			});
		  } else {
			console.error('Error saving sub-category:', error);
		  }
		});
	  };
	  
	

	  const update = async (key) => {
        try {
            const row = await form.validateFields();
            const newData = [...subCategoryList];
            const index = newData.findIndex((item) => key === item.sub_category_id);
            if (index > -1) {
                const item = newData[index];
                
                // Check for duplicates before submitting
                const isDuplicate = newData.some(
                    (subCategory) => 
                        subCategory.sub_category_id !== item.sub_category_id && 
                        subCategory.category_name === item.category_name &&
                        subCategory.sub_category_name.toLowerCase() === row.sub_category_name.toLowerCase()
                );

                if (isDuplicate) {
                    api.error({
                        message: 'Duplicate Entry',
                        description: 'This sub-product name already exists for the selected main product. Please enter a different name.',
                        duration: 2,
                    });
                    return;
                }

                // If not a duplicate, proceed with the update
                await handleUpdate(item.sub_category_id, row.sub_category_name);

                newData.splice(index, 1, {
                    ...item,
                    ...row,
                });
                setSubCategoryList(newData);
                setEditingKey('');
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const handleUpdate = async (sub_category_id, sub_category_name) => {
        try {
            const response = await httpCall({
                method: 'PUT',
                url: '/api/subcategory/edit',
                payload: {
                    sub_category_name,
                    sub_category_id
                },
            });

            if (response.data.success) {
                openNotification('Update Success !', 'Sub Product Name Updated Successfully, Updating Grid. ');
                fetchSubCategoryList();
            } else {
                api.error({
                    message: 'Update Failed',
                    description: response.data.message,
                    duration: 2,
                });
            }
        } catch (error) {
            console.error(error);
            api.error({
                message: 'Update Failed',
                description: 'An error occurred while updating the sub-category.',
                duration: 2,
            });
        }
    };

	const handleDelete = (id) => {
		httpCall({
			method: 'DELETE',
			url: '/api/subcategory/delete/'+ id,
		}).then(reponse => {
			openNotification('Delete Success !', 'Sub Product Deleted Successfully, Refreshing Grid. ');
			fetchSubCategoryList();
		}).catch(error => {
			console.log('Error>', error)
		})
	};

	const handleTotal = (total, range) => (
		<div style={{fontFamily: 'Poppins', fontSize: '15px'}}><strong>{range[0].toLocaleString()}</strong> to <strong>{range[1].toLocaleString()}</strong> of <strong>{total.toLocaleString()}</strong> records</div>
	);

    const columnsNew = [
		{
			title: 'Product Name',
			dataIndex: 'category_name',
			key: 'category_name',
			...tableColumnSearch('category_name'),
			align: 'center',
			editable: false,
			width: 300,
			sorter: (a, b) => a.category_name.localeCompare(b.category_name),
			defaultSortOrder: 'ascend', // Default sorting in ascending order
		},
		{
			title: 'Sub Product Name',
			dataIndex: 'sub_category_name',
			key: 'sub_category_name',
			...tableColumnSearch('sub_category_name'),
			align: 'center',
			editable: true,
			width: 300,
			sorter: (a, b) => a.sub_category_name.localeCompare(b.sub_category_name),
			defaultSortOrder: 'ascend', // Default sorting in ascending order
		},		
		{
			title: 'Action',
			key: 'action',
			align: 'center',
			width: 150,
			render: (_, record) => {
				const editable = isEditing(record);
				return editable ? (
					<span>
						<Typography.Link
							onClick={() => update(record.sub_category_id)}
							style={{
								marginRight: 8,
							}}
						> Save
						</Typography.Link>
						<Popconfirm title="Sure to cancel?" onConfirm={cancel}>
							<a>Cancel</a>
						</Popconfirm>
					</span>
				) : (
					<Space size="middle" disabled={editingKey !== ''}>
						<EditOutlined
							style={{
								cursor: 'pointer',
								fontSize: '20px',
								color: '#08c',
							}}
							onClick={() => edit(record)}
						/>
						<Popconfirm
							title="Are you sure you want to delete?"
							onConfirm={() => handleDelete(record.sub_category_id)}
						>
							<DeleteOutlined
								style={{ fontSize: '20px', color: '#FF6868' }}
							/>
						</Popconfirm>
					</Space>
				);}		
		  },
	];

	const mergedColumns = columnsNew.map((col) => {
		if (!col.editable) {
		  return col;
		}
		return {
		  ...col,
		  onCell: (record) => ({
			record,
			inputType: 'text',
			dataIndex: col.dataIndex,
			title: col.title,
			editing: isEditing(record),
		  }),
		};
	});



	const CategoryAdd = () => {
		const [selectedCategory, setSelectedCategory] = useState('');
		const handleChange = (value) => {
			setSelectedCategory(value);
		};
		return (
			<div style={{ marginTop: '2rem', marginLeft: '2rem', width: '100%', display: 'flex', gap: '20px', justifyContent: 'start', alignItems: 'center' }}>
				<Space direction='vertical'>
					<span style={{fontSize: '13px'}}>Select Main Product</span>
					<Select
						showSearch
						onChange={handleChange}
						style={{ width: 250, marginBottom: '30px', height: '38px', fontFamily: 'poppins'}}
						placeholder="Search to select product"
						optionFilterProp="children"
						filterOption={(input, option) => (option?.label ?? '').includes(input)}
						filterSort={(optionA, optionB) =>
						(optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
						}
						options={categoryOptions}
					/>
				</Space>
				<Space direction='vertical'>
					<span style={{fontSize: '13px'}}>Sub Product Name</span>
					<Input
						type="text"
						placeholder='Enter Sub Product Name'							
						style={{ width: '350px', marginBottom: '30px' }}
						ref={nameInputRef}							
					/>
				</Space>
				<Button type='primary' 
						style={{backgroundColor: 'green'}} 
						onClick={() => handleSave(selectedCategory)}
						icon={<PlusOutlined />}>
						Add Sub Product
				</Button>
			</div>
		)
	}

    return (
		<>
			<PageTitle
				imagesrc={CategoryImage}
				pageTitle="Product List"
				buttonList={[{
					buttonName: 'Back',
					className: 'btn-back',
					url: '/slj/items'
				}]}
			/>
			{contextHolder}
			<CategoryAdd />
			{<div className='categoryList' style={{width: '820px'}}>
				<Spin tip="Fetching Data From Server, Please Wait ..." spinning={loading} size="large">
					<Form form={form} component={false}>
						<Table dataSource={subCategoryList}
						    components={{
								body: {
								  cell: EditableCell,
								},
							}}
							bordered
							columns={mergedColumns}
							rowKey="sub_category_id"
							rowClassName="editable-row"
							pagination={{showSizeChanger: true, position: ['bottomCenter'], pageSizeOptions: ['10', '20', '30'], showTotal: handleTotal}}
							scroll={{ y: 500 }}
							size='small'
							style={{fontFamily: 'Poppins'}}/>
					</Form>
				</Spin>
			</div>}
		</>
	);
};

export default SubCategoryList;