import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Popconfirm, Space, Spin, Table, Tabs, Tag, notification } from 'antd';
import PageTitle from '../common/PageTitle';
import SalesImage from '../../assets/images/customer_receipt.svg';
import columnSearch from '../common/columnSearch';
import httpCall from '../../utils/api';
import '../../css/container.css';
import '../../css/SmallComponents.css';
import '../../css/Vendor.css';
import { CheckCircleFilled, DeleteOutlined, EditOutlined, EyeOutlined, FilterFilled } from '@ant-design/icons';
import moment from 'moment';

const SalesReceiptList = () => {
	const navigate = useNavigate();
	const searchInput = useRef(null);
	const [invoiceReceiptList, setInvoiceReceiptList] = useState([]);		
	const [api, contextHolder] = notification.useNotification();
	const [loading, setLoading] = useState(false);

	const tableColumnSearch = columnSearch(searchInput);
	
	useEffect(() => {
		setLoading(true);
		fetchSalesReceiptList();
	}, []);

	const fetchSalesReceiptList = () => {
		httpCall({
			method: 'GET',
			url: '/api/sales/invoice-receipt/list',
		})
			.then((response) => {
				setLoading(false);
				if (response) {
					setInvoiceReceiptList(response?.data);					
				} else {
					console.error(
						'Error retrieving invoice receipt list',
						response.message
					);
				}
			})
			.catch((error) => {
				setLoading(false);
				console.error('Error retrieving invoice receipt list', error);
			});
	};

	const openNotification = () => {
		api.open({
		  message: 'Delete Success !',
		  duration: 2,
		  description:
			'Invoice Data Successfully Deleted, Refreshing Grid. ',
		  icon: <CheckCircleFilled style={{ color: '#808000' }} />,		  
		});
	};

	const handleEdit = (id) => {
		navigate('/slj/sales/form', { state: { id } })
	};

	const handleDelete = (id) => {
		httpCall({
			method: 'DELETE',
			url: '/api/sales/delete/all?sales_id='+ id,
		}).then(reponse => {
			openNotification();
			fetchSalesReceiptList();
		}).catch(error => {
			console.log('Error>', error)
		})
	};
  
  	const columnsNew = [
        {
			title: 'Invoice Receipt Nr',
			dataIndex: 'invoice_receipt_no',
			key: 'invoice_receipt_no',
			...tableColumnSearch('invoice_receipt_no'),
			align: 'center',
			width: 150,
			sorter: (a, b) => a.invoice_receipt_no.localeCompare(b.invoice_receipt_no),
			defaultSortOrder: 'ascend', // Default sorting in ascending order
		},
        {
			title: 'Invoice Receipt Date',
			dataIndex: 'invoice_receipt_date',
			key: 'invoice_receipt_date',			
			align: 'center',
			width: 150,
            render: (date) => moment(date).format('DD-MM-YYYY'),
			sorter: (a, b) => a.invoice_receipt_date.localeCompare(b.invoice_receipt_date),
			defaultSortOrder: 'ascend', // Default sorting in ascending order
		},
        {
			title: 'Customer Name',
			dataIndex: 'customer_name',
			key: 'customer_name',
			align: 'center',
			width: 200,
			sorter: (a, b) => a.customer_name.localeCompare(b.customer_name),
			defaultSortOrder: 'ascend', // Default sorting in ascending order
		},
        {
			title: 'Payment Mode',
			dataIndex: 'payment_mode',
			key: 'payment_mode',			
			align: 'center',
			width: 150,
		},
		{
			title: 'Pending Amount',
			dataIndex: 'pending_amount',
			key: 'pending_amount',
			align: 'center',
			width: 200
		},
		{
			title: 'Advance Amount',
			dataIndex: 'advance_amount',
			key: 'advance_amount',
			align: 'center',
			width: 200
		},
		{
			title: 'Action',
			key: 'action',
			align: 'center',
			width: 150,
			render: (_, record) => 
				invoiceReceiptList.length >= 1 ? (
						<Space size="middle">
							<EyeOutlined style={{cursor: 'pointer', fontSize: '20px', color: '#08c'}}/>
							<EditOutlined style={{cursor: 'pointer', fontSize: '20px', color: '#08c'}} onClick={() => handleEdit(record.invoice_receipt_no)} />
							<Popconfirm title="Are you sure you want to delete?" onConfirm={() => handleDelete(record.invoice_receipt_no)}>
								<DeleteOutlined style={{fontSize: '20px', color: '#FF6868'}}/>
							</Popconfirm>
						</Space>
				) : null			
		  },
	];

	const salesReturnColumns = [
        {
			title: 'Item Name',
			dataIndex: 'item_name',
			key: 'item_name',
			...tableColumnSearch('item_name'),
			align: 'center',
			width: 300
		},
		{
			title: 'Cost Center Name',
			dataIndex: 'cost_center_name',
			key: 'cost_center_name',
			...tableColumnSearch('cost_center_name'),
			align: 'center',
			width: 200
		},
		{
			title: 'Customer Name',
			dataIndex: 'name',
			key: 'name',
			align: 'center',
			width: 200
		},
		{
			title: 'Phone No',
			dataIndex: 'no',
			key: 'no',
			align: 'center',
			width: 200
		},
        {
			title: 'Amount',
			dataIndex: 'amount',
			key: 'amount',
			align: 'center',
			width: 200
		},
		{
			title: 'Action',
			key: 'action',
			align: 'center',
			width: 150,
			render: (_, record) => 
				invoiceReceiptList.length >= 1 ? (
						<Space size="middle">
							<EyeOutlined style={{cursor: 'pointer', fontSize: '20px', color: '#08c'}}/>
						</Space>
				) : null			
		  },
	];

	const tab1 = () => {
		return (
				<Spin tip="Fetching Data From Server, Please Wait ..." spinning={loading} size="large">
					<Table 
						dataSource={invoiceReceiptList}
						columns={columnsNew}
						rowKey="sales_order_no"
						pagination={{showSizeChanger: true, position: ['bottomCenter'], pageSizeOptions: ['10', '20', '30'], showTotal: handleTotal}}
						scroll={{ y: 570 }}
						style={{fontFamily: 'Poppins'}}/>
				</Spin>
		)
	}

	return (
		<>
			<PageTitle
				imagesrc={SalesImage}
				pageTitle="Sales Receipt List"
				buttonList={[{
					buttonName: 'Create Receipt',
					className: 'btn-create',
					url: '/slj/sales-receipt/form'
				}]}
			/>
			{contextHolder}
			{<div className='vendorListArea'>
			<Tabs type='card' 
				tabBarGutter={10} 
				size='small' 
				style={{fontFamily: 'poppins'}} 
				items={[{title: 'Sales Receipts', table: tab1()}].map((tab, i) => {
				return {
					key: i,
					label: tab.title,
					children: tab.table,
				};
			})}>
			</Tabs>
			</div>}
		</>
	);
};

const handleTotal = (total, range) => (
	<div style={{fontFamily: 'Poppins', fontSize: '15px'}}><strong>{range[0].toLocaleString()}</strong> to <strong>{range[1].toLocaleString()}</strong> of <strong>{total.toLocaleString()}</strong> records</div>
);

export default SalesReceiptList;
