import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, DatePicker, Divider, Input, InputNumber, Modal, Radio, Row, Select, Space, Statistic, Switch, Table, Tag, notification } from 'antd';
import { AlertOutlined, CheckCircleFilled, ClearOutlined, DeleteFilled, EditFilled, FileImageOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import materialreturnImage from '../../assets/images/material-return.svg';
import '../../css/container.css';
import '../../css/SmallComponents.css';
import httpCall from '../../utils/api';
import { useFormik } from 'formik';
import PageTitle from '../common/PageTitle';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';

const {Option} = Select;

const MaterialReturnForm = () => { 
	const location = useLocation();
	const navigate = useNavigate();
    const [costCenterList, setCostCenterList] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const [itemDataList, setItemDataList] = useState([]);
    const [itemDataOptions, setItemDataOptions] = useState([]);
    const [custMobileOptions, setCustMobileOptions] = useState([]);
	const [api, contextHolder] = notification.useNotification();
    const [salesReturnItemList, setSalesReturnItemList] = useState([]);
    const [plants, setPlants] = useState([]);
    const [plantOptions, setPlantOptions] = useState([]);
    const [paymentOptions, setPaymentOptions] = useState([]);
    const [accountList, setAccountList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [itemTypes, setItemTypes] = useState([]);
    const [modelType, setModelType] = useState('');
    const [isNewCustomer, setIsNewCustomer] = useState(false); 
    const [isUpdate, setIsUpdate] = useState(false); 
    const [isEdit, setIsEdit] = useState(false);

    const componentRef = useRef();
    
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        removeAfterPrint: true,
    });
    
    const showModal = (type, isEdit) => {
        setIsEdit(!!isEdit);
        setModelType(type);
    };

    const handleOk = () => {
        addNewItem(salesItemReturnFormik, salesReturnItemList, setSalesReturnItemList);     
    };

    const handleCancel = () => {
        setModelType('');
        setIsNewCustomer(false);
    };

    const formik = useFormik({
		initialValues: {
            return_item_no_inc: '',
            return_item_no: '',
            customer_id: '',
            return_item_date: moment(),
            total_amount: '',
            sales_person_id: '',
            isNewCustomer: false,
            customer_mobile: '',
            customer_name: '',
            customer_address: '',
            new_customer_mobile: '',
            new_customer_name: '',
            new_customer_address: '',
            plant_code: '',
            quotationHeader: '',
            module: 'QUOTATION',
            salesPerson: {},
            plant: {},
            customer: {},
            payment_mode_1: 'Cash',
            account_1: '',
            paid_amount_1: ''
		},
		onSubmit: (values, {resetForm}) => {
            if(!hasValidated(values)) {
                return;
            }
			if(location?.state?.id) {
				updateMaterialReturn(values, resetForm);
			} else {
				createMaterialReturn(values, resetForm);
			}
		},
	});

    const salesItemReturnFormik = useFormik({
		initialValues: {
            item_id: '',
            sales_item_name: '',
            item_option: {},
            pcs: '',
            weight: '',
            amount: '',
            stone: '',
            waste: '',
            waste_unit: 'g',
            huid: '',
            mc_amount: '',
            other_details: ''
		}
	});

    const hasValidated = (values) => {
        if(values.customer_id === '') {
            openErrorNotification('Please Select a Customer.');
            return false;
        }
        if(values.plant_code === '') {
            openErrorNotification('Please Select a Plant.');
            return false;
        }
        if(values.sales_person_id === '') {
            openErrorNotification('Please Select Sales Person');
            return;
        }

        return true;
    }

    useEffect(() => {
        fetchCostCenter();
        fetchCustomerList();
        fetchItemDataList();
        fetchPlantList();
        fetchItemTyeps();
        fetchPaymentOptions();
        fetchAccountList();
		if(location?.state?.id) {
            setTimeout(() => {
                // Update functionality
            }, 0);            
		} else {
            fetchNextBillNo();
        }
	}, []);

    useEffect(() => {        
        if(isUpdate) {
            const salesPerson = costCenterList?.find(sp => sp.value === formik.values.sales_person_id);
            formik.setFieldValue('salesPerson', salesPerson);
            
            const plant = plantOptions?.find(p => p.value === formik.values.plant_code);
            formik.setFieldValue('plant', plant);

            const customer = customerList?.find(c => c.id === formik.values.customer_id);
            formik.setFieldValue('customer', {label: customer.mobile_number, value: customer.mobile_number});
            formik.setFieldValue("customer_id", customer.id);
            formik.setFieldValue("customer_name", customer.company_name);
            formik.setFieldValue("customer_address", customer.address);

        }
	}, [isUpdate]);

    const fetchItemTyeps = () => {
		setLoading(true);
		httpCall({
			method: 'GET',
			url: '/api/itemtype/list',
		})
			.then((response) => {
				if (response) {
                    setItemTypes(response?.data);
				} else {
					console.error(
						'Error retrieving item type list',
						response.message
					);
				}
				setLoading(false);
			})
			.catch((error) => {
				console.error('Error retrieving item type list', error);
				setLoading(false);
			});
	};

    const fetchPaymentOptions = () => {
		httpCall({
			method: 'GET',
			url: '/api/payment/list?filter=true',
		})
			.then((response) => {
                setPaymentOptions(response?.data);
			})
			.catch((error) => {
				console.error('Error retrieving payment list:', error);
			});
	};

    const fetchAccountList = () => {
		setLoading(true);
		httpCall({
			method: 'GET',
			url: '/api/account/list',
		})
			.then((response) => {
				if (response) {
					setAccountList(response?.data.map(acc => ({label: `${acc.bank_name} - ${acc.account_no}`, value: acc.account_no})));
				} else {
					console.error('Error retrieving account list', response.message);
				}
				setLoading(false);
			})
			.catch((error) => {
				console.error('Error retrieving account list', error);
				setLoading(false);
			});
	};

    useEffect(() => {
        calculateTotal();
	}, [salesReturnItemList]);

    useEffect(() => {
        if(!!salesItemReturnFormik.values.weight && !!salesItemReturnFormik.values.current_rate) {
            const current_rate = parseFloat(salesItemReturnFormik.values.current_rate || 0);
            const weight = parseFloat(salesItemReturnFormik.values.weight || 0);
            let waste = parseFloat(salesItemReturnFormik.values.waste || 0);
            const waste_unit = salesItemReturnFormik.values.waste_unit;
            const stone = parseFloat(salesItemReturnFormik.values.stone || 0);
            const mc_amount = parseFloat(salesItemReturnFormik.values.mc_amount || 0);
            waste = waste_unit === '%' ? (weight * waste) / 100 : waste;

            const amt = ((weight - waste) * current_rate) - stone - mc_amount;
            salesItemReturnFormik.setFieldValue('amount', amt.toFixed(3));
        }
	}, [salesItemReturnFormik.values.weight,
        salesItemReturnFormik.values.current_rate,
        salesItemReturnFormik.values.waste, 
        salesItemReturnFormik.values.waste_unit,
        salesItemReturnFormik.values.stone, 
        salesItemReturnFormik.values.mc_amount
    ]);

	const fetchPlantList = () => {
		httpCall({
			method: 'GET',
			url: '/api/plant/list',
		})
			.then((response) => {
                setPlants(response?.data);
                setPlantOptions(response?.data.map(p => ({label: p.plant_name, value: p.plant_code})))
			})
			.catch((error) => {
			});
	};

    const fetchCostCenter = () => {
		httpCall({
			method: 'GET',
			url: '/api/costcenter/get',
		})
			.then((response) => {
				if (response) {
                    setCostCenterList(response?.data.map(c => ({label: c.cost_center_name, value: c.cost_center_id})));
				} else {
					console.error(
						'Error retrieving categories data:',
						response.message
					);
				}
			})
			.catch((error) => {
				console.error('Error retrieving categories data:', error);
			});
	};

    const fetchCustomerList = () => {
		httpCall({
			method: 'GET',
			url: '/api/customer/get',
		})
			.then((response) => {
				if (response) {
                    setCustomerList(response?.data);
                    setCustMobileOptions(response?.data.map(c => ({label: c.mobile_number, value: c.mobile_number})))
				} else {
					console.error(
						'Error retrieving customer list:',
						response.message
					);
				}
			})
			.catch((error) => {
				console.error('Error retrieving customer list:', error);
			});
	};

    const fetchItemDataList = () => {
		httpCall({
			method: 'GET',
			url: '/api/item/sale/list',
		})
			.then((response) => {
				if (response) {
                    setItemDataList(response?.data);
                    setItemDataOptions(response?.data.map(i => ({label: i.itemname, value: i.itemname})));
				} else {
					console.error(
						'Error retrieving item data list:',
						response.message
					);
				}
			})
			.catch((error) => {
				console.error('Error retrieving item data list:', error);
			});
	};

    const fetchNextBillNo = () => {
		httpCall({
			method: 'GET',
			url: '/api/sales/getMaterialReturnNr',
		})
			.then((response) => {
				if (response) {
                    formik.setFieldValue('return_item_no_inc', response.data);
                    formik.setFieldValue('return_item_no', `MR${response.data}/${getCurrentDate()}`);
				} else {
					console.error(
						'Error retrieving next billno:',
						response.message
					);
				}
			})
			.catch((error) => {
				console.error('Error retrieving next billno:', error);
			});
	};

    const getCurrentDate = () => {
        let newDate = new Date()
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        return `${date}${month<10?`0${month}`:`${month}`}${year}`;
      }

	const openNotification = (description) => {
		api.open({
		  message: 'Success !',
		  duration: 2,		  
		  description: description,
		  icon: <CheckCircleFilled style={{ color: '#808000' }} />,		  
		});
	};

    const openErrorNotification = (description) => {
		api.error({
		  message: 'Error !',
		  duration: 4,		  
		  description: description,
		});
	};

    const createMaterialReturn = (values, handleReset) => {
        const return_item_date = moment(values.return_item_date).format('YYYY-MM-DD');
        ['item_option', 'customer_address', 'customer_mobile', 'customer_name', 'isNewCustomer', 
        'new_customer_address', 'new_customer_mobile', 'new_customer_name', 'quotationHeader'].forEach((prop) => delete values[prop]);
		httpCall({
			method: 'POST',
			url: '/api/sales/material-return',
			payload: {...values, return_item_date, items: [...salesReturnItemList]}
		})
			.then((response) => {
				if (response) {
					openNotification('Material Return Created Successfully.');		
					handleReset();
                    setTimeout(() => { navigate('/slj/material-return')})
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

    const updateMaterialReturn = (values, handleReset) => {
        ['item_option', 'customer_address', 'customer_mobile', 'customer_name', 'isNewCustomer', 
        'new_customer_address', 'new_customer_mobile', 'new_customer_name', 'quotationHeader'].forEach((prop) => delete values[prop]);        
		httpCall({
			method: 'PUT',
			url: '/api/sales/material-return',
			payload: {...values, items: [...salesReturnItemList]}
		})
			.then((response) => {
				if (response) {
					openNotification('Material Return Details Updated Successfully, Goto list view. ');		
					setTimeout(() => navigate('/slj/material-return'), 2500)
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

    const createCustomer = () => {
		httpCall({
			method: 'POST',
			url: '/api/customer/create',
			payload: {company_name: formik.values.new_customer_name, address: formik.values.new_customer_address, mobile_number: formik.values.new_customer_mobile},
		})
			.then((response) => {
                openNotification('Customer created successfully.');
                setIsNewCustomer(false);
                setModelType('');
                fetchCustomerList();
			})
			.catch((error) => {
                if(error.response.status === 403) {
                    console.error(error.response.data.message);
                } else {
                    console.error(error);
                }
			});
    }

    const calculateTotal = () => {
        const oldGoldAmount = salesReturnItemList.reduce((acc, next) => acc + parseFloat(next.amount), 0);
       
        formik.setFieldValue('total_amount', oldGoldAmount);
    }

    const salesItemColumns = [
        {
			title: 'Item Name',
			dataIndex: 'sales_item_name',
			key: 'sales_item_name',
			align: 'center',
			width: 250
		},
        {
			title: 'PCS',
			dataIndex: 'pcs',
			key: 'pcs',		
			align: 'center',
			width: 70
		},
        {
			title: 'Weight',
			dataIndex: 'weight',
			key: 'weight',		
			align: 'center',
			width: 70
		},
        {
			title: 'Current Rate',
			dataIndex: 'current_rate',
			key: 'current_rate',		
			align: 'center',
			width: 100
		},
        {
			title: 'MC Amount',
			dataIndex: 'mc_amount',
			key: 'mc_amount',		
			align: 'center',
			width: 120
		},
        {
			title: 'HUID',
			dataIndex: 'huid',
			key: 'huid',		
			align: 'center',
			width: 100
		},
		{
			title: 'Amount',
			dataIndex: 'amount',
			key: 'amount',		
			align: 'center',
			width: 200
		},
		{
			title: 'Action',
			key: 'action',
			align: 'center',
			width: 150,
			render: (_, record) => 
                (
                    <Space size="middle">
                        <EditFilled
                            onClick={() => {
                                const salesReturn = salesReturnItemList.find(item => item.item_id === record.item_id);                                    
                                salesItemReturnFormik.setValues(salesReturn);
                                showModal('RETURN', true);                               
                            }}
                            style={{cursor: 'pointer', fontSize: '20px', color: '#08c'}}/>
                        <DeleteFilled 
                            onClick={() => {
                                const idx = salesReturnItemList.findIndex(item => item.item_id === record.item_id);
                                salesReturnItemList.splice(idx, 1)
                                setSalesReturnItemList([...salesReturnItemList]);
                            }}
                            style={{cursor: 'pointer', fontSize: '20px', color: '#B31312'}}/>
                    </Space>
                )
		  },
	];

    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const salesItemTable = (datasoure) => {
        return datasoure.length > 0 ? (
            <>
                <Row gutter={16} className="gutter-row sales-list">
                    <Col span={24} className="gutter-box">
                    <Table
						dataSource={datasoure}
						columns={salesItemColumns}
                        pagination={{position: ['bottomCenter'], pageSize: 2}}
						rowKey="sales_item_name"
                        size='small'
                        bordered="true"						
						scroll={{ y: 100 }}
						style={{fontFamily: 'Poppins'}}/>
                    </Col>
                </Row>
            </>
        ) : null
    }

    const salesOrderForm = () => {
        return (
            <>
                <Row gutter={16} className="gutter-row">                    
                    <Col span={4} offset={20} className="gutter-box" style={{textAlign: 'right'}}>
                        <div>
                            <span className="hintText">Material Return Nr</span>                            
                        </div> 
                        <Input
                            type="text"
                            id="return_item_no"
                            disabled={true}
                            variant="borderless"
                            style={{ width: '100%', color: '#000000', padding: '5px 2px', fontSize: '14px', fontWeight: '700',  textAlign: 'right'}}
                            value={formik.values['return_item_no']}
                        />                                
                    </Col>
                </Row>
                <Divider orientation='left' orientationMargin='0'>Material Return Details</Divider>
                <Row gutter={16} className="gutter-row">
                    <Col span={4} className="gutter-box" style={{display: 'flex', alignItems: 'center'}}>
                        <Switch disabled={isUpdate} checked={isNewCustomer} checkedChildren='New Customer' unCheckedChildren='Existing Customer' 
                            onChange={(checked, e) => {
                                showModal('NEW_CUSTOMER');
                                setIsNewCustomer(checked);
                            }}
                        />
                    </Col>
                    <Col span={6} className="gutter-box">
                        <div>
                            <span className="hintText">Customer Mobile Number</span>
                            <span className="mandatory"></span>
                        </div>                                
                        <Select
                            id="no"
                            showSearch
                            placeholder="Select a Number"
                            filterOption={filterOption}
                            style={{ width: '100%', height: '38px' }}
                            options={custMobileOptions}
                            value={formik.values.customer}
                            onChange={(e)=> {
                                const cust = customerList.find(c => c.mobile_number === e);
                                formik.setFieldValue("customer", e);
                                formik.setFieldValue("customer_id", cust.id);
                                formik.setFieldValue("customer_name", cust.company_name);
                                formik.setFieldValue("customer_address", cust.address);
                            }}
                        />  
                    </Col>
                    <Col span={6} className="gutter-box">
                        <div>
                            <span className="hintText">Customer Name</span>                            
                        </div> 
                        <Input
                            type="text"
                            id="customer_name"
                            disabled={true}
                            style={{ width: '100%', color: '#000000'}}
                            value={formik.values.customer_name}
                        />     
                    </Col>
                    <Col span={8} className="gutter-box">
                        <div>
                            <span className="hintText">Customer Address</span>                            
                        </div> 
                        <Input
                            type="text"
                            id="customer_address"
                            disabled={true}
                            style={{ width: '100%', color: '#000000'}}
                            value={formik.values.customer_address}
                        />                                
                    </Col>
                </Row>
                <Row gutter={16} className="gutter-row">
                    <Col span={4} className="gutter-box">
                        <div>
                            <span className="hintText">Material Return Date</span>                            
                        </div> 
                        <DatePicker
                            style={{ width: '100%', height: '38px' }}                            
                            disabled={true}
                            name='return_item_date'
                            format={{
                                format: 'YYYY-MM-DD',
                                type: 'mask',
                            }}
                            value={formik.values.return_item_date}
                        />                             
                    </Col>
                    <Col span={6} className="gutter-box">
                        <div>
                            <span className="hintText">Plant</span>
                            <span className="mandatory"></span>                         
                        </div> 
                        <Select
                            id="plant_code"
                            placeholder="Select a Plant"
                            style={{ width: '100%', height: '38px' }}
                            options={plantOptions}
                            value={formik.values.plant}
                            onChange={(e)=> {
                                formik.setFieldValue("plant_code", e);
                                formik.setFieldValue("plant", e);
                                const plant = plants.find(p => p.plant_code === e);
                                const qHeader = `${plant.plant_name}, ${plant.address}, ${plant.city}-${plant.zipcode}, ${plant.phone_no}`;
                                formik.setFieldValue("quotationHeader", qHeader);
                            }}
                        />   
                    </Col>
                    <Col span={6} className="gutter-box">
                        <div>
                            <span className="hintText">Sales Person Name</span> 
                            <span className="mandatory"></span>                       
                        </div> 
                        <Select
                            id="sales_person_id"
                            placeholder="Select a Name"
                            style={{ width: '100%', height: '38px' }}
                            options={costCenterList}
                            value={formik.values.salesPerson}
                            onChange={(e)=> {
                                formik.setFieldValue("salesPerson", e);
                                formik.setFieldValue("sales_person_id", e);
                            }}
                        />   
                    </Col>
                </Row>
            </>
        )
    }

    const newCustomer = () => {
        return (
            <>
               <Tag icon={<AlertOutlined />} color="magenta">Basic details are provided here, you can update full details in master section. </Tag>
               <Row gutter={16} className="gutter-row">
                    <Col span={24} className="gutter-box">
                        <div>
                            <span className="hintText">Customer Mobile Number</span>
                            <span className="mandatory"></span>
                        </div>                                
                        <Input
                            type="text"
                            id="new_customer_mobile"
                            style={{ width: '100%', color: '#000000'}}
                            onChange={formik.handleChange}
                            value={formik.values.new_customer_mobile}
                        />   
                    </Col>
                </Row>
                <Row gutter={16} className="gutter-row">
                    <Col span={24} className="gutter-box">
                        <div>
                            <span className="hintText">Customer Name</span>
                            <span className="mandatory"></span>                        
                        </div> 
                        <Input
                            type="text"
                            id="new_customer_name"
                            style={{ width: '100%', color: '#000000'}}
                            onChange={formik.handleChange}
                            value={formik.values.new_customer_name}
                        />     
                    </Col>
                </Row>
                <Row gutter={16} className="gutter-row">
                    <Col span={24} className="gutter-box">
                        <div>
                            <span className="hintText">Customer Address</span>                            
                        </div> 
                        <Input
                            type="text"
                            id="new_customer_address"
                            style={{ width: '100%', color: '#000000'}}
                            onChange={formik.handleChange}
                            value={formik.values.new_customer_address}
                        />                                
                    </Col>
                </Row>                
            </>
        )
    }

    const updateItemFields = (item_name, form) => {
        form.setFieldValue("sales_item_name", item_name);
        const item = itemDataList.find(i => i.itemname === item_name);
        form.setFieldValue("item_id", item.itemid);
        form.setFieldValue("product", item.category_name);
        form.setFieldValue("sub_product", item.sub_category_name);
        form.setFieldValue("unit", item.unit);
        form.setFieldValue("stone", item.stone);
        form.setFieldValue("waste", item.waste);
        form.setFieldValue("waste_unit", item.waste_unit);     
        form.setFieldValue("igst", item.igst);
        form.setFieldValue("cgst", item.cgst);
        form.setFieldValue("sgst", item.sgst);
    }

    const addNewItem = (form, itemList, setItemList) => {
        if(!isEdit && itemList.some(i => i.item_id === form.values.item_id)) {
            openErrorNotification('Same Return alreay added, pls update if you need.');
            return;
        }
        const salesItem = {
            item_id: form.values.item_id,
            sales_item_name: form.values.sales_item_name,
            item_option: form.values.item_option,
            pcs: form.values.pcs || 0,
            weight: form.values.weight,
            current_rate: form.values.current_rate,
            amount: form.values.amount,
            stone: form.values.stone,
            waste: form.values.waste,
            waste_unit: form.values.waste_unit,
            mc_amount: form.values.mc_amount,
            huid: form.values.huid,
            igst: form.values.igst,
            cgst: form.values.cgst,
            sgst: form.values.sgst,
            tax_amount: form.values.tax_amount,
            other_details: form.values.other_details
        }

        if(salesItem.sales_item_name && salesItem.weight !== '' && salesItem.current_rate) {
            if(isEdit) {
                itemList = itemList.filter(i => i.item_id !== salesItem.item_id);
            }
            setItemList([...itemList, salesItem]);
            form.resetForm();
            form.setFieldValue('sales_item_name', '');
            form.setFieldValue('item_option', {});
            setModelType('');
        } else {
            openErrorNotification('Please Enter Required Item Fields.');
        }
    }

    const wasteTypes = (form) => (
        <Select value={form.values.waste_unit} style={{ width: 60 }} onChange={(e) => form.setFieldValue("waste_unit", e)}>
          <Option value="g">g</Option>
          <Option value="%">%</Option>
        </Select>
    );

    const salesItemReturnForm = () => {
        return (
            <>
                <Row gutter={16} className="gutter-row">
                    <Col span={17} className="gutter-box">
                        <div>
                            <span className="hintText">Item Name</span>
                            <span className="mandatory"></span>
                        </div>                                
                        <Select
                            id="item_name"
                            placeholder="Select a Name"
                            style={{ width: '100%', height: '38px' }}
                            options={itemDataOptions}
                            defaultValue={salesItemReturnFormik.values.item_option}
                            onChange={(e)=> {
                                updateItemFields(e, salesItemReturnFormik);
                            }}
                        />
                    </Col>
                    <Col span={3} className="gutter-box">
                        <div>
                            <span className="hintText">Unit</span>                            
                        </div> 
                        <Input
                            type="text"
                            id="unit"
                            disabled={true}
                            style={{ width: '100%', color: '#000000'}}
                            onChange={salesItemReturnFormik.handleChange}
                            value={salesItemReturnFormik.values.unit}
                        />                                
                    </Col>
                    <Col span={4} className="gutter-box">
                        <div>
                            <span className="hintText">PCS</span>                            
                        </div> 
                        <Input
                            type="text"
                            id="pcs"
                            style={{ width: '100%'}}
                            onChange={salesItemReturnFormik.handleChange}
                            value={salesItemReturnFormik.values.pcs}
                        />    
                    </Col>
                </Row>
                <Row gutter={16} className="gutter-row">
                    <Col span={12} className="gutter-box">
                        <Space.Compact style={{width: '100%'}}>
                            <div style={{width: '50%'}}>
                                <div>
                                    <span className="hintText">Weight</span>
                                    <span className="mandatory"></span>                           
                                </div> 
                                <Input
                                    type="text"
                                    id="weight"
                                    style={{ width: '100%'}}
                                    onChange={(e) => {
                                        salesItemReturnFormik.setFieldValue('weight', e.target.value);
                                        const amount = parseFloat(e.target.value || 0) * parseFloat(salesItemReturnFormik.values.current_rate || 0);
                                        salesItemReturnFormik.setFieldValue('amount', amount.toFixed(3));
                                    }}
                                    value={salesItemReturnFormik.values.weight}
                                />   
                            </div>
                            <div style={{width: '50%'}}>
                                <div>
                                    <span className="hintText">Current Market Rate</span>   
                                    <span className="mandatory"></span>                         
                                </div> 
                                <Input
                                    type="text"
                                    prefix='₹'
                                    id="current_rate"                                    
                                    style={{ width: '100%', padding: '7px', color: '#000000'}}
                                    value={salesItemReturnFormik.values.current_rate}
                                    onChange={salesItemReturnFormik.handleChange}
                                />  
                            </div>
                        </Space.Compact>
                    </Col>
                    <Col span={4} className="gutter-box">
                        <div>
                            <span className="hintText">Stone</span>                            
                        </div> 
                        <Input
                            type="text"
                            id="stone"
                            style={{ width: '100%'}}
                            onChange={salesItemReturnFormik.handleChange}
                            value={salesItemReturnFormik.values.stone}
                        />     
                    </Col>
                    <Col span={8} className="gutter-box">
                        <div>
                            <span className="hintText">Waste</span>                            
                        </div> 
                        <Input
                            type="text"
                            addonAfter={wasteTypes(salesItemReturnFormik)}
                            id="waste"
                            style={{ width: '100%'}}
                            onChange={salesItemReturnFormik.handleChange}
                            value={salesItemReturnFormik.values.waste}
                        />                                
                    </Col>
                </Row>
                <Row gutter={16} className="gutter-row">
                    {/* <Col span={4} className="gutter-box">
                        <div>
                            <span className="hintText">Waste (%)</span>                            
                        </div> 
                        <Input
                            type="text"
                            id="touch"
                            style={{ width: '100%'}}
                            onChange={salesItemReturnFormik.handleChange}
                            value={salesItemReturnFormik.values.touch}
                        />                                
                    </Col> */}
                    <Col span={5} className="gutter-box">
                        <div>
                            <span className="hintText">MC Amount</span>                            
                        </div> 
                        <Input
                            type="text"
                            id="mc_amount"
                            style={{ width: '100%'}}
                            onChange={salesItemReturnFormik.handleChange}
                            value={salesItemReturnFormik.values.mc_amount}
                        />                                
                    </Col>
                    <Col span={6} className="gutter-box">
                        <div>
                            <span className="hintText">HUID</span>                            
                        </div> 
                        <Input
                            type="text"
                            id="huid"
                            style={{ width: '100%'}}
                            onChange={salesItemReturnFormik.handleChange}
                            value={salesItemReturnFormik.values.huid}
                        />                                
                    </Col>
                    <Col span={13} className="gutter-box">
                        <div>
                            <span className="hintText">Other Details</span>                            
                        </div> 
                        <Input
                            type="text"
                            id="other_details"
                            style={{ width: '100%'}}
                            onChange={salesItemReturnFormik.handleChange}
                            value={salesItemReturnFormik.values.other_details}
                        />     
                    </Col>  
                </Row>
                <Row gutter={16} className="gutter-row">
                    <Col className="gutter-box">
                        <div>
                            <span className="hintText">Amount</span>                            
                        </div> 
                        <Input
                            type="text"
                            prefix='₹'
                            id="amount"
                            disabled={true}
                            style={{ width: '100%', padding: '7px', color: '#000000'}}
                            onChange={salesItemReturnFormik.handleChange}
                            value={salesItemReturnFormik.values.amount}
                        />                             
                    </Col>
                </Row>
            </>
        )
    }

    const salesForm = () => {
        return (
            <>
                <Row gutter={16} justify={"end"} className="gutter-row">
                    <Col span={8} className="gutter-box">
                        <div>
                            <span className="hintText color-black" style={{fontWeight: '600'}}>PAYMENT MODE</span>                            
                        </div> 
                        <Radio.Group 
                            style={{marginTop: '5px', fontSize:' 16px'}}
                            onChange={(e) => {formik.setFieldValue('payment_mode_1', e.target.value)}} value={formik.values.payment_mode_1}>
                            {paymentOptions.map((option, index) => <Radio key={index} style={{fontSize:'13px', fontFamily:'poppins'}} value={option.mop}>{option.mop}</Radio>)}                
                        </Radio.Group>
                    </Col>
                    <Col span={8} className="gutter-box">
                        <div>
                            <span className="hintText color-black">Bank</span>                            
                        </div> 
                        <Select
                            id="account_1"
                            disabled={formik.values.payment_mode_1 === 'Cash'}
                            placeholder="Select an Account"
                            style={{ width: '75%', height: '38px' }}
                            options={accountList}
                            onChange={(e)=> {
                                formik.setFieldValue("account_1", e);
                            }}
                        />
                    </Col>
                    <Col span={6} className="gutter-box">
                        <div style={{textAlign: 'right'}}>
                            <span className="hintText color-black">Payment To Customer</span>                            
                        </div> 
                        <Input
                            prefix='₹'
                            id="paid_amount_1"
                            className='input-number-with-prefix'
                            onChange={(e) => {
                                formik.setFieldValue('paid_amount_1', e.target.value);
                            }}
                            value={formik.values.paid_amount_1}
                        /> 
                    </Col>
                </Row>
                <Row gutter={16} justify={"end"} className="gutter-row">
                    <Col span={8} className="gutter-box" style={{display: 'flex', flexDirection: 'column', alignItems: 'end', justifyContent: 'start'}}>
                        <Statistic 
                            prefix='₹'
                            title="Total Return Amount" 
                            value={formik.values.total_amount} 
                            precision={3}
                            style={{textAlign:'right', border: '1px dotted #d3d3d3', padding: '10px'}}
                            valueStyle={{ width: '275px', fontSize: '28px', textAlign:'right', color: '#000000', fontWeight: 'bolder'}} />
                    </Col>
                </Row>
            </>
        )
    }

    const salesReturnContainer = () => {
        return (
            <>
                <Row gutter={16} className="gutter-row" justify="center" style={{margin: '0px 0px'}}>
                    <Col span={24} className="gutter-box" style={{textAlign: salesReturnItemList.length === 0 ? 'center' : 'right'}}>
                        <span className="saleBillText" style={{marginRight: '15px', fontSize: '13px', display: salesReturnItemList.length === 0 ? 'inline' : 'none'}}>
                            No sale return item added so far, click here to add
                        </span>
                    </Col>                  
                </Row>
                <Row gutter={16} className="gutter-row" justify="center" style={{margin: '0px 0px'}}>
                    <Col span={24} className="gutter-box" style={{textAlign: salesReturnItemList.length === 0 ? 'center' : 'right'}}>
                        <Button 
                            type='primary'
                            icon={<PlusOutlined />}
                            onClick={() => showModal('RETURN')}
                            style={{ width: '50px', height: '36px', fontFamily: 'poppins', fontSize: '15px'}}>
                        </Button>
                    </Col>                  
                </Row>
                {salesItemTable(salesReturnItemList)}
            </>
        )
    }

    const createNewFormSection = () => {
		return (
			<>
                {salesOrderForm()}
                <Divider orientation='left' orientationMargin='0'>Sales Return</Divider>
                {salesReturnContainer()}
                <Card style={{marginTop: '35px'}}>
                    {salesForm()}
                </Card>                
			</>
		);
	};

    const getCurrentRate = (type) => itemTypes.find(it => it.name.toLowerCase() === type.toLowerCase())?.current_rate;

    const totalOldGoldWeight = () => salesReturnItemList.reduce((acc, next) => parseFloat(acc) + parseFloat(next.weight), 0);  

    const getPageMargins = () => {
		return `@page { size: 80mm; margin: 10px !important; }`;
	};

	return (
		<>
		<PageTitle
			imagesrc={materialreturnImage}
			pageTitle={isUpdate ? 'Create Material Return' : 'Update Material Return'}
			buttonList={[{
				buttonName: 'Back',
				className: 'btn-back',
				url: '/slj/material-return'
			}]}
		/>
		{contextHolder}
			<div className="content-area">
				<div className="formAreaStyle">
					{ createNewFormSection() }		
				</div>
			</div>
			<div className="formSubmitArea">
				<div className="formSubmitInnerArea">
                    <Button
                        icon={<FileImageOutlined />}
						type="primary"
						size="large"
						style={{ width: '162px', backgroundColor: 'green', borderRadius: '4px' }}
						onClick={() => {
                            if(hasValidated(formik.values)) {
                                handlePrint();
                            }                            
                        }}
					>
						Material Return
					</Button>
					<Button
                        icon={<ClearOutlined />}
						type="primary"
						size="large"
						style={{ width: '150px', backgroundColor: 'grey', borderRadius: '4px' }}
						onClick={formik.handleReset}
					>
						Reset Values
					</Button>
					<Button
                        icon={<SaveOutlined />}
						type="primary"
						size="large"
						style={{ width: '150px', backgroundColor: '#1f43e5', borderRadius: '4px' }}
						onClick={formik.handleSubmit}
					>
						Submit
					</Button>
				</div>
			</div>
            <Modal title={isEdit ? 'Update Return Details' : 'Enter Return Details'} 
                closable={false} maskClosable={false} keyboard={false} 
                open={modelType === 'RETURN'} 
                okText={isEdit ? 'Update Return Details' : 'Add Return Details'} 
                onOk={() => handleOk()} width={'800px'} onCancel={handleCancel}>
                <div>
                    {salesItemReturnForm()}
                </div>
            </Modal>
            <Modal title='New Customer' 
                    closable={false} maskClosable={false} keyboard={false} 
                    open={modelType === 'NEW_CUSTOMER'} okText={'Create Customer'} 
                    onOk={() => {createCustomer()}} width={'500px'} onCancel={handleCancel}>
                <div>
                    {newCustomer()}
                </div>
            </Modal>
		</>
	);
}

export default MaterialReturnForm;