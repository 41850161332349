import React, { useEffect, useState } from 'react';
import { Button, Col, Divider, Input, Row, Select, Switch, notification } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import ItemImage from '../../../assets/images/item.png';
import '../../../css/container.css';
import '../../../css/SmallComponents.css';
import httpCall from '../../../utils/api';
import { useFormik } from 'formik';
import PageTitle from '../../common/PageTitle';
import { useLocation, useNavigate } from 'react-router-dom';
const {Option} = Select;

const ItemDataForm = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [units, setUnits] = useState([]);
    const [itemTypes, setItemTypes] = useState([]);
    const [api, contextHolder] = notification.useNotification();

    const formik = useFormik({
        initialValues: {
            category_id: '',
            sub_category_id: '',
            itemname: '',
            unit: '',
            item_type: '',
            weight: 0,
            stone_per: 0,
            stone_amount: 0,
            waste: '',
            waste_unit: 'g',
            hsn: '',
            tax_exempted: false,
            igst: '',
            cgst: '',
            sgst: '',
            huid: '',
            opg_stock_weight: 0,
            opg_stock_pcs: 0,
            opg_stock_rpg: 0,
            opg_stock_amount: 0
        },
        onSubmit: (values, { resetForm }) => {
            if (validateFields(values)) {
                if (location?.state?.id) {
                    updateItemData(values);
                } else {
                    createItemData(values, resetForm);
                }
            } else {
                openErrorNotification('Validation Error!', 'Please fill all required fields.');
            }
        },
    });

	const validateFields = (values) => {
        // Define required fields
        const requiredFields = [
            'category_id',
            'sub_category_id',
            'itemname',
            'unit',
            'item_type',
            'hsn',
            'igst', // Assuming IGST is required; adjust as needed
        ];

        // Check if all required fields are filled
        return requiredFields.every(field => values[field]);
    };
		

    useEffect(() => {
        if(formik.values.category_id && formik.values.sub_category_id) {
            const cName = categories.find(c => c.value === formik.values.category_id);
            const scName = subCategories.find(c => c.value === formik.values.sub_category_id);
            formik.setFieldValue('itemname', `${cName?.label ?? ''} ${scName?.label ?? ''}`);
        }
	}, [formik.values.category_id, formik.values.sub_category_id]);

	useEffect(() => {
        fetchCategories();
        fetchUnits();
        fetchItemTypes();
		if(location?.state?.id) {
			httpCall({
				method: 'GET',
				url: '/api/item/get?id=' + location?.state?.id,
			})
				.then((response) => {
                    fetchSubCategories(response.data.category_id);
                    setTimeout(() => {
                        formik.setValues(response.data);
						formik.setFieldValue('tax_exempted', response.data.igst === 0);
                    }, 1000)
                    
				})
				.catch((error) => {
					console.error(error);
				});
		}
	}, []);

	const wasteTypes = (form) => (
        <Select value={form.values.waste_unit} style={{ width: 60 }} onChange={(e) => form.setFieldValue("waste_unit", e)}>
          <Option value="g">g</Option>
          <Option value="%">%</Option>
        </Select>
    );

    const fetchCategories = () => {
		httpCall({
			method: 'GET',
			url: '/api/category/list',
		})
			.then((response) => {
				if (response) {
                    setCategories(response?.data.map(c => ({label: c.category_name, value: c.category_id})));
				} else {
					console.error(
						'Error retrieving categories data:',
						response.message
					);
				}
			})
			.catch((error) => {
				console.error('Error retrieving categories data:', error);
			});
	};

    const fetchSubCategories = (id) => {
		httpCall({
			method: 'GET',
			url: '/api/subcategory/list/category?id=' + id,
		})
			.then((response) => {
				if (response) {
					setSubCategories(response?.data.map(sc => ({label: sc.sub_category_name, value: sc.sub_category_id})));
				} else {
					console.error(
						'Error retrieving sub categories data:',
						response.message
					);
				}
			})
			.catch((error) => {
				console.error('Error retrieving sub categories data:', error);
			});
	};

    const fetchUnits = () => {
		httpCall({
			method: 'GET',
			url: '/api/uom/get',
		})
			.then((response) => {
				if (response) {
                    setUnits(response?.data.map(unit => ({label: unit.symbol, value: unit.symbol})));
				} else {
					console.error(
						'Error retrieving units:',
						response.message
					);
				}
			})
			.catch((error) => {
				console.error('Error retrieving units:', error);
			});
	};

    const fetchItemTypes = () => {
		httpCall({
			method: 'GET',
			url: '/api/itemtype/list',
		})
			.then((response) => {
				if (response) {
                    setItemTypes(response?.data.map(type => ({label: type.name, value: type.name})));
				} else {
					console.error(
						'Error retrieving units:',
						response.message
					);
				}
			})
			.catch((error) => {
				console.error('Error retrieving units:', error);
			});
	};

	const openNotification = (description) => {
		api.open({
		  message: 'Success !',
		  duration: 2,		  
		  description: description,
		  icon: <CheckCircleFilled style={{ color: '#808000' }} />,		  
		});
	};

	const createItemData = (values, handleReset) => {
		httpCall({
		  method: 'POST',
		  url: '/api/item/create',
		  payload: values,
		})
		  .then((response) => {
			if (response.data.success) {
			  openNotification('Success!', 'Item Data Created Successfully, Go to list view or create another one.');		
			  handleReset();
			} else {
			  // Show the error message from the response if it's not successful
			  openErrorNotification('Error!', response.data.message); // Show duplicate error message
			}
		  })
		  .catch((error) => {
			console.error(error);
			
			// Check if the error response has a specific status
			if (error.response && error.response.status === 400) {
			  // If the error is a duplicate entry error, show that message
			  openErrorNotification('Duplicate Entry!', error.response.data.message); 
			} else {
			  // Generic error message for any other issues
			  openErrorNotification('Error!', 'An error occurred while creating item data.');
			}
		  });
	  };

	  const openErrorNotification = (message, description) => {
		api.error({
			message: message,
			duration: 4,
			description: description,
		});
	};
	  
	  

	const updateItemData = (values) => {
        httpCall({
            method: 'PUT',
            url: '/api/item/edit',
            payload: {...values, itemid: location?.state?.id},
        })
            .then((response) => {
                if (response.data.success) {
                    openNotification('Item Details Updated Successfully, Goto list view. ');        
                    setTimeout(() => navigate('/slj/item-data'), 2500)
                } else {
                    // Handle any other non-success responses
                    openErrorNotification('Update Failed', response.data.message || 'An error occurred while updating the item.');
                }
            })
            .catch((error) => {
                console.error(error);
                if (error.response && error.response.status === 400) {
                    // Handle the duplicate item error
                    openErrorNotification('Duplicate Item', error.response.data.message || 'An item with the same details already exists.');
                } else {
                    // Handle other types of errors
                    openErrorNotification('Update Error', 'An unexpected error occurred while updating the item.');
                }
            });
    };

    const isEditMode = Boolean(location?.state?.id);

    const createNewFormSection = () => {
		return (
			<>
				<Row gutter={16} className="gutter-row">
                    <Col span={12} className="gutter-box">
                        <div>
                            <span className="hintText">Product</span>
                            <span className="mandatory"></span>
                        </div>                                
                        <Select
                            id="category_id"
                            style={{ width: '100%', height: '38px' }}
                            options={categories}
                            value={categories.find(c => c.value === formik.values.category_id)}
                            onChange={(e)=> {
                                formik.setFieldValue("category_id", e);
                                formik.setFieldValue("sub_category_id", '');
                                fetchSubCategories(e);
                            }}
                        />
                    </Col>
                    <Col span={12} className="gutter-box">
                        <div>
                            <span className="hintText">Sub Product</span>
                            <span className="mandatory"></span>
                        </div>                                
                        <Select
                            id="sub_category_id"
                            style={{ width: '100%', height: '38px' }}
                            options={subCategories}
                            value={subCategories.find(sc => sc.value === formik.values.sub_category_id)}
                            onChange={(e)=> {
                                formik.setFieldValue("sub_category_id", e);
                            }}
                        />
                    </Col>
                </Row>
                <Row gutter={16} className="gutter-row">
                    <Col span={16} className="gutter-box">
                        <div>
                            <span className="hintText">Item Name</span>
                            <span className="mandatory"></span>
                        </div>                                
                        <Input
                            type="text"
                            id="itemname"
                            disabled={true}
                            style={{ width: '100%', height: '38px' }}
                            onChange={formik.handleChange}
                            value={formik.values['itemname']}
                        />
                    </Col>
                    <Col span={4} className="gutter-box">
                        <div>
                            <span className="hintText">Unit</span>
                            <span className="mandatory"></span>
                        </div>                                
                        <Select
                            id="unit"
                            style={{ width: '100%', height: '38px' }}
                            options={units}
                            value={units.find(u => u.value === formik.values.unit)}
                            onChange={(e)=> {
                                formik.setFieldValue("unit", e);
                            }}
                        />
                    </Col>
                    <Col span={4} className="gutter-box">
                        <div>
                            <span className="hintText">Material Type</span>
                            <span className="mandatory"></span>
                        </div>                                
                        <Select
                            id="item_type"
                            style={{ width: '100%', height: '38px' }}
                            options={itemTypes}
                            value={itemTypes.find(it => it.value === formik.values.item_type)}
							onChange={(e)=> {
                                formik.setFieldValue("item_type", e);
                            }}
                        />
                    </Col>
                </Row>
                <Row gutter={16} className="gutter-row">
                    <Col span={4} className="gutter-box">
                        <div>
                            <span className="hintText">Weight (g)</span>
                        </div>                                
                        <Input
                            type="text"
                            id="weight"
                            style={{ width: '100%' }}
                            onChange={formik.handleChange}
                            value={formik.values['weight']}
                        />
                    </Col>
					<Col span={4} className="gutter-box">
                        <div>
                            <span className="hintText">Stone (%)</span>
                        </div>                                
                        <Input
                            type="text"
                            id="stone_per"
                            style={{ width: '100%' }}
                            onChange={formik.handleChange}
                            value={formik.values['stone_per']}
                        />
                    </Col>
                    <Col span={4} className="gutter-box">
                        <div>
                            <span className="hintText">Stone Amount</span>
                        </div>                                
                        <Input
                            type="text"
                            id="stone_amount"
                            style={{ width: '100%' }}
                            onChange={formik.handleChange}
                            value={formik.values['stone_amount']}
                        />
                    </Col>
					<Col span={4} className="gutter-box">
                        <div>
                            <span className="hintText">Waste</span>
                        </div>
						<Input
                            type="text"
                            addonAfter={wasteTypes(formik)}
                            id="waste"
                            style={{ width: '100%'}}
                            onChange={formik.handleChange}
                            value={formik.values['waste']}
                        />
                    </Col>
					<Col span={4} className="gutter-box">
                        <div>
                            <span className="hintText">HSN Code</span>
                            <span className="mandatory"></span>
                        </div>                                
                        <Input
                            type="text"
                            id="hsn"
                            style={{ width: '100%' }}
                            onChange={formik.handleChange}
                            value={formik.values['hsn']}
                        />
                    </Col>
                </Row>
				<Row gutter={16} className="gutter-row">
                    <Col span={6} className="gutter-box">
                        <div>
                            <span className="hintText">TAX Exempted</span>
                            <span className="mandatory"></span>
                        </div>                                
						<Switch checked={formik.values.tax_exempted} onChange={(e) => {
							formik.setFieldValue('tax_exempted', e);
							if(e) {
								formik.setFieldValue('igst', 0);
								formik.setFieldValue('cgst', 0);
								formik.setFieldValue('sgst', 0);
							}
						}}></Switch>
                    </Col>
                    <Col span={6} className="gutter-box">
                        <div>
                            <span className="hintText">IGST</span>
                            <span className="mandatory"></span>
                        </div>                                
                        <Input
                            type="text"
                            id="igst"
							disabled={formik.values.tax_exempted}
                            style={{ width: '100%' }}
                            onChange={(e) => {
								const igst = parseFloat(e.target.value || 0);								
								formik.setFieldValue('igst', igst);
								formik.setFieldValue('cgst', igst / 2);
								formik.setFieldValue('sgst', igst / 2);
							}}
                            value={formik.values['igst']}
                        />
                    </Col>
					<Col span={6} className="gutter-box">
                        <div>
                            <span className="hintText">CGST</span>
                            <span className="mandatory"></span>
                        </div>                                
                        <Input
                            type="text"
							disabled={true}
                            id="cgst"
                            style={{ width: '100%' }}
                            value={formik.values['cgst']}
                        />
                    </Col>
					<Col span={6} className="gutter-box">
                        <div>
                            <span className="hintText">SGST</span>
                            <span className="mandatory"></span>
                        </div>                                
                        <Input
                            type="text"
							disabled={true}
                            id="sgst"
                            style={{ width: '100%' }}
                            value={formik.values['sgst']}
                        />
                    </Col>
                </Row>
                <Divider orientation='left' orientationMargin='0'>Opening Stock Details</Divider>
                <Row gutter={16} className="gutter-row">
                    <Col span={4} className="gutter-box">
                        <div>
                            <span className="hintText">Weight(g)</span>
                        </div>
                        <Input
                            type="text"
                            id="opg_stock_weight"
                            style={{ width: '100%'}}
                            onChange={formik.handleChange}
                            value={formik.values['opg_stock_weight']}
                            disabled={isEditMode} // Disable in edit mode
                        />
                    </Col>
                    <Col span={4} className="gutter-box">
                        <div>
                            <span className="hintText">Pcs</span>
                        </div>
                        <Input
                            type="text"
                            id="opg_stock_pcs"
                            style={{ width: '100%'}}
                            onChange={formik.handleChange}
                            value={formik.values['opg_stock_pcs']}
                            disabled={isEditMode} // Disable in edit mode
                        />
                    </Col>
                    <Col span={4} className="gutter-box">
                        <div>
                            <span className="hintText">Rate Per Gram</span>
                        </div>
                        <Input
                            type="text"
                            id="opg_stock_rpg"
                            style={{ width: '100%'}}
                            onChange={formik.handleChange}
                            value={formik.values['opg_stock_rpg']}
                            disabled={isEditMode} // Disable in edit mode
                        />
                    </Col>
                    <Col span={4} className="gutter-box">
                        <div>
                            <span className="hintText">Amount</span>
                        </div>
                        <Input
                            type="text"
                            id="opg_stock_amount"
                            style={{ width: '100%'}}
                            onChange={formik.handleChange}
                            value={formik.values['opg_stock_amount']}
                            disabled={isEditMode} // Disable in edit mode
                        />
                    </Col>
                    <Col span={4} className="gutter-box">
                        <div>
                            <span className="hintText">HUID</span>
                            <span className="mandatory"></span>
                        </div>                                
                        <Input
                            type="text"
                            id="huid"
                            style={{ width: '100%' }}
                            onChange={formik.handleChange}
                            value={formik.values['huid']}
                        />
                    </Col>
                </Row>
            </>
        );
    };

    return (
        <>
            <PageTitle
                imagesrc={ItemImage}
                pageTitle="Create Item Data"
                buttonList={[{
                    buttonName: 'Back',
                    className: 'btn-back',
                    url: '/slj/item-data'
                }]}
            />
            {contextHolder}
            <div className="content-area">
                <div className="formAreaStyle">
                    { createNewFormSection() }
                </div>
            </div>
            <div className="formSubmitArea">
                <div className="formSubmitInnerArea">
                    <Button
                        type="primary"
                        size="large"
                        style={{ width: '170px', backgroundColor: 'grey' }}
                        onClick={formik.handleReset}
                    >
                        Reset Fields
                    </Button>
                    <Button
                        type="primary"
                        size="large"
                        style={{ width: '170px', backgroundColor: '#1f43e5' }}
                        onClick={formik.handleSubmit}
                    >
                        Submit
                    </Button>
                </div>
            </div>
        </>
    );
};

export default ItemDataForm;