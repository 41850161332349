import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, DatePicker, Divider, Input, InputNumber, Modal, Radio, Row, Select, Space, Spin, Statistic, Switch, Table, Tag, notification } from 'antd';
import { ScanOutlined,AlertOutlined, CheckCircleFilled, ClearOutlined, DeleteFilled, EditFilled, FileImageOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import SaleImage from '../../assets/images/estimate.svg';
import '../../css/container.css';
import '../../css/SmallComponents.css';
import httpCall from '../../utils/api';
import { useFormik } from 'formik';
import PageTitle from '../common/PageTitle';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import { ExclamationCircleFilled } from '@ant-design/icons';
const { confirm } = Modal;

const {Option} = Select;

const EstimationForm = () => { 
	const location = useLocation();
	const navigate = useNavigate();
    const [costCenterList, setCostCenterList] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const [itemDataList, setItemDataList] = useState([]);
    const [itemDataOptions, setItemDataOptions] = useState([]);
    const [custMobileOptions, setCustMobileOptions] = useState([]);
	const [api, contextHolder] = notification.useNotification();
    const [salesItemList, setSalesItemList] = useState([]);
    const [salesReturnItemList, setSalesReturnItemList] = useState([]);
    const [quotationNrList, setQuotationNrList] = useState([]);
    const [plants, setPlants] = useState([]);
    const [plantOptions, setPlantOptions] = useState([]);
    const [paymentOptions, setPaymentOptions] = useState([]);
    const [accountList, setAccountList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [itemTypes, setItemTypes] = useState([]);
    const [modelType, setModelType] = useState('');
    const [isNewCustomer, setIsNewCustomer] = useState(false);  
    const [isQuotSelEnabled, setIsQuotSelEnabled] = useState(false);
    const [isFromQuotation, setIsFromQuotation] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const componentRef = useRef();
    
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        removeAfterPrint: true,
    });
    
    const showModal = (type, isEdit) => {
        setIsEdit(!!isEdit);
        setModelType(type);
    };

    const handleOk = async (isSaleType) => {
        if(isSaleType) {
            const stock = await fetchStock(salesItemFormik.values.item_id);
            const pcs = parseInt(stock.data.pcs_total || 0);
            const weight = parseFloat(stock.data.weight_total || 0);
            if(pcs >= parseInt(salesItemFormik.values.pcs) && weight >= parseInt(salesItemFormik.values.weight)) {
                addNewItem(salesItemFormik, salesItemList, setSalesItemList, false);
            } else {
                openErrorNotification(`Item stock not available, available stock [pcs=${pcs}, weight=${weight}]`);
            }
        } else {
            addNewItem(salesItemReturnFormik, salesReturnItemList, setSalesReturnItemList, true);
        }        
    };

    const handleCancel = () => {
        setModelType('');
        setIsNewCustomer(false);
    };

    const formik = useFormik({
		initialValues: {
            invoice_no_inc: '',
            invoice_no: '',
            invoice_receipt_no: '',
            invoice_receipt_no_inc: '', 
            customer_id: '',
            invoice_date: moment(),
            items_amount: '',
            return_items_amount: '',
            total_amount: '',
            pending_amount: '',
            sales_person_id: '',
            isNewCustomer: false,
            customer_mobile: '',
            customer_name: '',
            customer_address: '',
            new_customer_mobile: '',
            new_customer_name: '',
            new_customer_address: '',
            plant_code: '',
            quotationHeader: '',
            module: 'ESTIMATION',
            salesPerson: {},
            plant: {},
            customer: {},
            account_1: '',
            payment_mode_1: 'Cash',
            payment_1: 0.0,
            account_2: '',
            payment_mode_2: 'Cash',
            payment_2: 0.0,
            discount: 0.0
		},
		onSubmit: (values, {resetForm}) => {
            if(!hasValidated(values)) {
                return;
            }
			if(location?.state?.id) {
				updateEstimation(values, resetForm);
			} else {
				createEstimation(values, resetForm);
			}
		},
	});

    const salesItemFormik = useFormik({
		initialValues: {
            item_id: '',
            sales_item_name: '',
            item_option: {},
            pcs: '',
            weight: '',
            igst: '',
            cgst: '',
            sgst: '',
            tax_amount: '',
            amount: '',
            stone: '',
            gross_weight:'',
            stone_weight:'',
            waste: '',
            waste_unit: 'g',
            huid: '',
            mc_amount: '',
            other_details: ''
		}
	});

    const salesItemReturnFormik = useFormik({
		initialValues: {
            item_id: '',
            sales_item_name: '',
            item_option: {},
            pcs: '',
            weight: '',
            amount: '',
            stone: '',
            gross_weight:'',
            stone_weight:'',
            waste: '',
            waste_unit: 'g',
            huid: '',
            mc_amount: '',
            other_details: ''
		}
	});

    const hasValidated = (values) => {
        if(values.customer_id === '') {
            openErrorNotification('Please Select a Customer.');
            return false;
        }
        if(values.plant_code === '') {
            openErrorNotification('Please Select a Plant.');
            return false;
        }
        if(values.sales_person_id === '') {
            openErrorNotification('Please Select Sales Person');
            return;
        }
        if(salesItemList.length === 0) {
            openErrorNotification('Please add a sales item');
            return false;
        }

        return true;
    }

    useEffect(() => {
        fetchQuotationNrList();
        fetchCostCenter();
        fetchCustomerList();
        fetchItemDataList();
        fetchPlantList();
        fetchItemTypes();
        fetchAccountList();
        fetchPaymentOptions();
		if(location?.state?.id) {
            setTimeout(() => {
                prefillQuotationValues(location?.state?.id);
            }, 0);            
		} else {
            fetchNextBillNo();
            fetchInvoiceReceiptNr();
        }
	}, []);

    useEffect(() => {        
        if(isFromQuotation) {
            const salesPerson = costCenterList?.find(sp => sp.value === formik.values.sales_person_id);
            formik.setFieldValue('salesPerson', salesPerson);
            
            const plant = plantOptions?.find(p => p.value === formik.values.plant_code);
            formik.setFieldValue('plant', plant);

            const customer = customerList?.find(c => c.id === formik.values.customer_id);
            formik.setFieldValue('customer', {label: customer.mobile_number, value: customer.mobile_number});
            formik.setFieldValue("customer_id", customer.id);
            formik.setFieldValue("customer_name", customer.company_name);
            formik.setFieldValue("customer_address", customer.address);

        }
	}, [isFromQuotation]);

    const prefillQuotationValues = (id) => {
        httpCall({
            method: 'GET',
            url: '/api/sales/quotation?quotationno=' + id,
        })
            .then((response) => {
                const quoDetails = response.data.quotationDetails;
                const items = response.data.items;
                const return_items = response.data.return_items;
                setIsFromQuotation(true);
                
                formik.setFieldValue('quotation_no', quoDetails.quotation_no);
                formik.setFieldValue('sales_person_id', quoDetails.sales_person_id);
                formik.setFieldValue('plant_code',  quoDetails.plant_code);
                formik.setFieldValue('customer_id',  quoDetails.customer_id);
                formik.setFieldValue('items_amount', Number(quoDetails.items_amount));
                formik.setFieldValue('return_items_amount', Number(quoDetails.return_items_amount));
                formik.setFieldValue('total_amount', Number(quoDetails.total_amount));
                const latestSalesItem = [];
                const latestReturnItem = [];
                for (let index = 0; index < items.length; index++) {
                    const item = items[index];
                    const salesItem = {
                        isSalesReturn: false,
                        sub_module: 'ITEM',
                        item_id: item.item_id,
                        sales_item_name: item.item_name,
                        item_option: {label: item.item_name, value: item.item_name},
                        pcs: item.pcs || 0,
                        weight: item.weight,
                        current_rate: item.current_rate,
                        amount: item.amount,
                        stone: item.stone,
                        waste: item.waste,
                        gross_weight:item.gross_weight,
                        stone_weight:item.stone_weight,
                        waste_unit: item.waste_unit,
                        mc_amount: item.mc_amount,
                        huid: item.huid,
                        igst: item.igst,
                        cgst: item.cgst,
                        sgst: item.sgst,
                        tax_amount: item.tax_amount,
                        other_details: item.other_details
                    }
                    latestSalesItem.push(salesItem);
                }
                setSalesItemList(latestSalesItem);

                for (let index = 0; index < return_items.length; index++) {
                    const item = return_items[index];
                    const salesReturnItem = {
                        isSalesReturn: true,
                        sub_module: 'RETURN_ITEM',
                        item_id: item.item_id,
                        sales_item_name: item.item_name,
                        item_option: {label: item.item_name, value: item.item_name},
                        pcs: item.pcs || 0,
                        weight: item.weight,
                        current_rate: item.current_rate,
                        amount: item.amount,
                        stone: item.stone,
                        waste: item.waste,
                        gross_weight:item.gross_weight,
                        stone_weight:item.stone_weight,
                        waste_unit: item.waste_unit,
                        mc_amount: item.mc_amount,
                        huid: item.huid,
                        igst: item.igst,
                        cgst: item.cgst,
                        sgst: item.sgst,
                        tax_amount: item.tax_amount,
                        other_details: item.other_details
                    }
                    latestReturnItem.push(salesReturnItem);
                }
                setSalesReturnItemList(latestReturnItem);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const clearForm = () => {
        const invoice_no_inc = formik.values.invoice_no_inc;
        const invoice_no = formik.values.invoice_no;
        formik.resetForm();
        formik.setFieldValue('invoice_no_inc', invoice_no_inc);
        formik.setFieldValue('invoice_no', invoice_no);
        setSalesItemList([]);
        setSalesReturnItemList([]);
    }

    const showQuotationConfirm = () => {
        confirm({
          title: !isQuotSelEnabled ? 'Do you want to add Estimation manually?' : 'Do you want to enable Quotation selection?',
          icon: <ExclamationCircleFilled />,
          content: 'Current form data will be lost if you click Ok !',
          onOk() {
            setIsQuotSelEnabled(!isQuotSelEnabled);
            clearForm();
          },
          onCancel() {
          },
        });
      };

    const fetchQuotationNrList = () => {
		httpCall({
			method: 'GET',
			url: '/api/sales/getQuotationNrList',
		})
			.then((response) => {
                setQuotationNrList(response?.data.map(qt => ({label: qt.quotation_no, value: qt.quotation_no})))
			})
			.catch((error) => {
			});
	};

    const fetchStock = (item_id) => {
		return httpCall({
			method: 'GET',
			url: '/api/sales/stock/' + item_id,
		});
	};

    const fetchItemTypes = () => {
		setLoading(true);
		httpCall({
			method: 'GET',
			url: '/api/itemtype/list',
		})
			.then((response) => {
				if (response) {
                    setItemTypes(response?.data);
				} else {
					console.error(
						'Error retrieving item type list',
						response.message
					);
				}
				setLoading(false);
			})
			.catch((error) => {
				console.error('Error retrieving item type list', error);
				setLoading(false);
			});
	};

    useEffect(() => {
        calculateTotal();
	}, [salesItemList, salesReturnItemList, formik.values.discount, formik.values.payment_1, formik.values.payment_2]);

    useEffect(() => {
        if(!!salesItemFormik.values.weight && !!salesItemFormik.values.current_rate) {
            const current_rate = parseFloat(salesItemFormik.values.current_rate || 0);
            const weight = parseFloat(salesItemFormik.values.weight || 0);
            let waste = parseFloat(salesItemFormik.values.waste || 0);
            const waste_unit = salesItemFormik.values.waste_unit;
            const stone = parseFloat(salesItemFormik.values.stone || 0);

            const stone_weight = parseFloat(salesItemFormik.values.stone_weight || 0);
            const gross_weight = parseFloat(salesItemFormik.values.gross_weight || 0);
            const mc_amount = parseFloat(salesItemFormik.values.mc_amount || 0);
            waste = waste_unit === '%' ? (weight * waste) / 100 : waste;

            let amt = ((weight + waste) * current_rate) + stone + mc_amount;

            const igst = parseFloat(salesItemFormik.values.cgst || 0) + parseFloat(salesItemFormik.values.sgst || 0);
            const igstAmt = (amt * igst)/100;
            salesItemFormik.setFieldValue('tax_amount', igstAmt.toFixed(3));
            amt = amt + igstAmt;
            salesItemFormik.setFieldValue('amount', amt.toFixed(3));
        }
	}, [salesItemFormik.values.weight,
        salesItemFormik.values.waste, 
        salesItemFormik.values.waste_unit,
        salesItemFormik.values.stone, 
        salesItemFormik.values.mc_amount,
        salesItemFormik.values.gross_weight,
        salesItemFormik.values.stone_weight,
    ]);

    useEffect(() => {
        if(!!salesItemReturnFormik.values.weight && !!salesItemReturnFormik.values.current_rate) {
            const current_rate = parseFloat(salesItemReturnFormik.values.current_rate || 0);
            const weight = parseFloat(salesItemReturnFormik.values.weight || 0);
            let waste = parseFloat(salesItemReturnFormik.values.waste || 0);
            const waste_unit = salesItemReturnFormik.values.waste_unit;
            const stone = parseFloat(salesItemReturnFormik.values.stone || 0);
            const stone_weight = parseFloat(salesItemFormik.values.stone_weight || 0);
            const gross_weight = parseFloat(salesItemFormik.values.gross_weight || 0);
            const mc_amount = parseFloat(salesItemReturnFormik.values.mc_amount || 0);
            waste = waste_unit === '%' ? (weight * waste) / 100 : waste;

            const amt = ((weight - waste) * current_rate) - stone - mc_amount;
            salesItemReturnFormik.setFieldValue('amount', amt.toFixed(3));
        }
	}, [salesItemReturnFormik.values.weight,
        salesItemReturnFormik.values.current_rate,
        salesItemReturnFormik.values.waste, 
        salesItemReturnFormik.values.waste_unit,
        salesItemReturnFormik.values.stone, 
        salesItemReturnFormik.values.mc_amount,
        salesItemReturnFormik.values.gross_weight,
        salesItemReturnFormik.values.stone_weight,
    ]);

    useEffect(() => {
        const gross = parseFloat(salesItemFormik.values.gross_weight || 0);
        const stone = parseFloat(salesItemFormik.values.stone_weight || 0);
    
        if (!isNaN(gross) && !isNaN(stone) && gross && stone) {
            const netWeight = Math.max(0, gross - stone);
            salesItemFormik.setFieldValue('weight', netWeight.toFixed(3));
        } else {
            // Set empty value if either gross or stone weight is missing or invalid
            salesItemFormik.setFieldValue('weight', '');
        }
    }, [salesItemFormik.values.gross_weight, salesItemFormik.values.stone_weight]);

    useEffect(() => {
        const gross = parseFloat(salesItemReturnFormik.values.gross_weight || 0);
        const stone = parseFloat(salesItemReturnFormik.values.stone_weight || 0);
    
        if (!isNaN(gross) && !isNaN(stone) && gross && stone) {
            const netWeight = Math.max(0, gross - stone);
            salesItemReturnFormik.setFieldValue('weight', netWeight.toFixed(3));
        } else {
            // Set empty value if either gross or stone weight is missing or invalid
            salesItemReturnFormik.setFieldValue('weight', '');
        }
    }, [salesItemReturnFormik.values.gross_weight, salesItemReturnFormik.values.stone_weight]);
        
	const fetchPlantList = () => {
		httpCall({
			method: 'GET',
			url: '/api/plant/list',
		})
			.then((response) => {
                setPlants(response?.data);
                setPlantOptions(response?.data.map(p => ({label: p.plant_name, value: p.plant_code})))
			})
			.catch((error) => {
			});
	};

    const fetchPaymentOptions = () => {
		httpCall({
			method: 'GET',
			url: '/api/payment/list?filter=true',
		})
			.then((response) => {
                setPaymentOptions(response?.data);
			})
			.catch((error) => {
				console.error('Error retrieving payment list:', error);
			});
	};

    const fetchCostCenter = () => {
		httpCall({
			method: 'GET',
			url: '/api/costcenter/get',
		})
			.then((response) => {
				if (response) {
                    setCostCenterList(response?.data.map(c => ({label: c.cost_center_name, value: c.cost_center_id})));
				} else {
					console.error(
						'Error retrieving categories data:',
						response.message
					);
				}
			})
			.catch((error) => {
				console.error('Error retrieving categories data:', error);
			});
	};

    const fetchAccountList = () => {
		setLoading(true);
		httpCall({
			method: 'GET',
			url: '/api/account/list',
		})
			.then((response) => {
				if (response) {
					setAccountList(response?.data.map(acc => ({label: `${acc.bank_name} - ${acc.account_no}`, value: acc.account_no})));
				} else {
					console.error('Error retrieving account list', response.message);
				}
				setLoading(false);
			})
			.catch((error) => {
				console.error('Error retrieving account list', error);
				setLoading(false);
			});
	};

    const fetchCustomerList = () => {
		httpCall({
			method: 'GET',
			url: '/api/customer/get',
		})
			.then((response) => {
				if (response) {
                    setCustomerList(response?.data);
                    setCustMobileOptions(response?.data.map(c => ({label: c.mobile_number, value: c.mobile_number})))
				} else {
					console.error(
						'Error retrieving customer list:',
						response.message
					);
				}
			})
			.catch((error) => {
				console.error('Error retrieving customer list:', error);
			});
	};

    const fetchItemDataList = () => {
		httpCall({
			method: 'GET',
			url: '/api/item/sale/list',
		})
			.then((response) => {
				if (response) {
                    setItemDataList(response?.data);
                    setItemDataOptions(response?.data.map(i => ({label: i.itemname, value: i.itemname})));
				} else {
					console.error(
						'Error retrieving item data list:',
						response.message
					);
				}
			})
			.catch((error) => {
				console.error('Error retrieving item data list:', error);
			});
	};

    const fetchNextBillNo = () => {
		httpCall({
			method: 'GET',
			url: '/api/sales/getEstimationNr',
		})
			.then((response) => {
				if (response) {
                    formik.setFieldValue('invoice_no_inc', response.data);
                    formik.setFieldValue('invoice_no', `INV${response.data}/${getCurrentDate()}`);
				} else {
					console.error(
						'Error retrieving next billno:',
						response.message
					);
				}
			})
			.catch((error) => {
				console.error('Error retrieving next billno:', error);
			});
	};

    const fetchInvoiceReceiptNr = () => {
		httpCall({
			method: 'GET',
			url: '/api/sales/getInvoiceReceiptNr',
		})
			.then((response) => {
				if (response) {
                    formik.setFieldValue('invoice_receipt_no_inc', response.data);
                    formik.setFieldValue('invoice_receipt_no', `IR${response.data}/${getCurrentDate()}`);
				} else {
					console.error('Error retrieving next billno:', response.message);
				}
			})
			.catch((error) => {
				console.error('Error retrieving next billno:', error);
			});
	};

    const getCurrentDate = () => {
        let newDate = new Date()
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        return `${date}${month<10?`0${month}`:`${month}`}${year}`;
      }

	const openNotification = (description) => {
		api.open({
		  message: 'Success !',
		  duration: 2,		  
		  description: description,
		  icon: <CheckCircleFilled style={{ color: '#808000' }} />,		  
		});
	};

    const openErrorNotification = (description) => {
		api.error({
		  message: 'Error !',
		  duration: 4,		  
		  description: description,
		});
	};

    const createInvoiceReceipt = (values, handleReset) => {
        const invoice_receipt_date = moment(values.invoice_date).format('YYYY-MM-DD');
        const totalPaidAmount = parseFloat(values.payment_1) + parseFloat(values.payment_2);
        const totalPendingAmount = parseFloat(formik.values.total_amount);
        const reqBody = {
            invoice_receipt_no_inc: values.invoice_receipt_no_inc,
            invoice_receipt_no: values.invoice_receipt_no,
            customer_id: values.customer_id,
            payment_mode_1: values.payment_mode_1,
            account_1: values.account_1,
            paid_amount_1: values.payment_1,
            payment_mode_2: values.payment_mode_2,
            account_2: values.account_2,
            paid_amount_2: values.payment_2,
            invoice_receipt_date,
            total_paid_amount: totalPaidAmount,
            receiptItems: [
                { 
                    invoice_receipt_no: values.invoice_receipt_no,
                    invoice_no: values.invoice_no, 
                    paid_amount: totalPaidAmount,
                    pending_amount: totalPendingAmount - totalPaidAmount
                }
            ]
        };
		httpCall({
			method: 'POST',
			url: '/api/sales/invoiceReceipt',
			payload: reqBody
		})
			.then((response) => {
				if (response) {

					openNotification('Estimation Created Successfully.');		
					handleReset();
                    setTimeout(() => { navigate('/slj/estimation')})
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

    const createEstimation = (values, handleReset) => {
        const invoice_date = moment(values.invoice_date).format('YYYY-MM-DD');
        ['sales_person', 'customer', 'plant', 'item_option', 'customer_address', 'customer_mobile', 'customer_name', 'isNewCustomer', 
        'new_customer_address', 'new_customer_mobile', 'new_customer_name', 'quotationHeader'].forEach((prop) => delete values[prop]);
		httpCall({
			method: 'POST',
			url: '/api/sales/estimation',
			payload: {...values, invoice_date, items: [...salesItemList, ...salesReturnItemList]}
		})
			.then((response) => {
				if (response) {
                    if(parseInt(values.payment_1) === 0) {
                        openNotification('Estimation Created Successfully.');		
                        handleReset();
                        setTimeout(() => { navigate('/slj/estimation')})
                    } else {
                        createInvoiceReceipt(values, handleReset);
                    }
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

    const updateEstimation = (values, handleReset) => {
        ['sales_person', 'customer', 'plant', 'item_option', 'customer_address', 'customer_mobile', 'customer_name', 'isNewCustomer', 
        'new_customer_address', 'new_customer_mobile', 'new_customer_name', 'quotationHeader'].forEach((prop) => delete values[prop]);        
		httpCall({
			method: 'PUT',
			url: '/api/sales/estimation',
			payload: {...values, items: [...salesItemList, ...salesReturnItemList]}
		})
			.then((response) => {
				if (response.data.success) {
					openNotification('Estimation Details Updated Successfully, Goto list view. ');		
					setTimeout(() => navigate('/slj/estimation'), 2500)
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

    const createCustomer = () => {
		httpCall({
			method: 'POST',
			url: '/api/customer/create',
			payload: {company_name: formik.values.new_customer_name, address: formik.values.new_customer_address, mobile_number: formik.values.new_customer_mobile},
		})
			.then((response) => {
                openNotification('Customer created successfully.');
                setIsNewCustomer(false);
                setModelType('');
                fetchCustomerList();
			})
			.catch((error) => {
                if(error.response.status === 403) {
                    console.error(error.response.data.message);
                } else {
                    console.error(error);
                }
			});
    }

    const calculateTotal = () => {
        const salesBillAmount = salesItemList.reduce((acc, next) => acc + parseFloat(next.amount), 0);
        const oldGoldAmount = salesReturnItemList.reduce((acc, next) => acc + parseFloat(next.amount), 0);

        formik.setFieldValue('items_amount', salesBillAmount); 
        formik.setFieldValue('return_items_amount', oldGoldAmount);
        const totalBillAmount = (salesBillAmount - oldGoldAmount) - parseFloat(formik.values.discount || 0);
        const paymentRecvd = parseFloat(formik.values.payment_1) + parseFloat(formik.values.payment_2);
        formik.setFieldValue('total_amount', totalBillAmount);
        formik.setFieldValue('pending_amount', totalBillAmount - paymentRecvd);
    }

    const salesItemColumns = [
        {
			title: 'Item Name',
			dataIndex: 'sales_item_name',
			key: 'sales_item_name',
			align: 'center',
			width: 250
		},
        {
			title: 'PCS',
			dataIndex: 'pcs',
			key: 'pcs',		
			align: 'center',
			width: 70
		},
        {
			title: 'Weight',
			dataIndex: 'weight',
			key: 'weight',		
			align: 'center',
			width: 70
		},
        {
			title: 'Current Rate',
			dataIndex: 'current_rate',
			key: 'current_rate',		
			align: 'center',
			width: 100
		},
        {
			title: 'MC Amount',
			dataIndex: 'mc_amount',
			key: 'mc_amount',		
			align: 'center',
			width: 120
		},
        {
			title: 'HUID',
			dataIndex: 'huid',
			key: 'huid',		
			align: 'center',
			width: 100
		},
		{
			title: 'Amount',
			dataIndex: 'amount',
			key: 'amount',		
			align: 'center',
			width: 200
		},
		{
			title: 'Action',
			key: 'action',
			align: 'center',
			width: 150,
			render: (_, record) => 
                (
                    <Space size="middle">
                        <EditFilled
                            onClick={() => {
                                if(record.isSalesReturn) {
                                    const salesReturn = salesReturnItemList.find(item => item.item_id === record.item_id);                                    
                                    salesItemReturnFormik.setValues(salesReturn);
                                    showModal('RETURN', true);
                                } else {
                                    const salesItem = salesItemList.find(item => item.item_id === record.item_id);
                                    salesItemFormik.setValues(salesItem);
                                    showModal('SALE', true);
                                }                                
                            }}
                            style={{cursor: 'pointer', fontSize: '20px', color: '#08c'}}/>
                        <DeleteFilled 
                            onClick={() => {
                                if(record.isSalesReturn) {
                                    const idx = salesReturnItemList.findIndex(item => item.item_id === record.item_id);
                                    salesReturnItemList.splice(idx, 1)
                                    setSalesReturnItemList([...salesReturnItemList]);
                                } else {
                                    const idx = salesItemList.findIndex(item => item.item_id === record.item_id);
                                    salesItemList.splice(idx, 1);
                                    setSalesItemList([...salesItemList]);
                                }
                            }}
                            style={{cursor: 'pointer', fontSize: '20px', color: '#B31312'}}/>
                    </Space>
                )
		  },
	];

    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const salesItemTable = (datasoure) => {
        return datasoure.length > 0 ? (
            <>
                <Row gutter={16} className="gutter-row sales-list">
                    <Col span={24} className="gutter-box">
                    <Table
						dataSource={datasoure}
						columns={salesItemColumns}
                        pagination={{position: ['bottomCenter'], pageSize: 2}}
						rowKey="sales_item_name"
                        size='small'
                        bordered="true"						
						scroll={{ y: 100 }}
						style={{fontFamily: 'Poppins'}}/>
                    </Col>
                </Row>
            </>
        ) : null
    }

    const salesOrderForm = () => {
        return (
            <>
                <Row gutter={16} className="gutter-row">
                    {/* <Col span={4} className="gutter-box" style={{display: 'flex', alignItems: 'center'}}>
                        <Switch checked={isQuotSelEnabled} checkedChildren='New Estimation' unCheckedChildren='Select Quotation' 
                            onChange={(checked, e) => {
                                showQuotationConfirm();
                            }}
                        />
                    </Col> */}
                    <Col span={8} className="gutter-box">
                        <div>
                            <span className="hintText">Quotation Nr</span>                   
                        </div> 
                        <Select
                            id="quotation_no"
                            placeholder="Select Quotation"
                            disabled={isQuotSelEnabled}
                            style={{ width: '100%', height: '38px' }}
                            options={quotationNrList}
                            onChange={(e)=> {
                                clearForm();
                                setLoading(true);
                                setTimeout(() => {
                                    formik.setFieldValue("quotation_no", e);
                                    prefillQuotationValues(e);
                                    setLoading(false);
                                }, 2000);
                            }}
                        />   
                    </Col>
                    <Col span={4} offset={12} className="gutter-box" style={{textAlign: 'right'}}>
                        <div>
                            <span className="hintText">Estimation Nr</span>                            
                        </div> 
                        <Input
                            type="text"
                            id="invoice_no"
                            disabled={true}
                            variant="borderless"
                            style={{ width: '100%', color: '#000000', padding: '5px 2px', fontSize: '14px', fontWeight: '700',  textAlign: 'right'}}
                            value={formik.values['invoice_no']}
                        />                                
                    </Col>
                </Row>
                <Divider orientation='left' orientationMargin='0'>Estimation Details</Divider>
                <Row gutter={16} className="gutter-row">
                    <Col span={4} className="gutter-box" style={{display: 'flex', alignItems: 'center'}}>
                        <Switch disabled={isFromQuotation} checked={isNewCustomer} checkedChildren='New Customer' unCheckedChildren='Existing Customer' 
                            onChange={(checked, e) => {
                                showModal('NEW_CUSTOMER');
                                setIsNewCustomer(checked);
                            }}
                        />
                    </Col>
                    <Col span={6} className="gutter-box">
                        <div>
                            <span className="hintText">Customer Mobile Number</span>
                            <span className="mandatory"></span>
                        </div>                                
                        <Select
                            id="no"
                            showSearch
                            placeholder="Select a Number"
                            filterOption={filterOption}
                            style={{ width: '100%', height: '38px' }}
                            options={custMobileOptions}
                            value={formik.values.customer}
                            onChange={(e)=> {
                                const cust = customerList.find(c => c.mobile_number === e);
                                formik.setFieldValue("customer", e);
                                formik.setFieldValue("customer_id", cust.id);
                                formik.setFieldValue("customer_name", cust.company_name);
                                formik.setFieldValue("customer_address", cust.address);
                            }}
                        />  
                    </Col>
                    <Col span={6} className="gutter-box">
                        <div>
                            <span className="hintText">Customer Name</span>                            
                        </div> 
                        <Input
                            type="text"
                            id="customer_name"
                            disabled={true}
                            style={{ width: '100%', color: '#000000'}}
                            value={formik.values.customer_name}
                        />     
                    </Col>
                    <Col span={8} className="gutter-box">
                        <div>
                            <span className="hintText">Customer Address</span>                            
                        </div> 
                        <Input
                            type="text"
                            id="customer_address"
                            disabled={true}
                            style={{ width: '100%', color: '#000000'}}
                            value={formik.values.customer_address}
                        />                                
                    </Col>
                </Row>
                <Row gutter={16} className="gutter-row">
                    <Col span={4} className="gutter-box">
                        <div>
                            <span className="hintText">Estimation Date</span>                            
                        </div> 
                        <DatePicker
                            style={{ width: '100%', height: '38px' }}                            
                            disabled={true}
                            name='invoice_date'
                            format={{
                                format: 'YYYY-MM-DD',
                                type: 'mask',
                            }}
                            value={formik.values.invoice_date}
                        />                             
                    </Col>
                    <Col span={6} className="gutter-box">
                        <div>
                            <span className="hintText">Plant</span>
                            <span className="mandatory"></span>                         
                        </div> 
                        <Select
                            id="plant_code"
                            placeholder="Select a Plant"
                            style={{ width: '100%', height: '38px' }}
                            options={plantOptions}
                            value={formik.values.plant}
                            onChange={(e)=> {
                                formik.setFieldValue("plant_code", e);
                                formik.setFieldValue("plant", e);
                                const plant = plants.find(p => p.plant_code === e);
                                const qHeader = `${plant.plant_name}, ${plant.address}, ${plant.city}-${plant.zipcode}, ${plant.phone_no}`;
                                formik.setFieldValue("quotationHeader", qHeader);
                            }}
                        />   
                    </Col>
                    <Col span={6} className="gutter-box">
                        <div>
                            <span className="hintText">Sales Person Name</span> 
                            <span className="mandatory"></span>                       
                        </div> 
                        <Select
                            id="sales_person_id"
                            placeholder="Select a Name"
                            style={{ width: '100%', height: '38px' }}
                            options={costCenterList}
                            value={formik.values.salesPerson}
                            onChange={(e)=> {
                                formik.setFieldValue("salesPerson", e);
                                formik.setFieldValue("sales_person_id", e);
                            }}
                        />   
                    </Col>
                </Row>
            </>
        )
    }

    const newCustomer = () => {
        return (
            <>
               <Tag icon={<AlertOutlined />} color="magenta">Basic details are provided here, you can update full details in master section. </Tag>
               <Row gutter={16} className="gutter-row">
                    <Col span={24} className="gutter-box">
                        <div>
                            <span className="hintText">Customer Mobile Number</span>
                            <span className="mandatory"></span>
                        </div>                                
                        <Input
                            type="text"
                            id="new_customer_mobile"
                            style={{ width: '100%', color: '#000000'}}
                            onChange={formik.handleChange}
                            value={formik.values.new_customer_mobile}
                        />   
                    </Col>
                </Row>
                <Row gutter={16} className="gutter-row">
                    <Col span={24} className="gutter-box">
                        <div>
                            <span className="hintText">Customer Name</span>
                            <span className="mandatory"></span>                        
                        </div> 
                        <Input
                            type="text"
                            id="new_customer_name"
                            style={{ width: '100%', color: '#000000'}}
                            onChange={formik.handleChange}
                            value={formik.values.new_customer_name}
                        />     
                    </Col>
                </Row>
                <Row gutter={16} className="gutter-row">
                    <Col span={24} className="gutter-box">
                        <div>
                            <span className="hintText">Customer Address</span>                            
                        </div> 
                        <Input
                            type="text"
                            id="new_customer_address"
                            style={{ width: '100%', color: '#000000'}}
                            onChange={formik.handleChange}
                            value={formik.values.new_customer_address}
                        />                                
                    </Col>
                </Row>                
            </>
        )
    }

    const updateItemFields = (item_name, form, isReturn) => {
        form.setFieldValue("sales_item_name", item_name);
        const item = itemDataList.find(i => i.itemname === item_name);
        form.setFieldValue("item_id", item.itemid);
        form.setFieldValue("product", item.category_name);
        form.setFieldValue("sub_product", item.sub_category_name);
        form.setFieldValue("unit", item.unit);
        if(!isReturn) {
            form.setFieldValue("current_rate", item.current_rate);
        }        
        form.setFieldValue("igst", item.igst);
        form.setFieldValue("cgst", item.cgst);
        form.setFieldValue("sgst", item.sgst);
    }

    const addNewItem = (form, itemList, setItemList, isReturn) => {
        if(!isEdit && itemList.some(i => i.item_id === form.values.item_id)) {
            openErrorNotification('Same Item/Return alreay added, pls update if you need.');
            return;
        }
        const salesItem = {
            isSalesReturn: isReturn,
            sub_module: isReturn ? 'RETURN_ITEM' : 'ITEM',
            item_id: form.values.item_id,
            sales_item_name: form.values.sales_item_name,
            item_option: form.values.item_option,
            pcs: form.values.pcs || 0,
            weight: form.values.weight,
            current_rate: form.values.current_rate,
            amount: form.values.amount,
            stone: form.values.stone,
            stone_weight : form.values.stone_weight,
            gross_weight : form.values.gross_weight,
            waste: form.values.waste,
            waste_unit: form.values.waste_unit,
            mc_amount: form.values.mc_amount,
            huid: form.values.huid,
            igst: form.values.igst,
            cgst: form.values.cgst,
            sgst: form.values.sgst,
            tax_amount: form.values.tax_amount,
            other_details: form.values.other_details
        }

        if(salesItem.sales_item_name && salesItem.weight !== '' && (!isReturn || salesItem.current_rate)) {
            if(isEdit) {
                itemList = itemList.filter(i => i.item_id !== salesItem.item_id);
            }
            setItemList([...itemList, salesItem]);
            form.resetForm();
            form.setFieldValue('sales_item_name', '');
            form.setFieldValue('item_option', {});
            setModelType('');
        } else {
            openErrorNotification('Please Enter Required Item Fields.');
        }
    }

    const wasteTypes = (form) => (
        <Select value={form.values.waste_unit} style={{ width: 60 }} onChange={(e) => form.setFieldValue("waste_unit", e)}>
          <Option value="g">g</Option>
          <Option value="%">%</Option>
        </Select>
    );

    const salesItemForm = () => {
        return (
          <>
            <Row gutter={16} className="gutter-row">
              <Col span={13} className="gutter-box">
                <div>
                  <span className="hintText">Item Name</span>
                  <span className="mandatory"></span>
                </div>
                <Select
                  id="item_name"
                  placeholder="Select an Item"
                  style={{ width: "100%", height: "38px" }}
                  options={itemDataOptions}
                  value={salesItemFormik.values.sales_item_name}
                  defaultValue={salesItemFormik.values.item_option}
                  onChange={(e) => {
                    updateItemFields(e, salesItemFormik, false);
                  }}
                />
              </Col>
              <Col span={3} className="gutter-box">
                <div>
                  <span className="hintText">Unit</span>
                </div>
                <Input
                  type="text"
                  id="unit"
                  disabled={true}
                  style={{ width: "100%", color: "#000000" }}
                  onChange={salesItemFormik.handleChange}
                  value={salesItemFormik.values.unit}
                />
              </Col>
              <Col span={2} className="gutter-box">
                <div>
                  <span className="hintText">PCS</span>
                </div>
                <Input
                  type="text"
                  id="pcs"
                  style={{ width: "100%" }}
                  onChange={salesItemFormik.handleChange}
                  value={salesItemFormik.values.pcs}
                />
              </Col>
              <Col span={3} className="gutter-box">
                <div>
                  <span className="hintText">Gross Wt</span>
                </div>
                <Input
                  type="number"
                  step="0.001"
                  id="gross_weight"
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    const value = e.target.value;
                    salesItemFormik.setFieldValue("gross_weight", value);
                  }}
                  value={salesItemFormik.values.gross_weight}
                />
              </Col>
              <Col span={3} className="gutter-box">
                <div>
                  <span className="hintText">Stone Wt</span>
                </div>
                <Input
                  type="number"
                  step="0.001"
                  id="stone_weight"
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    const value = e.target.value;
                    salesItemFormik.setFieldValue("stone_weight", value);
                  }}
                  value={salesItemFormik.values.stone_weight}
                />
              </Col>
            </Row>
            <Row gutter={16} className="gutter-row">
              <Col span={10} className="gutter-box">
                <Space.Compact style={{ width: "100%" }}>
                  <div style={{ width: "50%" }}>
                    <div>
                      <span className="hintText">Net Weight</span>
                      <span className="mandatory"></span>
                    </div>
                    <Input
                      type="text"
                      id="weight"
                      style={{ width: "100%" }}
                      onChange={salesItemFormik.handleChange}
                      value={salesItemFormik.values.weight}
                    />
                  </div>
                  <div style={{ width: "50%" }}>
                    <div>
                      <span className="hintText">Current Market Rate</span>
                    </div>
                    <Input
                      type="text"
                      prefix="₹"
                      id="current_rate"
                      disabled={true}
                      style={{
                        width: "100%",
                        padding: "7px",
                        color: "#000000",
                      }}
                      value={salesItemFormik.values.current_rate}
                    />
                  </div>
                </Space.Compact>
              </Col>
              <Col span={4} className="gutter-box">
                <div>
                  <span className="hintText">CGST (%)</span>
                </div>
                <Input
                  type="text"
                  disabled={true}
                  id="cgst"
                  style={{ width: "100%", color: "#000000" }}
                  onChange={salesItemFormik.handleChange}
                  value={salesItemFormik.values.cgst}
                />
              </Col>
              <Col span={4} className="gutter-box">
                <div>
                  <span className="hintText">SGST (%)</span>
                </div>
                <Input
                  type="text"
                  disabled={true}
                  id="sgst"
                  style={{ width: "100%", color: "#000000" }}
                  onChange={salesItemFormik.handleChange}
                  value={salesItemFormik.values.sgst}
                />
              </Col>
              <Col span={6} className="gutter-box">
                <div>
                  <span className="hintText">Tax Amount</span>
                </div>
                <Input
                  type="text"
                  disabled={true}
                  id="tax_amount"
                  style={{ width: "100%", color: "#000000" }}
                  value={salesItemFormik.values.tax_amount}
                />
              </Col>
            </Row>
            <Row gutter={16} className="gutter-row">
              <Col span={5} className="gutter-box">
                <div>
                  <span className="hintText">Stone</span>
                </div>
                <Input
                  type="text"
                  id="stone"
                  style={{ width: "100%" }}
                  onChange={salesItemFormik.handleChange}
                  value={salesItemFormik.values.stone}
                />
              </Col>
              <Col span={5} className="gutter-box">
                <div>
                  <span className="hintText">Waste</span>
                </div>
                <Input
                  type="text"
                  addonAfter={wasteTypes(salesItemFormik)}
                  id="waste"
                  style={{ width: "100%" }}
                  onChange={salesItemFormik.handleChange}
                  value={salesItemFormik.values.waste}
                />
              </Col>
              <Col span={4} className="gutter-box">
                <div>
                  <span className="hintText">MC Amount</span>
                </div>
                <Input
                  type="text"
                  id="mc_amount"
                  style={{ width: "100%" }}
                  onChange={salesItemFormik.handleChange}
                  value={salesItemFormik.values.mc_amount}
                />
              </Col>
              <Col span={4} className="gutter-box">
                <div>
                  <span className="hintText">HUID</span>
                </div>
                <Input
                  type="text"
                  id="huid"
                  style={{ width: "100%" }}
                  onChange={salesItemFormik.handleChange}
                  value={salesItemFormik.values.huid}
                />
              </Col>
              <Col span={6} className="gutter-box">
                <div>
                  <span className="hintText">Other Details</span>
                </div>
                <Input
                  type="text"
                  id="other_details"
                  style={{ width: "100%" }}
                  onChange={salesItemFormik.handleChange}
                  value={salesItemFormik.values.other_details}
                />
              </Col>
            </Row>
            <Row gutter={16} className="gutter-row" align={"middle"}>
              <Col className="gutter-box">
                <div>
                  <span className="hintText">Amount</span>
                </div>
                <Input
                  type="text"
                  prefix="₹"
                  id="amount"
                  disabled={true}
                  style={{ width: "100%", padding: "7px", color: "#000000" }}
                  onChange={salesItemFormik.handleChange}
                  value={salesItemFormik.values.amount}
                />
              </Col>
            </Row>
          </>
        );
    }

    const salesItemReturnForm = () => {
        return (
          <>
            <Row gutter={16} className="gutter-row">
              <Col span={13} className="gutter-box">
                <div>
                  <span className="hintText">Item Name</span>
                  <span className="mandatory"></span>
                </div>
                <Select
                  id="item_name"
                  placeholder="Select a Name"
                  style={{ width: "100%", height: "38px" }}
                  options={itemDataOptions}
                  defaultValue={salesItemReturnFormik.values.item_option}
                  onChange={(e) => {
                    updateItemFields(e, salesItemReturnFormik, true);
                  }}
                />
              </Col>
              <Col span={3} className="gutter-box">
                <div>
                  <span className="hintText">Unit</span>
                </div>
                <Input
                  type="text"
                  id="unit"
                  disabled={true}
                  style={{ width: "100%", color: "#000000" }}
                  onChange={salesItemReturnFormik.handleChange}
                  value={salesItemReturnFormik.values.unit}
                />
              </Col>
              <Col span={2} className="gutter-box">
                <div>
                  <span className="hintText">PCS</span>
                </div>
                <Input
                  type="text"
                  id="pcs"
                  style={{ width: "100%" }}
                  onChange={salesItemReturnFormik.handleChange}
                  value={salesItemReturnFormik.values.pcs}
                />
              </Col>
              <Col span={3} className="gutter-box">
                <div>
                  <span className="hintText">Gross Wt</span>
                </div>
                <Input
                  type="number"
                  step="0.001"
                  id="gross_weight"
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    const value = e.target.value;
                    salesItemReturnFormik.setFieldValue("gross_weight", value);
                  }}
                  value={salesItemReturnFormik.values.gross_weight}
                />
              </Col>
              <Col span={3} className="gutter-box">
                <div>
                  <span className="hintText">Stone Wt</span>
                </div>
                <Input
                  type="number"
                  step="0.001"
                  id="stone_weight"
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    const value = e.target.value;
                    salesItemReturnFormik.setFieldValue("stone_weight", value);
                  }}
                  value={salesItemReturnFormik.values.stone_weight}
                />
              </Col>
            </Row>
            <Row gutter={16} className="gutter-row">
              <Col span={12} className="gutter-box">
                <Space.Compact style={{ width: "100%" }}>
                  <div style={{ width: "50%" }}>
                    <div>
                      <span className="hintText">Weight</span>
                      <span className="mandatory"></span>
                    </div>
                    <Input
                      type="text"
                      id="weight"
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        salesItemReturnFormik.setFieldValue(
                          "weight",
                          e.target.value
                        );
                        const amount =
                          parseFloat(e.target.value || 0) *
                          parseFloat(
                            salesItemReturnFormik.values.current_rate || 0
                          );
                        salesItemReturnFormik.setFieldValue(
                          "amount",
                          amount.toFixed(3)
                        );
                      }}
                      value={salesItemReturnFormik.values.weight}
                    />
                  </div>
                  <div style={{ width: "50%" }}>
                    <div>
                      <span className="hintText">Current Market Rate</span>
                      <span className="mandatory"></span>
                    </div>
                    <Input
                      type="text"
                      prefix="₹"
                      id="current_rate"
                      style={{
                        width: "100%",
                        padding: "7px",
                        color: "#000000",
                      }}
                      value={salesItemReturnFormik.values.current_rate}
                      onChange={salesItemReturnFormik.handleChange}
                    />
                  </div>
                </Space.Compact>
              </Col>
              <Col span={4} className="gutter-box">
                <div>
                  <span className="hintText">Stone</span>
                </div>
                <Input
                  type="text"
                  id="stone"
                  style={{ width: "100%" }}
                  onChange={salesItemReturnFormik.handleChange}
                  value={salesItemReturnFormik.values.stone}
                />
              </Col>
              <Col span={8} className="gutter-box">
                <div>
                  <span className="hintText">Waste</span>
                </div>
                <Input
                  type="text"
                  addonAfter={wasteTypes(salesItemReturnFormik)}
                  id="waste"
                  style={{ width: "100%" }}
                  onChange={salesItemReturnFormik.handleChange}
                  value={salesItemReturnFormik.values.waste}
                />
              </Col>
            </Row>
            <Row gutter={16} className="gutter-row">
              {/* <Col span={4} className="gutter-box">
                        <div>
                            <span className="hintText">Waste (%)</span>                            
                        </div> 
                        <Input
                            type="text"
                            id="touch"
                            style={{ width: '100%'}}
                            onChange={salesItemReturnFormik.handleChange}
                            value={salesItemReturnFormik.values.touch}
                        />                                
                    </Col> */}
              <Col span={5} className="gutter-box">
                <div>
                  <span className="hintText">MC Amount</span>
                </div>
                <Input
                  type="text"
                  id="mc_amount"
                  style={{ width: "100%" }}
                  onChange={salesItemReturnFormik.handleChange}
                  value={salesItemReturnFormik.values.mc_amount}
                />
              </Col>
              <Col span={6} className="gutter-box">
                <div>
                  <span className="hintText">HUID</span>
                </div>
                <Input
                  type="text"
                  id="huid"
                  style={{ width: "100%" }}
                  onChange={salesItemReturnFormik.handleChange}
                  value={salesItemReturnFormik.values.huid}
                />
              </Col>
              <Col span={13} className="gutter-box">
                <div>
                  <span className="hintText">Other Details</span>
                </div>
                <Input
                  type="text"
                  id="other_details"
                  style={{ width: "100%" }}
                  onChange={salesItemReturnFormik.handleChange}
                  value={salesItemReturnFormik.values.other_details}
                />
              </Col>
            </Row>
            <Row gutter={16} className="gutter-row">
              <Col className="gutter-box">
                <div>
                  <span className="hintText">Amount</span>
                </div>
                <Input
                  type="text"
                  prefix="₹"
                  id="amount"
                  disabled={true}
                  style={{ width: "100%", padding: "7px", color: "#000000" }}
                  onChange={salesItemReturnFormik.handleChange}
                  value={salesItemReturnFormik.values.amount}
                />
              </Col>
            </Row>
          </>
        );
    }

    const salesForm = () => {
        return (
            <>
                <Row gutter={16} justify={"end"} className="gutter-row">
                    <Col span={4} offset={14} className="gutter-box flex-je-ac">
                        <span className="hintText color-black">Items Amount</span>                       
                    </Col>
                    <Col span={6} className="gutter-box">
                        <InputNumber
                            prefix='₹'
                            precision={3}
                            disabled={true}
                            id="items_amount"
                            className='input-number-with-prefix'
                            onChange={formik.handleChange}
                            value={formik.values.items_amount}
                        /> 
                    </Col>
                </Row>
                <Row gutter={16} justify={"end"} className="gutter-row">
                    <Col span={4} offset={14} className="gutter-box flex-je-ac">
                        <span className="hintText color-black">Returns Amount</span>                      
                    </Col>
                    <Col span={6} className="gutter-box">
                        <InputNumber
                            prefix='₹'
                            precision={3}
                            disabled={true}
                            id="return_items_amount"
                            className='input-number-with-prefix'
                            onChange={formik.handleChange}
                            value={formik.values.return_items_amount}
                        /> 
                    </Col>
                </Row>
                <Row gutter={16} justify={"end"} className="gutter-row">
                    <Col span={6} className="gutter-box">
                        <div style={{textAlign: 'right'}}>
                            <span className="hintText color-black">Discount</span>                            
                        </div> 
                        <Input
                            prefix='₹'
                            id="discount"
                            className='input-number-with-prefix'
                            onChange={(e) => {
                                formik.setFieldValue('discount', e.target.value);
                            }}
                            value={formik.values.discount}
                        /> 
                    </Col>
                </Row>
                <Row gutter={16} justify={"end"} className="gutter-row">
                    <Col span={8} className="gutter-box">
                        <div>
                            <span className="hintText color-black" style={{fontWeight: '600'}}>PAYMENT MODE</span>                            
                        </div> 
                        <Radio.Group 
                            style={{marginTop: '5px', fontSize:' 16px'}}
                            onChange={(e) => {formik.setFieldValue('payment_mode_1', e.target.value)}} value={formik.values.payment_mode_1}>
                            {paymentOptions.map((option, index) => <Radio key={index} style={{fontSize:'13px', fontFamily:'poppins'}} value={option.mop}>{option.mop}</Radio>)}                
                        </Radio.Group>
                    </Col>
                    <Col span={8} className="gutter-box">
                        <div>
                            <span className="hintText color-black">Bank</span>                            
                        </div> 
                        <Select
                            id="account_1"
                            disabled={formik.values.payment_mode_1 === 'Cash'}
                            placeholder="Select an Account"
                            style={{ width: '75%', height: '38px' }}
                            options={accountList}
                            onChange={(e)=> {
                                formik.setFieldValue("account_1", e);
                            }}
                        />
                    </Col>
                    <Col span={6} className="gutter-box">
                        <div style={{textAlign: 'right'}}>
                            <span className="hintText color-black">Customer Payment 1</span>                            
                        </div> 
                        <Input
                            prefix='₹'
                            id="payment_1"
                            className='input-number-with-prefix'
                            onChange={(e) => {
                                formik.setFieldValue('payment_1', e.target.value);
                            }}
                            value={formik.values.payment_1}
                        /> 
                    </Col>
                </Row>
                {/* <Row gutter={16} justify={"end"} className="gutter-row">
                    <Col span={8} className="gutter-box">
                        <div>
                            <span className="hintText color-black" style={{fontWeight: '600'}}>PAYMENT MODE 2</span>                            
                        </div> 
                        <Radio.Group 
                            style={{marginTop: '5px', fontSize:' 16px'}}
                            onChange={(e) => {formik.setFieldValue('payment_mode_2', e.target.value)}} value={formik.values.payment_mode_2}>
                            {paymentOptions.map((option, index) => <Radio key={index} style={{fontSize:'13px', fontFamily:'poppins'}} value={option.mop}>{option.mop}</Radio>)}                
                        </Radio.Group>
                    </Col>
                    <Col span={8} className="gutter-box">
                        <div>
                            <span className="hintText color-black">Bank</span>                            
                        </div> 
                        <Select
                            id="account_1"
                            disabled={formik.values.payment_mode_2 === 'Cash'}
                            placeholder="Select an Account"
                            style={{ width: '75%', height: '38px' }}
                            options={accountList}
                            onChange={(e)=> {
                                formik.setFieldValue("account_2", e);
                            }}
                        />
                    </Col>
                    <Col span={6} className="gutter-box">
                        <div style={{textAlign: 'right'}}>
                            <span className="hintText color-black">Customer Payment 2</span>                            
                        </div> 
                        <Input
                            prefix='₹'
                            id="payment_2"
                            className='input-number-with-prefix'
                            onChange={(e) => {
                                formik.setFieldValue('payment_2', e.target.value);
                            }}
                            value={formik.values.payment_2}
                        /> 
                    </Col>
                </Row> */}
                <Row gutter={16} justify={"end"} className="gutter-row">
                    <Col span={8} className="gutter-box" style={{display: 'flex', flexDirection: 'column', alignItems: 'end', justifyContent: 'start'}}>
                        <Statistic 
                            prefix='₹'
                            title="Pending Invoice Amount" 
                            value={formik.values.pending_amount} 
                            precision={3}
                            style={{textAlign:'right', border: '1px dotted #d3d3d3', padding: '10px'}}
                            valueStyle={{ width: '275px', fontSize: '28px', textAlign:'right', color: '#000000', fontWeight: 'bolder'}} />
                    </Col>
                </Row>
            </>
        )
    }

    const salesContainer = () => {
        return (
            <>
                <Row gutter={16} className="gutter-row" justify="center" style={{margin: '0px 0px'}}>
                    <Col span={24} className="gutter-box" style={{textAlign: salesItemList.length === 0 ? 'center' : 'right'}}>
                        <span className="saleBillText" style={{marginRight: '15px', fontSize: '13px', display: salesItemList.length === 0 ? 'inline' : 'none'}}>
                            No sale item added so far, click here to add
                        </span>
                    </Col>                  
                </Row>
                <Row gutter={16} className="gutter-row" justify="center" style={{margin: '0px 0px'}}>
                    <Col span={24} className="gutter-box" style={{textAlign: salesItemList.length === 0 ? 'center' : 'right'}}>
                        <Button 
                            type='primary'
                            icon={<PlusOutlined />}
                            onClick={() => showModal('SALE')}
                            style={{ width: '50px', height: '36px', fontFamily: 'poppins', fontSize: '15px'}}>
                        </Button>
                    </Col>                  
                </Row>
                {salesItemTable(salesItemList)}
            </>
        )
    }

    const salesReturnContainer = () => {
        return (
            <>
                <Row gutter={16} className="gutter-row" justify="center" style={{margin: '0px 0px'}}>
                    <Col span={24} className="gutter-box" style={{textAlign: salesReturnItemList.length === 0 ? 'center' : 'right'}}>
                        <span className="saleBillText" style={{marginRight: '15px', fontSize: '13px', display: salesReturnItemList.length === 0 ? 'inline' : 'none'}}>
                            No sale return item added so far, click here to add
                        </span>
                    </Col>                  
                </Row>
                <Row gutter={16} className="gutter-row" justify="center" style={{margin: '0px 0px'}}>
                    <Col span={24} className="gutter-box" style={{textAlign: salesReturnItemList.length === 0 ? 'center' : 'right'}}>
                        <Button 
                            type='primary'
                            icon={<PlusOutlined />}
                            onClick={() => showModal('RETURN')}
                            style={{ width: '50px', height: '36px', fontFamily: 'poppins', fontSize: '15px'}}>
                        </Button>
                    </Col>                  
                </Row>
                {salesItemTable(salesReturnItemList)}
            </>
        )
    }

    const createNewFormSection = () => {
		return (
			<>
                {salesOrderForm()}
                <Divider orientation='left' orientationMargin='0'>Sales</Divider>
                {salesContainer()}
                <Divider orientation='left' orientationMargin='0'>Sales Return</Divider>
                {salesReturnContainer()}
				{/* <Collapse 
                    style={{marginTop: '30px'}} 
                    defaultActiveKey={['1']} 
                    items={[
                        {
                            key: '1',
                            label: 'SALES',
                            children: salesContainer(),
                        }
                    ]}>
                </Collapse>
				<Collapse 
                    style={{marginTop: '20px'}} 
                    defaultActiveKey={['1']} 
                    items={[
                        {
                            key: '1',
                            label: 'OLD GOLD RETURN',
                            children: salesReturnContainer(),
                        }
                    ]}>
                </Collapse> */}
                <Card style={{marginTop: '35px'}}>
                    {salesForm()}
                </Card>                
			</>
		);
	};

    const getCurrentRate = (type) => itemTypes.find(it => it.name.toLowerCase() === type.toLowerCase())?.current_rate;

    const salesAmountWOTax = () => parseFloat(formik.values.items_amount) - salesItemList.reduce((acc, next) => acc + parseFloat(next.tax_amount), 0);

    const totalOldGoldWeight = () => salesReturnItemList.reduce((acc, next) => parseFloat(acc) + parseFloat(next.weight), 0);  

    const getPageMargins = () => {
		return `@page { size: 80mm; margin: 10px !important; }`;
	};

    const EstimationReport = () => {    
        return (
            <>
                <div style={{display: 'none'}}>
                    <div style={{display: 'flex', justifyContent: 'center'}} className='estimation-container' ref={componentRef}>
                        <style>{getPageMargins()}</style>
                        <div style={{textAlign: 'center'}}>
                            <span style={{fontSize: '14px', fontWeight: '700'}}>{formik.values.quotationHeader}</span>
                            <div style={{fontSize: '14px', fontWeight: '500', paddingTop: '15px'}}>Quotation</div>
                            <hr style={{border: '1px dashed #d3d3d3'}}/>

                            <Row gutter={16} className="gutter-row">
                                <Col span={8} style={{display: 'flex', justifyContent: 'start'}}>
                                    <span className="saleBillText">{moment(formik.values.invoice_date).format('DD/MM/YYYY')}</span>                    
                                </Col>
                                <Col span={16} style={{display: 'flex', justifyContent: 'end'}}>
                                    <span className="saleBillText">G.Rate:&nbsp;</span> 
                                    <span className="saleBillText">{getCurrentRate('GOLD')}</span> 
                                </Col>
                            </Row>
                            <Row gutter={16} className="gutter-row" style={{paddingTop: '5px'}}>
                                <Col span={8} style={{display: 'flex', justifyContent: 'start'}}>
                                    <span className="saleBillText">QT No &nbsp;</span>
                                    <span className="saleBillText">{formik.values.invoice_no_inc}</span>                 
                                </Col>
                                <Col span={16} style={{display: 'flex', justifyContent: 'end'}}>
                                    <span className="saleBillText">S.Rate:&nbsp;</span> 
                                    <span className="saleBillText">{getCurrentRate('SILVER') || 'NA'}</span> 
                                </Col>
                            </Row>
                            
                            { salesItemList.map(si => (
                                <>
                                    <hr style={{border: '1px dashed #d3d3d3'}}/>
                                    <Row gutter={16} className="gutter-row" style={{paddingTop: '5px'}}>
                                        <Col span={24} style={{display: 'flex', justifyContent: 'start'}}>
                                            <span className="saleBillText" style={{fontSize: '16px', fontWeight: '600'}}>{si.sales_item_name}</span>                  
                                        </Col>
                                    </Row>
                                    <Row gutter={16} className="gutter-row" style={{paddingTop: '5px'}}>
                                        <Col span={24} style={{display: 'flex', justifyContent: 'start'}}>
                                            <span className="saleBillText">HM</span>                  
                                        </Col>
                                    </Row>
                                    <Row gutter={16} className="gutter-row" style={{paddingTop: '5px', paddingLeft: '10px'}}>
                                        <Col span={6} style={{display: 'flex', justifyContent: 'start'}}>
                                            <span className="saleBillText">Weight</span>                  
                                        </Col>
                                        <Col span={6} style={{display: 'flex', justifyContent: 'start'}}>
                                            <span className="saleBillText">{si.weight} Gm</span>                  
                                        </Col>
                                    </Row>
                                    <Row gutter={16} className="gutter-row" style={{paddingTop: '5px', paddingLeft: '10px'}}>
                                        <Col span={6} style={{display: 'flex', justifyContent: 'start'}}>
                                            <span className="saleBillText">Waste</span>                  
                                        </Col>
                                        <Col span={6} style={{display: 'flex', justifyContent: 'start'}}>
                                            <span className="saleBillText">{si.waste} Gm</span>                  
                                        </Col>
                                    </Row>
                                    <Row gutter={16} className="gutter-row" style={{paddingTop: '5px', paddingLeft: '10px'}}>
                                        <Col span={6} style={{display: 'flex', justifyContent: 'start'}}>
                                            <span className="saleBillText">MC @</span>                  
                                        </Col>
                                        <Col span={6} style={{display: 'flex', justifyContent: 'start'}}>
                                            <span className="saleBillText">{si.mc_amount}</span>                  
                                        </Col>
                                        <Col span={12} style={{display: 'flex', justifyContent: 'end'}}>
                                            <span className="saleBillText">{si.amount - si.tax_amount}</span>                  
                                        </Col>
                                    </Row>
                                </>
                            )) }
                            <hr style={{border: '1px dashed #d3d3d3'}}/>
                            <Row gutter={16} className="gutter-row" style={{paddingTop: '5px', paddingLeft: '20px'}}>
                                <Col span={12} style={{display: 'flex', justifyContent: 'start'}}>
                                    <span className="saleBillText">Gross Amount</span>                  
                                </Col>
                                <Col span={12} style={{display: 'flex', justifyContent: 'end'}}>
                                    <span className="saleBillText">{salesAmountWOTax()}</span>                  
                                </Col>
                            </Row>
                            <Row gutter={16} className="gutter-row" style={{paddingTop: '5px', paddingLeft: '20px'}}>
                                <Col span={8} style={{display: 'flex', justifyContent: 'start'}}>
                                    <span className="saleBillText">GST &nbsp;</span>
                                    <span className="saleBillText">{salesItemList.reduce((acc, next) => acc + next.igst, 0)} %</span>
                                </Col>
                                <Col span={16} style={{display: 'flex', justifyContent: 'end'}}>
                                    <span className="saleBillText">{salesItemList.reduce((acc, next) => acc + parseFloat(next.tax_amount), 0).toFixed(2)}</span>                  
                                </Col>
                            </Row>
                            
                            <Row gutter={16} className="gutter-row" style={{paddingTop: '5px'}}>
                                <Col offset={16} span={8} style={{display: 'flex', justifyContent: 'start'}}>
                                    <Divider dashed={true} style={{border: '1px dashed #d3d3d3'}}></Divider>
                                </Col>
                            </Row>
                            <Row gutter={16} className="gutter-row" style={{paddingTop: '5px', paddingLeft: '20px'}}>
                                <Col span={10} style={{display: 'flex', justifyContent: 'start'}}>
                                    <span className="saleBillText">Net Amount</span>
                                </Col>
                                <Col span={14} style={{display: 'flex', justifyContent: 'end'}}>
                                    <span className="saleBillText">{formik.values.items_amount}</span>                  
                                </Col>
                            </Row>
                            <Row gutter={16} className="gutter-row" style={{paddingTop: '5px', paddingLeft: '20px'}}>
                                <Col span={14} style={{display: 'flex', justifyContent: 'start'}}>
                                    <span className="saleBillText">Old Gold &nbsp;&nbsp;</span>
                                    <span className="saleBillText">{totalOldGoldWeight()} Gm</span>
                                </Col>
                                <Col span={10} style={{display: 'flex', justifyContent: 'end'}}>
                                    <span className="saleBillText">(-) {formik.values.return_items_amount}</span>                  
                                </Col>
                            </Row>
                            <Row gutter={16} className="gutter-row" style={{paddingTop: '5px'}}>
                                <Col offset={16} span={8} style={{display: 'flex', justifyContent: 'start'}}>
                                    <Divider dashed={true} style={{border: '1px dashed #d3d3d3'}}></Divider>
                                </Col>
                            </Row>
                            <Row gutter={16} className="gutter-row" style={{paddingTop: '5px', paddingLeft: '20px'}}>
                                <Col span={10} style={{display: 'flex', justifyContent: 'start'}}>
                                    <span className="saleBillText">Grand Total</span>
                                </Col>
                                <Col span={14} style={{display: 'flex', justifyContent: 'end'}}>
                                    <span className="saleBillText">{formik.values.total_amount}</span>                  
                                </Col>
                            </Row>
                            <Row gutter={16} className="gutter-row" style={{paddingTop: '5px', paddingLeft: '20px'}}>
                                <Col span={8} style={{display: 'flex', justifyContent: 'start'}}>
                                    <span className="saleBillText">EST-3</span>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </>
        );
    };

	return (
        <>
            <PageTitle
                imagesrc={SaleImage}
                pageTitle={'Create Estimation'}
                buttonList={[{
                    buttonName: 'Back',
                    className: 'btn-back',
                    url: '/slj/estimation'
                }]}
            />
            {contextHolder}
            <Spin tip="Fetching Quotation Details ..." spinning={loading} size="large"> 
                <div className="content-area">
                    <div className="formAreaStyle">
                        { createNewFormSection() }		
                    </div>
                </div>
            </Spin>
            {EstimationReport()}
            <div className="formSubmitArea">
                <div className="formSubmitInnerArea">
                    <Button
                        icon={<ClearOutlined />}
                        type="primary"
                        size="large"
                        style={{ width: '150px', backgroundColor: 'grey', borderRadius: '4px' }}
                        onClick={formik.handleReset}
                    >
                        Reset Values
                    </Button>
                    <Button
                        icon={<SaveOutlined />}
                        type="primary"
                        size="large"
                        style={{ width: '150px', backgroundColor: '#1f43e5', borderRadius: '4px' }}
                        onClick={formik.handleSubmit}
                    >
                        Submit
                    </Button>
                </div>
            </div>
            <Modal 
                title={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span>
                            {modelType === 'SALE' 
                                ? (isEdit ? 'Update Sale Details' : 'Enter Sale Details') 
                                : (isEdit ? 'Update Return Details' : 'Enter Return Details')
                            }
                        </span>
                        <Button
            icon={<ScanOutlined />}
            type="default"
            size="small"
            ghost={true} // Gives an outlined look
            style={{
              marginLeft: "10px",
              borderColor: "#1f43e5",
              color: "#1f43e5",
            }}
            onClick={""} // Define the handleScan function for scan action
          >
            Scan
          </Button>
                    </div>
                }
                closable={false}
                maskClosable={false}
                keyboard={false}
                open={modelType === 'SALE' || modelType === 'RETURN'}
                okText={modelType === 'SALE' 
                    ? (isEdit ? 'Update Sale Details' : 'Add Sale Details') 
                    : (isEdit ? 'Update Return Details' : 'Add Return Details')}
                onOk={() => handleOk(modelType === 'SALE')}
                width={'800px'}
                onCancel={handleCancel}
            >
                <div>
                    {modelType === 'SALE' ? salesItemForm() : salesItemReturnForm()}
                </div>
            </Modal>
            <Modal title='New Customer' 
                    closable={false} maskClosable={false} keyboard={false} 
                    open={modelType === 'NEW_CUSTOMER'} okText={'Create Customer'} 
                    onOk={() => {createCustomer()}} width={'500px'} onCancel={handleCancel}>
                <div>
                    {newCustomer()}
                </div>
            </Modal>
            <Modal title='ALERT' 
                    closable={false} maskClosable={false} keyboard={false} 
                    open={modelType === 'ALERT'} okText={'Ok'} 
                    width={'500px'}
                    onOk={() => {
                        setIsQuotSelEnabled(!isQuotSelEnabled);
                        clearForm();
                        setModelType('');
                    }} onCancel={() => {
                        setModelType('');
                    }}>
                <div>
                    <span>Below form data will be reset, if you click Ok.</span>
                </div>
            </Modal>
        </>
    );
}
    
    export default EstimationForm;