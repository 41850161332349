import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, DatePicker, Popconfirm, Row, Select, Space, Spin, Table, Tabs, Tag, notification } from 'antd';
import PageTitle from '../common/PageTitle';
import SalesImage from '../../assets/images/sales.png';
import columnSearch from '../common/columnSearch';
import httpCall from '../../utils/api';
import '../../css/container.css';
import '../../css/SmallComponents.css';
import '../../css/Vendor.css';
import { useFormik } from 'formik';
import moment from 'moment';
import { ArrowDownOutlined, ArrowUpOutlined, CloseOutlined, FolderOpenOutlined, OpenAIOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;

const StockReport = () => {
	const navigate = useNavigate();
	const [stockReportData, setStockReportData] = useState([]);
	const [api, contextHolder] = notification.useNotification();
	const [loading, setLoading] = useState(false);

	const formik = useFormik({
		initialValues: {
			from_date: moment().format('YYYY-MM-DD'),
			to_date: moment().format('YYYY-MM-DD')
		},
		onSubmit: (values, {resetForm}) => {

		},
	});

	useEffect(() => {
		fetchStockReport(formik.values.from_date, formik.values.to_date);		
	}, []);

	const getValue = (list, key, id) => {
		const obj = list.filter(o => o.itemid === id);
		if(obj.length > 0) {
			return obj[0][key];
		}
		return 0;
	}

	const fetchStockReport = (from, to) => {
		httpCall({
			method: 'GET',
			url: `/api/stock/report?from=${from}&to=${to}`,
		})
			.then((response) => {
				setLoading(false);			
				const stocks = response?.data.stocks;
				const inwards = response?.data.inwards;
				const outwards = response?.data.outwards;
				const stockReport = stocks.map(stock => {
					const outwd_qty = getValue(outwards, 'outwd_qty', stock.item_id);
					const outwd_wgt = getValue(outwards, 'outwd_wgt', stock.item_id);
					const inwd_qty = getValue(inwards, 'inwd_qty', stock.item_id);
					const inwd_wgt = getValue(inwards, 'inwd_wgt', stock.item_id);
					
					return {
							itemid: stock.item_id, 
							itemname: stock.itemname,
							opg_qty: parseFloat(stock.pcs) + parseFloat(outwd_qty) - parseFloat(inwd_qty),
							opg_wgt: parseFloat(stock.weight) + parseFloat(outwd_wgt) - parseFloat(inwd_wgt),
							inwd_qty: inwd_qty,
							inwd_wgt: inwd_wgt,
							outwd_qty: outwd_qty,
							outwd_wgt: outwd_wgt,
							cl_qty: stock.pcs,
							cl_wgt: stock.weight,
						}
				})

				setStockReportData(stockReport);
			})
			.catch((error) => {
				setLoading(false);
				console.error('Error retrieving sales order data:', error);
			});
	};

	const stockReportColumns = [
		{
			title: 'Item Name',
			dataIndex: 'itemname',
			key: 'itemname',
			align: 'center',
			width: 100,
			sorter: (a, b) => a.itemname.localeCompare(b.itemname),
			defaultSortOrder: 'ascend', // Default sorting in ascending order
		},
		{			
			title: <>Opening Stock <FolderOpenOutlined /></>,
			align: 'center',
			children: [
				{
					title: 'Quantity',
					dataIndex: 'opg_qty',
					key: 'opg_qty',
					align: 'center',
					width: 30,
					sorter: (a, b) => {
					  return a.opg_qty - b.opg_qty; // Numeric comparison
					},
					defaultSortOrder: 'ascend', // Default sorting in ascending order
				  },
				{
					title: 'Weight (g)',
					dataIndex: 'opg_wgt',
					key: 'opg_wgt',
					align: 'center',
					width: 30,
					sorter: (a, b) => {
						return a.opg_wgt - b.opg_wgt; // Numeric comparison
					  },
					  defaultSortOrder: 'ascend', // Default sorting in ascending order
					},
				{
					title: 'Less',
					dataIndex: 'opg_less',
					key: 'opg_less',
					align: 'center',
					width: 30
				}
			]
		},
		{
			title: <>Inward Stock <ArrowDownOutlined /></>,
			align: 'center',
			children: [
				{
					title: 'Quantity',
					dataIndex: 'inwd_qty',
					key: 'inwd_qty',
					align: 'center',
					width: 30,
					sorter: (a, b) => {
						return a.inwd_qty - b.inwd_qty; // Numeric comparison
					  },
					  defaultSortOrder: 'ascend', // Default sorting in ascending order
					},
					
				{
					title: 'Weight (g)',
					dataIndex: 'inwd_wgt',
					key: 'inwd_wgt',
					align: 'center',
					width: 30,
					sorter: (a, b) => {
						return a.inwd_wgt - b.inwd_wgt; // Numeric comparison
					  },
					  defaultSortOrder: 'ascend', // Default sorting in ascending order
					},
				{
					title: 'Less',
					dataIndex: 'inwd_less',
					key: 'inwd_less',
					align: 'center',
					width: 30
				}
			]
		},
		{
			title: <>Outward Stock <ArrowUpOutlined /></>,
			align: 'center',
			children: [
				{
					title: 'Quantity',
					dataIndex: 'outwd_qty',
					key: 'outwd_qty',
					align: 'center',
					width: 30,
					sorter: (a, b) => {
						return a.outwd_qty - b.outwd_qty; // Numeric comparison
					  },
					  defaultSortOrder: 'ascend', // Default sorting in ascending order
					},
				{
					title: 'Weight (g)',
					dataIndex: 'outwd_wgt',
					key: 'outwd_wgt',
					align: 'center',
					width: 30,
					sorter: (a, b) => {
						return a.outwd_wgt - b.outwd_wgt; // Numeric comparison
					  },
					  defaultSortOrder: 'ascend', // Default sorting in ascending order
					},
				{
					title: 'Less',
					dataIndex: 'outwd_less',
					key: 'outwd_less',
					align: 'center',
					width: 30
				}
			]
		},
		{
			title: <>Closing Stock <CloseOutlined /></>,
			align: 'center',
			children: [
				{
					title: 'Quantity',
					dataIndex: 'cl_qty',
					key: 'cl_qty',
					align: 'center',
					width: 30,
					sorter: (a, b) => {
						return a.cl_qty - b.cl_qty; // Numeric comparison
					  },
					  defaultSortOrder: 'ascend', // Default sorting in ascending order
					},
				{
					title: 'Weight (g)',
					dataIndex: 'cl_wgt',
					key: 'cl_wgt',
					align: 'center',
					width: 30,
					sorter: (a, b) => {
						return a.cl_wgt - b.cl_wgt; // Numeric comparison
					  },
					  defaultSortOrder: 'ascend', // Default sorting in ascending order
				},
				{
					title: 'Less',
					dataIndex: 'cl_less',
					key: 'cl_less',
					align: 'center',
					width: 30
				}
			]
		},
		
	];

	const footerLabel = (list, label, field, unit) => {
		return (
			<>
				<div style={{width: '100%', display: 'flex', justifyContent: 'end'}}>
					<div style={{width: '20%', textAlign: 'center', backgroundColor: '#FFFFFF'}}>
						<span>{label}: </span>
						<span style={{fontSize: '18px', fontWeight: '600'}}>{list.reduce((acc, next) => next[field] + acc, 0)} {unit}</span>
					</div>
				</div>
			</>
		)
	}

	return (
		<>
			<PageTitle
				imagesrc={SalesImage}
				pageTitle="Daily Stock Statement"
				buttonList={[]}
			/>
			{contextHolder}
			{<div className='vendorListArea'>
				{/* <Row gutter={16} className="gutter-row" style={{marginBottom: '30px'}}>
					<Col span={12} className="gutter-box">
						<div>
                            <span className="hintText color-black">Select Date Range</span>
                        </div> 
						<RangePicker style={{height: '38px'}} onChange={(e) => {								
							if(!!e) {
								const start = e[0].format('YYYY-MM-DD');
								const end = e[1].format('YYYY-MM-DD');
								fetchStockReport(start, end)
							} else {
								
							}
						}}/>
					</Col>
				</Row> */}
				<Spin tip="Fetching Data From Server, Please Wait ..." spinning={loading} size="large">
						<Row gutter={16} className="gutter-row" style={{marginTop: '5px', marginBottom: '50px'}}>
                            <Col span={24} className="gutter-box">
								<span style={{fontSize: '15px', fontWeight: '600'}}>Stock Statement</span>
								<Table 
									dataSource={stockReportData}
									size='small'
									bordered={true}
									columns={stockReportColumns}
									rowKey="invoice_no"
									scroll={{y: '250px'}}
									pagination={false}
									// footer={() => footerLabel(stockReportData.filter(r => r.item_type.toLowerCase() === 'gold'), 'Total Weight', 'weight', 'g') }
									style={{fontFamily: 'Poppins', marginTop: '10px'}}/>
                            </Col>
                        </Row>
				</Spin>
			</div>}
		</>
	);
};

export default StockReport;
