import CostCenterList from './costcenter/CostCenterList';
import CostCenterForm from './costcenter/costCenterCreate';
import ItemMenu from './Item/ItemMenu';
import Login from './Login';
import MainLayout from './MainLayout';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import CategoryList from './Item/ItemCategory/CategoryList';
import SubCategoryList from './Item/ItemCategory/SubCategoryList';
import ItemDataList from './Item/ItemData/ItemDataList';
import ItemDataForm from './Item/ItemData/ItemDataCreate';
import VendorList from './Vendor/VendorList';
import VendorForm from './Vendor/VendorCreate';
import CustomerList from './Customer/CustomerList';
import CustomerForm from './Customer/CustomerCreate';
import ItemTypeList from './Item/ItemType/ItemTypeList';
import SaleList from './Sales/SaleList';
import QuotationList from './Sales/QuotationList';
import QuotationForm from './Sales/QuotationCreate';
import EstimatinList from './Sales/EstimationList';
import EstimationForm from './Sales/EstimationCreate';
import SalesForm from './Sales/SaleCreate';
import {default as SalesFormOld} from './Sales/__SaleCreate';
import PurchaseList from './purchase/PurchaseList';
import PurchaseForm from './purchase/PurchaseCreate';
import PurchasePayment from './purchase/PurchasePayment';
import SalesReceipt from './Sales/SalesReceipt';
import PageUnderConstruction from './common/PageUnderConstruction';
import UOMList from './uom/uomList';
import SalesReceiptList from './Sales/SalesReceipt';
import SalesReceiptCreate from './Sales/SalesReceiptCreate';
import PurchaseReceiptList from './purchase/PurchaseReceiptList';
import Payment from './settings/Payment';
import SaleReport from './Sales/SaleReport';
import PurchaseReport from './purchase/PurchaseReport';
import Accounts from './settings/Accounts';
import SettingsMenu from './settings/SettingsMenu';
import PlantList from './Store/PlantList';
import PlantForm from './Store/PlantCreate';
import StockList from './Stock/StockList';
import MCPayment from './purchase/MCPayment';
import MCReceiptList from './purchase/MCReceiptList';
import MaterialReturnList from './Sales/MaterialReturnList';
import MaterialReturnForm from './Sales/MaterialReturnCreate';
import DayBookReport from './Sales/DayBookReport';
import StockReport from './Sales/StockReport'
import Role from './Role/Role'
import QrGeneratorForm from './purchase/QrGenerator';

const Home = () => {
	const appRouterProvider = createBrowserRouter([
		{
			path: '/',
			element: <Login />,
		},
		{
			path: '/slj',
			element: <MainLayout />,
			children: [
				{ 
					path: '*',
					element: <PageUnderConstruction />
				},
				{ 
					path: 'cost-center',
					element: <CostCenterList /> 
				},
				{ 
					path: 'cost-center/form',
					element: <CostCenterForm /> 
				},
				{ 
					path: 'items',
					element: <ItemMenu /> 
				},
				{ 
					path: 'category',
					element: <CategoryList /> 
				},
				{ 
					path: 'subcategory',
					element: <SubCategoryList /> 
				},
				{ 
					path: 'item-data',
					element: <ItemDataList /> 
				},
				{ 
					path: 'item-type',
					element: <ItemTypeList /> 
				},
				{ 
					path: 'item-data/form',
					element: <ItemDataForm /> 
				},
				{ 
					path: 'vendor',
					element: <VendorList /> 
				},
				{ 
					path: 'vendor/form',
					element: <VendorForm /> 
				},
				{ 
					path: 'customer',
					element: <CustomerList /> 
				},
				{ 
					path: 'customer/form',
					element: <CustomerForm /> 
				},
				{ 
					path: 'quotation',
					element: <QuotationList /> 
				},
				{ 
					path: 'quotation/form',
					element: <QuotationForm /> 
				},
				{ 
					path: 'material-return',
					element: <MaterialReturnList /> 
				},
				{ 
					path: 'material-return/form',
					element: <MaterialReturnForm /> 
				},
				{ 
					path: 'estimation',
					element: <EstimatinList /> 
				},
				{ 
					path: 'estimation/form',
					element: <EstimationForm /> 
				},
				{ 
					path: 'sales',
					element: <SaleList /> 
				},
				{ 
					path: 'sales/form',
					element: <SalesForm /> 
				},
				{ 
					path: 'sales_old/form',
					element: <SalesFormOld /> 
				},
				{ 
					path: 'purchase',
					element: <PurchaseList /> 
				},
				{ 
					path: 'purchase/form',
					element: <PurchaseForm /> 
				},
				{ 
					path: 'purchase-receipt',
					element: <PurchaseReceiptList /> 
				},
				{ 
					path: 'purchase-receipt/form',
					element: <PurchasePayment /> 
				},
				{ 
					path: 'mc-receipt',
					element: <MCReceiptList /> 
				},
				{ 
					path: 'mc-receipt/form',
					element: <MCPayment /> 
				},
				{ 
					path: 'sales-receipt',
					element: <SalesReceiptList /> 
				},
				{ 
					path: 'sales-receipt/form',
					element: <SalesReceiptCreate /> 
				},
				{ 
					path: 'uom',
					element: <UOMList/> 
				},
				{ 
					path: 'settings',
					element: <SettingsMenu/> 
				},
				{ 
					path: 'payment',
					element: <Payment/> 
				},
				{ 
					path: 'accounts',
					element: <Accounts/> 
				},
				{ 
					path: 'sales-report',
					element: <SaleReport /> 
				},
				{ 
					path: 'purchase-report',
					element: <PurchaseReport/> 
				},
				{ 
					path: 'day-book-report',
					element: <DayBookReport /> 
				},
				{ 
					path: 'stock-report',
					element: <StockReport/>
				},
				{ 
					path: 'plant',
					element: <PlantList /> 
				},
				{ 
					path: 'role',
					element: <Role /> 
				},
				{ 
					path: 'plant/form',
					element: <PlantForm /> 
				},
				{ 
					path: 'stock',
					element: <StockList /> 
				},
				{ 
					path: 'qr/form',
					element: <QrGeneratorForm /> 
				},
			],
		},
	]);

	return (
		<div>
			<RouterProvider router={appRouterProvider} />
		</div>
	);
};

export default Home;
