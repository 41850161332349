import React, { useEffect, useState } from "react";
import {Button,Modal,Card,Col,Collapse,DatePicker,Divider,Flex,Input,InputNumber,Row,Select, Space, Statistic, Table,notification,} from "antd";
import {CheckCircleFilled,EditFilled,DeleteFilled,PlusOutlined,ProfileOutlined,ProfileTwoTone,} from "@ant-design/icons";
import PurchaseImage from '../../assets/images/material-return.svg';
import "../../css/container.css";
import "../../css/SmallComponents.css";
import httpCall from "../../utils/api";
import { useFormik } from "formik";
import PageTitle from "../common/PageTitle";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

const { Option } = Select;

const PurchaseForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [costCenterList, setCostCenterList] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [itemDataList, setItemDataList] = useState([]);
  const [itemDataOptions, setItemDataOptions] = useState([]);
  const [custMobileOptions, setCustMobileOptions] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [purchaseItemList, setPurchaseItemList] = useState([]);
  const [plants, setPlants] = useState([]);
  const [modelType, setModelType] = useState("");
  const [editingItemIndex, setEditingItemIndex] = useState(null);
  const [vendorMobileNumber, setVendorMobileNumber] = useState(null);
  const [selectedPlant, setSelectedPlant] = useState(null);
  const [selectedSalesPerson, setSelectedSalesPerson] = useState(null);

  const formik = useFormik({
    initialValues: {
      purchase_order_no: "",
      vendor_id: "",
      sales_person_id: "",
      purchase_order_date: moment(),
      opg_pure: "",
      opg_cash: "",
      reference_no: "",
      bar_rate: "",
      thru: "",
      total_weight: "",
      plant_code: "",
      total_mc_amount: "",
      pending_mc_amount: "",
    },
    onSubmit: (values, { resetForm }) => {
      if (location?.state?.id) {
        updateSaleData(values);
      } else {
        let errorMsg = "";
        if (values.vendor_id === "") {
          errorMsg = "Please select a vendor by mobile";
        } else if (values.purchase_order_no === "") {
          errorMsg = "Please give purchase order number";
        } else if (values.plant_code === "") {
          errorMsg = "Please select a plant";
        } else if (values.sales_person_id === "") {
          errorMsg = "Please select a sales person";
        } else if (purchaseItemList.length === 0) {
          errorMsg = "Please add a purchase item";
        }
        if (errorMsg === "") {
          createPurchaseData(values, resetForm);
        } else {
          openErrorNotification(errorMsg);
        }
      }
    },
  });

  const purchaseItemFormik = useFormik({
    initialValues: {
      item_id: "",
      item_name: "",
      item_option: {},
      pcs: "",
      weight: "",
      igst: "",
      cgst: "",
      sgst: "",
      tax_amount: "",
      stone: "",
      waste: "",
      waste_unit: "g",
      huid: "",
      touch: "",
      touch_unit: "%",
      mc_amount: "",
      other_details: "",
      module: "PURCHASE", // Default module
      sub_module: "ITEM", // Default sub_module
    },
  });

  const openErrorNotification = (description) => {
    api.error({
      message: "Error !",
      duration: 4,
      description: description,
    });
  };

  useEffect(() => {
    fetchCostCenter();
    fetchPlantList();
    fetchCustomerList();
    fetchItemDataList();
    if (location?.state?.id) {
      httpCall({
        method: "GET",
        url: "/api/purchase/get?id=" + location?.state?.id,
      })
        .then((response) => {
          formik.setValues(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, []);

  const calculateTotalWeight = () => {
    const totalWeight = purchaseItemList.reduce(
      (acc, next) => acc + parseFloat(next.weight || 0),
      0
    );
    formik.setFieldValue("total_weight", totalWeight.toFixed(3));
  };

  const calculateTotalMCAmount = () => {
    const totalMCAmount = purchaseItemList.reduce(
      (acc, next) => acc + parseFloat(next.mc_amount),
      0
    );

    formik.setFieldValue("total_mc_amount", totalMCAmount.toFixed(3));
    formik.setFieldValue("pending_mc_amount", totalMCAmount.toFixed(3));
  };

  useEffect(() => {
    const { weight, current_rate } = purchaseItemFormik.values;
    if (weight && current_rate) {
      calculateTaxAmount(purchaseItemFormik);
    }
  }, [purchaseItemFormik.values.weight, purchaseItemFormik.values.current_rate]);


  useEffect(() => {
    if (
      !!purchaseItemFormik.values.weight &&
      !!purchaseItemFormik.values.touch
    ) {
      const weight = parseFloat(purchaseItemFormik.values.weight || 0);
      let touch = parseFloat(purchaseItemFormik.values.touch || 0);
      const touch_unit = purchaseItemFormik.values.touch_unit;
      const pure = touch_unit === "%" ? (weight * touch) / 100 : weight * touch;
      purchaseItemFormik.setFieldValue("pure", pure.toFixed(3));
    }
  }, [
    purchaseItemFormik.values.weight,
    purchaseItemFormik.values.touch,
    purchaseItemFormik.values.touch_unit,
  ]);

  const fetchCostCenter = () => {
    httpCall({
      method: "GET",
      url: "/api/costcenter/get",
    })
      .then((response) => {
        if (response) {
          setCostCenterList(
            response?.data.map((c) => ({
              label: c.cost_center_name,
              value: c.cost_center_id,
            }))
          );
        } else {
          console.error("Error retrieving categories data:", response.message);
        }
      })
      .catch((error) => {
        console.error("Error retrieving categories data:", error);
      });
  };

  const fetchPlantList = () => {
    httpCall({
      method: "GET",
      url: "/api/plant/list",
    })
      .then((response) => {
        setPlants(
          response?.data.map((p) => ({
            label: p.plant_name,
            value: p.plant_code,
          }))
        );
      })
      .catch((error) => {});
  };

  const fetchCustomerList = () => {
    httpCall({
      method: "GET",
      url: "/api/vendor/get",
    })
      .then((response) => {
        if (response) {
          setVendorList(response?.data);
          setCustMobileOptions(
            response?.data.map((c) => ({
              label: c.mobile_number,
              value: c.mobile_number,
            }))
          );
        } else {
          console.error("Error retrieving customer list:", response.message);
        }
      })
      .catch((error) => {
        console.error("Error retrieving customer list:", error);
      });
  };

  const fetchItemDataList = () => {
    httpCall({
      method: "GET",
      url: "/api/item/sale/list",
    })
      .then((response) => {
        if (response) {
          setItemDataList(response?.data);
          setItemDataOptions(
            response?.data.map((i) => ({
              label: i.itemname,
              value: i.itemname,
            }))
          );
        } else {
          console.error("Error retrieving item data list:", response.message);
        }
      })
      .catch((error) => {
        console.error("Error retrieving item data list:", error);
      });
  };

  const openNotification = (description) => {
    api.open({
      message: "Success !",
      duration: 2,
      description: description,
      icon: <CheckCircleFilled style={{ color: "#808000" }} />,
    });
  };

  const createPurchaseData = (values, resetForm) => {
    const purchase_order_date = moment(values.purchase_order_date).format(
      "YYYY-MM-DD"
    );
  
    const payload = {
      ...values,
      purchase_order_date,
      purchase_items: purchaseItemList.map((item) => ({
        ...item,
        module: "PURCHASE",
        sub_module: "ITEM",
        transaction_ref_no: values.purchase_order_no,
        igst: parseFloat(item.igst || 0),
        cgst: parseFloat(item.cgst || 0),
        sgst: parseFloat(item.sgst || 0),
        tax_amount: item.tax_amount,
        mc_amount: parseFloat(item.mc_amount || 0),
        current_rate: parseFloat(item.current_rate || 0),
        weight: parseFloat(item.weight || 0),
        pcs: parseInt(item.pcs || 0),
        stone: item.stone || "",
        waste: item.waste || "",
        waste_unit: item.waste_unit || "",
        other_details: item.other_details || "",
        touch: parseFloat(item.touch || 0),
        touch_unit: item.touch_unit || "",
        huid: item.huid || "",
      })),
      bar_rate: parseFloat(values.bar_rate || 0),
      total_weight: parseFloat(values.total_weight || 0),
      total_mc_amount: parseFloat(values.total_mc_amount || 0),
      pending_mc_amount: parseFloat(values.pending_mc_amount || 0),
    };
  
    console.log("Payload being sent:", JSON.stringify(payload, null, 2));

    httpCall({
      method: "POST",
      url: "/api/purchase/purchaseOrder",
      payload: payload,
    })
      .then((response) => {
        console.log("Response from server:", response);
        if (response) {
          openNotification("Purchase Order Created Successfully");
          resetForm();
          setPurchaseItemList([]);
          formik.resetForm();
          purchaseItemFormik.resetForm();
          setVendorMobileNumber(null);
          setSelectedPlant(null);
          setSelectedSalesPerson(null);
        }
      })
      .catch((error) => {
        console.error("Error from server:", error);
        openErrorNotification("Purchase Order Number already exists. Please use a different number");
      });
  };

  const updateSaleData = (values) => {
    httpCall({
      method: "PUT",
      url: "/api/purchase/edit",
      payload: { ...values, itemid: location?.state?.id },
    })
      .then((response) => {
        if (response.data.success) {
          openNotification(
            "Purchase Details Updated Successfully, Goto list view. "
          );
          setTimeout(() => navigate("/slj/purchase"), 2500);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const showModal = (type, index = null) => {
    setModelType(type);
    setEditingItemIndex(index);
    if (index !== null) {
      const itemToEdit = purchaseItemList[index];
      purchaseItemFormik.setValues(itemToEdit);
    }
  };

  const handleCancel = () => {
    setModelType("");
    setEditingItemIndex(null);
    purchaseItemFormik.resetForm();
  };

  const handleOk = () => {
    const newPurchaseItem = {
      item_id: purchaseItemFormik.values.item_id,
      sales_item_name: purchaseItemFormik.values.sales_item_name,
      unit: purchaseItemFormik.values.unit,
      pcs: purchaseItemFormik.values.pcs,
      weight: purchaseItemFormik.values.weight,
      current_rate: purchaseItemFormik.values.current_rate,
      mc_amount: purchaseItemFormik.values.mc_amount,
      huid: purchaseItemFormik.values.huid,
      amount: purchaseItemFormik.values.amount,
      // Add these fields:
      stone: purchaseItemFormik.values.stone,
      waste: purchaseItemFormik.values.waste,
      waste_unit: purchaseItemFormik.values.waste_unit,
      igst: purchaseItemFormik.values.igst,
      sgst: purchaseItemFormik.values.sgst,
      cgst: purchaseItemFormik.values.cgst,
      tax_amount: purchaseItemFormik.values.tax_amount,
      other_details: purchaseItemFormik.values.other_details,
      touch: purchaseItemFormik.values.touch,
      touch_unit: purchaseItemFormik.values.touch_unit,
    };
  

    if (newPurchaseItem.sales_item_name && newPurchaseItem.weight) {
      if (editingItemIndex !== null) {
        // Update existing item
        updatePurchaseItemList(newPurchaseItem, editingItemIndex);
      } else {
        // Add new item
        addPurchaseItem(newPurchaseItem);
      }
      setModelType("");
      setEditingItemIndex(null);
      purchaseItemFormik.resetForm();
    } else {
      openErrorNotification("Please enter required item fields.");
    }
  };

  const updatePurchaseItemList = (updatedItem, index) => {
    setPurchaseItemList((prevList) => {
      const newList = [...prevList];
      newList[index] = updatedItem;
      return newList;
    });
  };

  const addPurchaseItem = (newItem) => {
    setPurchaseItemList((prevList) => [...prevList, newItem]);
  };

  useEffect(() => {
    calculateTotalWeight();
    calculateTotalMCAmount();
  }, [purchaseItemList]);

  const purchaseItemColumns = [
    {
      title: "Item Name",
      dataIndex: "sales_item_name",
      key: "sales_item_name",
      align: "center",
      width: 250,
    },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
      align: "center",
      width: 100,
    },
    {
      title: "PCS",
      dataIndex: "pcs",
      key: "pcs",
      align: "center",
      width: 70,
    },
    {
      title: "Weight",
      dataIndex: "weight",
      key: "weight",
      align: "center",
      width: 70,
    },
    {
      title: "Current Rate",
      dataIndex: "current_rate",
      key: "current_rate",
      align: "center",
      width: 100,
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      width: 150,
      render: (_, record, index) => (
        <Space size="middle">
          <EditFilled
            onClick={() => showModal("PURCHASE", index)}
            style={{ cursor: "pointer", fontSize: "20px", color: "#08c" }}
          />
          <DeleteFilled
            onClick={() => {
              setPurchaseItemList((prevList) =>
                prevList.filter((_, i) => i !== index)
              );
            }}
            style={{ cursor: "pointer", fontSize: "20px", color: "#B31312" }}
          />
        </Space>
      ),
    },
  ];

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const purchaseItemTable = (datasoure) => {
    return datasoure.length > 0 ? (
      <>
        <Row gutter={16} className="gutter-row sales-list">
          <Col span={24} className="gutter-box">
            <Table
              dataSource={datasoure}
              columns={purchaseItemColumns}
              pagination={{ position: ["bottomCenter"], pageSize: 2 }}
              rowKey="item_name"
              size="small"
              bordered="true"
              scroll={{ y: 100 }}
              style={{ fontFamily: "Poppins" }}
            />
          </Col>
        </Row>
      </>
    ) : null;
  };
  const updateItemFields = (item_name, form, isReturn) => {
    form.setFieldValue("sales_item_name", item_name);
    const item = itemDataList.find((i) => i.itemname === item_name);
    if (item) {
      form.setFieldValue("item_id", item.itemid);
      form.setFieldValue("product", item.category_name);
      form.setFieldValue("sub_product", item.sub_category_name);
      form.setFieldValue("unit", item.unit);
      if (!isReturn) {
        form.setFieldValue("current_rate", item.current_rate);
      }
      form.setFieldValue("igst", item.igst);
      form.setFieldValue("cgst", item.cgst);
      form.setFieldValue("sgst", item.sgst);
      
      form.setFieldValue("stone", item.stone || "");
      form.setFieldValue("waste", item.waste || "");
      form.setFieldValue("waste_unit", item.waste_unit || "g");
      
      calculateTaxAmount(form);
    }
  };

  const calculateTaxAmount = (form) => {
    const weight = parseFloat(form.values.weight) || 0;
    const currentRate = parseFloat(form.values.current_rate) || 0;
    const cgst = parseFloat(form.values.cgst) || 0;
    const sgst = parseFloat(form.values.sgst) || 0;
    
    const totalTaxPercentage = cgst + sgst;
    const itemValue = weight * currentRate;
    const taxAmount = (itemValue * totalTaxPercentage) / 100;
    
    form.setFieldValue("tax_amount", taxAmount.toFixed(2));
  };


  const wasteTypes = (form) => (
    <Select
      value={form.values.waste_unit}
      style={{ width: 60 }}
      onChange={(e) => form.setFieldValue("waste_unit", e)}
    >
      <Option value="g">g</Option>
      <Option value="%">%</Option>
    </Select>
  );

  const purchaseContainer = () => {
    return (
      <>
        <Row
          gutter={16}
          className="gutter-row"
          justify="center"
          style={{ margin: "0px 0px" }}
        >
          <Col
            span={24}
            className="gutter-box"
            style={{
              textAlign: purchaseItemList.length === 0 ? "center" : "right",
            }}
          >
            <span
              className="saleBillText"
              style={{
                marginRight: "15px",
                fontSize: "13px",
                display: purchaseItemList.length === 0 ? "inline" : "none",
              }}
            >
              No sale item added so far, click here to add
            </span>
          </Col>
        </Row>
        <Row
          gutter={16}
          className="gutter-row"
          justify="center"
          style={{ margin: "0px 0px" }}
        >
          <Col
            span={24}
            className="gutter-box"
            style={{
              textAlign: purchaseItemList.length === 0 ? "center" : "right",
            }}
          >
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => showModal("PURCHASE")}
              style={{
                width: "50px",
                height: "36px",
                fontFamily: "poppins",
                fontSize: "15px",
              }}
            ></Button>
          </Col>
        </Row>
        {purchaseItemTable(purchaseItemList)}
      </>
    );
  };

  const purchaseOrderForm = () => {
    return (
      <>
        <Row gutter={16} className="gutter-row">
          <Col span={10} className="gutter-box">
            <div>
              <span className="hintText">Vendor Mobile Number</span>
              <span className="mandatory"></span>
            </div>
            <Select
              id="mobile_number"
              placeholder="Select Number"
              style={{ width: "100%", height: "38px" }}
              options={custMobileOptions}
              value={vendorMobileNumber}
              onChange={(e) => {
                setVendorMobileNumber(e);
                const vendor = vendorList.find((c) => c.mobile_number === e);
                formik.setFieldValue("vendor_id", vendor.id);
                formik.setFieldValue("name", vendor.company_name);
                formik.setFieldValue("address", vendor.address);
              }}
            />
          </Col>
          <Col span={6} className="gutter-box">
            <div>
              <span className="hintText">Purchase Date</span>
            </div>
            <DatePicker
              style={{ width: "100%", height: "38px" }}
              disabled={true}
              format={{
                format: "YYYY-MM-DD",
                type: "mask",
              }}
              value={formik.values.purchase_order_date}
            />
          </Col>
          <Col span={4} offset={4} className="gutter-box">
            <div>
              <span className="hintText">Purchase Nr</span>
              <span className="mandatory"></span>
            </div>
            <Input
              type="text"
              placeholder="Enter Purchase Nr"
              id="purchase_order_no"
              style={{ width: "100%" }}
              onChange={formik.handleChange}
              value={formik.values["purchase_order_no"]}
            />
          </Col>
        </Row>
        <Row gutter={16} className="gutter-row">
          <Col span={8} className="gutter-box">
            <div>
              <span className="hintText">Vendor Name</span>
            </div>
            <Input
              type="text"
              id="name"
              disabled={true}
              style={{ width: "100%" }}
              onChange={formik.handleChange}
              value={formik.values.name}
            />
          </Col>
          <Col span={8} className="gutter-box">
            <div>
              <span className="hintText">Vendor Address</span>
            </div>
            <Input
              type="text"
              id="address"
              disabled={true}
              style={{ width: "100%" }}
              onChange={formik.handleChange}
              value={formik.values.address}
            />
          </Col>
          <Col span={8} className="gutter-box">
            <div>
              <span className="hintText">Plant</span>
              <span className="mandatory"></span>
            </div>
            <Select
              id="plant_code"
              placeholder="Select a Plant"
              style={{ width: "100%", height: "38px" }}
              options={plants}
              value={selectedPlant}
              onChange={(e) => {
                setSelectedPlant(e);
                formik.setFieldValue("plant_code", e);
              }}
            />
          </Col>
        </Row>
        <Row gutter={16} className="gutter-row">
          <Col span={9} className="gutter-box">
            <div>
              <span className="hintText">Sales Person Name</span>
              <span className="mandatory"></span>
            </div>
            <Select
              id="sales_person_id"
              placeholder="Select a Name"
              style={{ width: "100%", height: "38px" }}
              options={costCenterList}
              value={selectedSalesPerson}
              onChange={(e) => {
                setSelectedSalesPerson(e);
                formik.setFieldValue("sales_person_id", e);
              }}
            />
          </Col>

          <Col span={3} className="gutter-box">
            <div>
              <span className="hintText">OpgPure</span>
            </div>
            <Input
              type="text"
              id="opg_pure"
              style={{ width: "100%" }}
              onChange={formik.handleChange}
              value={formik.values.opg_pure}
            />
          </Col>
          <Col span={3} className="gutter-box">
            <div>
              <span className="hintText">OpgCash</span>
            </div>
            <Input
              type="text"
              id="opg_cash"
              style={{ width: "100%" }}
              onChange={formik.handleChange}
              value={formik.values.opg_cash}
            />
          </Col>
          <Col span={3} className="gutter-box">
            <div>
              <span className="hintText">Reference No</span>
            </div>
            <Input
              type="text"
              id="reference_no"
              style={{ width: "100%" }}
              onChange={formik.handleChange}
              value={formik.values.reference_no}
            />
          </Col>
          <Col span={3} className="gutter-box">
            <div>
              <span className="hintText">Bar Rate</span>
            </div>
            <Input
              type="text"
              id="bar_rate"
              style={{ width: "100%" }}
              onChange={formik.handleChange}
              value={formik.values.bar_rate}
            />
          </Col>
          <Col span={3} className="gutter-box">
            <div>
              <span className="hintText">Thru</span>
            </div>
            <Input
              type="text"
              id="thru"
              style={{ width: "100%" }}
              onChange={formik.handleChange}
              value={formik.values.thru}
            />
          </Col>
        </Row>
      </>
    );
  };

  const touchTypes = (form) => (
    <Select
      value={form.values.touch_unit}
      style={{ width: 60 }}
      onChange={(e) => form.setFieldValue("touch_unit", e)}
    >
      <Option value="g">g</Option>
      <Option value="%">%</Option>
    </Select>
  );

  const salesItemTable = (datasoure) => {
    return datasoure.length > 0 ? (
      <>
        <Row gutter={16} className="gutter-row sales-list">
          <Col span={24} className="gutter-box">
            <Table
              dataSource={datasoure}
              columns={purchaseItemColumns}
              pagination={{ position: ["bottomCenter"], pageSize: 2 }}
              rowKey="sales_item_name"
              size="small"
              bordered="true"
              scroll={{ y: 100 }}
              style={{ fontFamily: "Poppins" }}
            />
          </Col>
        </Row>
      </>
    ) : null;
  };

  const purchaseItemForm = () => {
    return (
      <>
        <Row gutter={16} className="gutter-row">
          <Col span={18} className="gutter-box">
            <div>
              <span className="hintText">Item Name</span>
              <span className="mandatory"></span>
            </div>
            <Select
              id="item_name"
              placeholder="Select an Item"
              style={{ width: "100%", height: "38px" }}
              options={itemDataOptions}
              value={purchaseItemFormik.values.sales_item_name}
              onChange={(value) => {
                purchaseItemFormik.setFieldValue('item_name', value);
                purchaseItemFormik.setFieldValue('sales_item_name', value);
                updateItemFields(value, purchaseItemFormik, false);
              }}
            />
          </Col>
          <Col span={3} className="gutter-box">
            <div>
              <span className="hintText">Unit</span>
            </div>
            <Input
              type="text"
              id="unit"
              disabled={true}
              style={{ width: "100%", color: "#000000" }}
              onChange={purchaseItemFormik.handleChange}
              value={purchaseItemFormik.values.unit}
            />
          </Col>
          <Col span={3} className="gutter-box">
            <div>
              <span className="hintText">PCS</span>
            </div>
            <Input
              type="number"
              id="pcs"
              style={{ width: "100%" }}
              onChange={purchaseItemFormik.handleChange}
              value={purchaseItemFormik.values.pcs}
            />
          </Col>
        </Row>
        <Row gutter={16} className="gutter-row">
          <Col span={10} className="gutter-box">
            <Space.Compact style={{ width: "100%" }}>
              <div style={{ width: "50%" }}>
                <div>
                  <span className="hintText">Weight</span>
                  <span className="mandatory"></span>
                </div>
                <Input
                  type="number"
                  id="weight"
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    purchaseItemFormik.handleChange(e);
                    calculateTaxAmount(purchaseItemFormik);
                  }}
                  value={purchaseItemFormik.values.weight}
                />
              </div>
              <div style={{ width: "50%" }}>
                <div>
                  <span className="hintText">Current Market Rate</span>
                </div>
                <Input
                  type="text"
                  prefix="₹"
                  disabled={true}
                  id="current_rate"
                  style={{ width: "100%", padding: "7px", color: "#000000" }}
                  onChange={(e) => {
                    purchaseItemFormik.handleChange(e);
                    calculateTaxAmount(purchaseItemFormik);
                  }}
                  value={purchaseItemFormik.values.current_rate}
                />
              </div>
            </Space.Compact>
          </Col>
          <Col span={3} className="gutter-box">
            <div>
              <span className="hintText">CGST (%)</span>
            </div>
            <Input
              type="text"
              disabled={true}
              id="cgst"
              style={{ width: "100%", color: "#000000" }}
              onChange={purchaseItemFormik.handleChange}
              value={purchaseItemFormik.values.cgst}
            />
          </Col>
          <Col span={3} className="gutter-box">
            <div>
              <span className="hintText">SGST (%)</span>
            </div>
            <Input
              type="text"
              disabled={true}
              id="sgst"
              style={{ width: "100%", color: "#000000" }}
              onChange={purchaseItemFormik.handleChange}
              value={purchaseItemFormik.values.sgst}
            />
          </Col>
          <Col span={6} className="gutter-box">
            <div>
              <span className="hintText">Tax Amount</span>
            </div>
            <Input
              type="number"
              id="tax_amount"
              style={{ width: "100%", color: "#000000" }}
              onChange={purchaseItemFormik.handleChange}
              value={purchaseItemFormik.values.tax_amount}
              disabled={true}
            />
          </Col>
        </Row>
        <Row gutter={16} className="gutter-row">
          <Col span={3} className="gutter-box">
            <div>
              <span className="hintText">Stone</span>
            </div>
            <Input
              type="text"
              id="stone"
              style={{ width: "100%", color: "#000000" }}
              onChange={purchaseItemFormik.handleChange}
              value={purchaseItemFormik.values.stone}
            />
          </Col>
          <Col span={5} className="gutter-box">
            <div>
              <span className="hintText">Waste</span>
            </div>
            <Input
              type="text"
              style={{ width: "100%", color: "#000000" }}
              addonAfter={wasteTypes(purchaseItemFormik)}
              id="waste"
              onChange={purchaseItemFormik.handleChange}
              value={purchaseItemFormik.values.waste}
            />
          </Col>
          <Col span={4} className="gutter-box">
            <div>
              <span className="hintText">Touch</span>
            </div>
            <Input
              type="text"
              id="touch"
              style={{ width: "100%" }}
              onChange={purchaseItemFormik.handleChange}
              value={purchaseItemFormik.values.touch}
            />
          </Col>
          <Col span={4} className="gutter-box">
            <div>
              <span className="hintText">MC Amount</span>
            </div>
            <Input
              type="number"
              id="mc_amount"
              style={{ width: "100%" }}
              onChange={purchaseItemFormik.handleChange}
              value={purchaseItemFormik.values.mc_amount}
            />
          </Col>
          <Col span={4} className="gutter-box">
            <div>
              <span className="hintText">HUID</span>
            </div>
            <Input
              type="text"
              id="huid"
              style={{ width: "100%" }}
              onChange={purchaseItemFormik.handleChange}
              value={purchaseItemFormik.values.huid}
            />
          </Col>
          <Col span={8} className="gutter-box">
            <div>
              <span className="hintText">Other Details</span>
            </div>
            <Input
              type="text"
              id="other_details"
              style={{ width: "100%" }}
              onChange={purchaseItemFormik.handleChange}
              value={purchaseItemFormik.values.other_details}
            />
          </Col>
        </Row>
      </>
    );
  };



  const createNewFormSection = () => {
    return (
      <>
        {purchaseOrderForm()}
        <Divider orientation="left" orientationMargin="0">
          Purchase
        </Divider>
        {purchaseContainer()}
        <Card style={{ marginTop: "35px" }}>
          <Row gutter={16} className="gutter-row">
            <Col
              span={8}
              offset={16}
              className="gutter-box"
              style={{ display: "flex", justifyContent: "end" }}
            >
              <Statistic
                title="Total Weight (g)"
                value={formik.values.total_weight}
                precision={3}
                style={{
                  textAlign: "right",
                  border: "1px dotted #d3d3d3",
                  padding: "10px",
                }}
                valueStyle={{
                  width: "275px",
                  fontSize: "28px",
                  textAlign: "right",
                  color: "#000000",
                  fontWeight: "bolder",
                }}
              />
            </Col>
          </Row>
        </Card>
      </>
    );
  };

  return (
    <>
      <PageTitle
        imagesrc={PurchaseImage}
        pageTitle="Create Purchase Entry"
        buttonList={[
          {
            buttonName: "Back",
            className: "btn-back",
            url: "/slj/purchase",
          },
        ]}
      />
      {contextHolder}
      <div className="content-area">
        <div className="formAreaStyle">{createNewFormSection()}</div>
      </div>
      <div className="formSubmitArea">
        <div className="formSubmitInnerArea">
          <Button
            type="primary"
            size="large"
            style={{ width: "170px", backgroundColor: "grey" }}
            onClick={() => {
              formik.resetForm();
              setPurchaseItemList([]);
              purchaseItemFormik.resetForm();
            }}
          >
            Reset Fields
          </Button>
          <Button
            type="primary"
            size="large"
            style={{ width: "170px", backgroundColor: "#1f43e5" }}
            onClick={formik.handleSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
      <Modal
        title={
          editingItemIndex !== null ? "Edit Purchase Item" : "Add Purchase Item"
        }
        closable={false}
        maskClosable={false}
        keyboard={false}
        open={modelType === "PURCHASE"}
        onOk={handleOk}
        width={"800px"}
        onCancel={handleCancel}
      >
        <div>{purchaseItemForm()}</div>
      </Modal>
    </>
  );
};

export default PurchaseForm;
