import React, { useEffect, useState } from 'react';
import { Button, Col, DatePicker, Divider, Flex, Form, Input, InputNumber, Popconfirm, Radio, Row, Select, Space, Statistic, Steps, Switch, Table, Tag, Typography, notification } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined, CalendarOutlined, CheckCircleFilled, DeleteFilled, EditOutlined, PlusOutlined, ProfileOutlined, ProfileTwoTone, SolutionOutlined, UserOutlined } from '@ant-design/icons';
import SaleImage from '../../assets/images/customer_receipt.svg';
import '../../css/container.css';
import '../../css/SmallComponents.css';
import httpCall from '../../utils/api';
import { useFormik } from 'formik';
import PageTitle from '../common/PageTitle';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import EditableCell from '../common/EditableCell';

const SalesReceiptCreate = () => { 
	const location = useLocation();
	const navigate = useNavigate();
    const [salesBillList, setSalesBillList] = useState([]);    
    const [custNameOptions, setCustNameOptions] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const [billOptions, setBillOptions] = useState([]);
	const [api, contextHolder] = notification.useNotification();
    const [billList, setBillList] = useState([]);
    const [current, setCurrent] = useState(0);
    const [selectedBills, setSelectedBills] = useState([]);
    const [paymentOptions, setPaymentOptions] = useState([]);
    const [accountList, setAccountList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [activatePM2, setActivatePM2] = useState(false);

    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState('');
	const isEditing = (record) => record.invoice_no === editingKey;

    const steps = [
        {
          title: 'Select Customer',
          content: 'First-content',
          icon: <UserOutlined />
        },
        {
          title: 'Select Bills To Pay',
          content: 'Second-content',
          icon: <SolutionOutlined />
        },
        {
          title: 'View Receipt Summary',
          content: 'Last-content',
        },
      ];
      const prev = () => {
        setCurrent(current - 1);
      };
      const next = () => {
        setCurrent(current + 1);
      };

      const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
        icon: item.icon,
      }));
      

    const formik = useFormik({
		initialValues: {
            invoice_receipt_no: '1',
            invoice_receipt_no_inc: '',  
            invoice_receipt_date: moment(),
            customer_id: '',
            customer_name: '',
            payment_mode_1: 'Cash',  
            account_1: '',
            payment_mode_2: 'Banking',
            account_2: '',
            paid_amount_1: '',
            paid_amount_2: '',
            cust_pending_amount: '',
            total_paid_amount: '',
		},
		onSubmit: (values, {resetForm}) => {    
            // const pending_amount = parseFloat(formik.values.pending_amount || 0);
            // const advance_amount = parseFloat(formik.values.advance_amount || 0);

            // const advance = values.customer_advance_amount + advance_amount;
            // const pending = values.customer_pending_amount + pending_amount;

            // if(advance >= pending) {
            //     values.customer_advance_amount = advance - pending;
            //     values.customer_pending_amount = 0;
            // } else {
            //     values.customer_pending_amount = pending - advance;
            //     values.customer_advance_amount = 0;
            // }
            // values['billList'] = billList;
            values['receiptItems'] = billList;
            values.invoice_receipt_date = moment(values.invoice_receipt_date).format('YYYY-MM-DD');    
            // console.log(values);
            createSalesRecipt(values);
		},
	});

    useEffect(() => {
        formik.setFieldValue('sales_receipt_amount', totalAmountPaidForSelectedBills());
    }, [billList]);

    const billsFormik = useFormik({
		initialValues: {
            invoice_receipt_no: '',
            invoice_no: '',
            bill_amount: '',
            pending_amount: '',
            paid_amount: ''
		}
	});

    const openErrorNotification = (description) => {
		api.error({
		  message: 'Error !',
		  duration: 4,		  
		  description: description,
		});
	};

    const edit = function(record){        
		form.setFieldsValue({
            paid_amount: 0,
			...record,
		});
		setEditingKey(record.invoice_no);
	};

	const cancel = () => {
		setEditingKey('');
	};

    const totalPendingAmountPerVendor = (customerPendingBills) => {
        return customerPendingBills.reduce((acc, next) => acc + parseFloat(next.bill_amount || 0), 0);
    }

    const totalAmountPaidForSelectedBills = () => {
        return billList.reduce((acc, next) => acc + parseFloat(next.paid_amount || 0), 0);
    }

    const totalAmountInCustomerWallet = () => {
        return parseFloat(formik.values.total_paid_amount || 0) + parseFloat(formik.values.customer_advance_amount || 0);
    }

    const update = async (key) => {
		try {
			const row = await form.validateFields();
            const updated_paid_amount = parseFloat(row.paid_amount || 0);
            const mBill = billList.find(bill => bill.invoice_no === key);            
            if(mBill.bill_amount < updated_paid_amount) {
                openErrorNotification('Paid Amount Per Bill Exceeds Bill Amount');
                cancel();
                return;
            }
            let paidTotal = totalAmountPaidForSelectedBills();
            paidTotal = paidTotal - mBill.paid_amount + updated_paid_amount;            

            if(totalAmountInCustomerWallet() < paidTotal) {
                openErrorNotification('Total Paid Amount Exceeds Customer Payment');
                cancel();
                return;
            }
            billList.forEach(bill => {
                if(bill.invoice_no === key) {                           
                    bill.paid_amount = row.paid_amount;
                    bill.pending_amount = bill.bill_amount - row.paid_amount;
                }
            });
            setBillList([...billList]);
            cancel();
		} catch (errInfo) {
			console.log('Validate Failed:', errInfo);
		}
	};

    useEffect(() => {
        fetchNextReceiptNo();
        fetchPaymentOptions();
        fetchCustomerList();  
        fetchAccountList();
	}, []);

    const fetchAccountList = () => {
		setLoading(true);
		httpCall({
			method: 'GET',
			url: '/api/account/list',
		})
			.then((response) => {
				if (response) {
					setAccountList(response?.data.map(acc => ({label: `${acc.bank_name} - ${acc.account_no}`, value: acc.account_no})));
				} else {
					console.error(
						'Error retrieving account list',
						response.message
					);
				}
				setLoading(false);
			})
			.catch((error) => {
				console.error('Error retrieving account list', error);
				setLoading(false);
			});
	};

    // useEffect(() => {
    //     const totalAmountGiven = parseFloat(formik.values.total_paid_amount || 0);
    //     const totalBillAmount = billList.reduce((acc, next) => acc + parseFloat(next.bill_amount), 0);
    //     const totalPaidAmount = billList.reduce((acc, next) => acc + parseFloat(next.paid_amount), 0);  
    //     console.log(totalAmountGiven, totalBillAmount, totalPaidAmount)      
    //     if((totalAmountGiven - totalPaidAmount) >= (totalBillAmount - totalPaidAmount)) {
    //         formik.setFieldValue('advance_amount', ((totalAmountGiven - totalPaidAmount) - (totalBillAmount - totalPaidAmount)).toFixed(3));
    //         formik.setFieldValue('pending_amount', 0);
    //     }
    //     if((totalAmountGiven - totalPaidAmount) < (totalBillAmount - totalPaidAmount)) {
    //         formik.setFieldValue('advance_amount', 0);
    //         formik.setFieldValue('pending_amount', ((totalBillAmount - totalPaidAmount) - (totalAmountGiven - totalPaidAmount)).toFixed(3));
    //     }        
	// }, [billList]);

    useEffect(() => {
        if(formik.values.customer_id) {
            fetchBillByCustomer(formik.values.customer_id);
        }        
    }, [formik.values.customer_id]);

    const fetchPaymentOptions = () => {
		httpCall({
			method: 'GET',
			url: '/api/payment/list?filter=true',
		})
			.then((response) => {
                setPaymentOptions(response?.data);
			})
			.catch((error) => {
				console.error('Error retrieving payment list:', error);
			});
	};

    const openNotification = (description) => {
		api.open({
		  message: 'Success !',
		  duration: 2,		  
		  description: description,
		  icon: <CheckCircleFilled style={{ color: '#808000' }} />,		  
		});
	};

    const openAlertNotification = (description) => {
		api.error({
		  message: 'Error !',
		  duration: 4,		  
          placement: 'top',
		  description: description,	  
		});
	};
    
    const fetchNextReceiptNo = () => {
		httpCall({
			method: 'GET',
			url: '/api/sales/getInvoiceReceiptNr',
		})
			.then((response) => {
				if (response) {
                    formik.setFieldValue('invoice_receipt_no', `IR${response.data}/${moment().format('DDMMYYYY')}`);
                    formik.setFieldValue('invoice_receipt_no_inc', response.data);
				} else {
					console.error(
						'Error retrieving salses receipt no',
						response.message
					);
				}
			})
			.catch((error) => {
				console.error('Error retrieving salses receipt no', error);
			});
	};

    
    const createSalesRecipt = (values) => {
		httpCall({
			method: 'POST',
			url: '/api/sales/invoiceReceipt',
            payload: values
		})
			.then((response) => {
				if (response) {
                    openNotification('Invoice Receipt Created Successfully !')
				} else {
                    console.log('Error while creating invoice receipt');
				}
			})
			.catch((error) => {
				console.error('Error retrieving customer list:', error);
			});
	};

    const fetchCustomerList = () => {
		httpCall({
			method: 'GET',
			url: '/api/customer/get',
		})
			.then((response) => {
				if (response) {
                    setCustomerList(response?.data);
                    setCustNameOptions(response?.data.map(c => ({label: c.company_name, value: c.id})))
				} else {
					console.error(
						'Error retrieving customer list:',
						response.message
					);
				}
			})
			.catch((error) => {
				console.error('Error retrieving customer list:', error);
			});
	};

    const fetchBillByCustomer = (id) => {
		httpCall({
			method: 'GET',
			url: '/api/sales/getInvoiceDetails?customerId=' + id,
		})
			.then((response) => {
				if (response) {
                    // setSalesBillList(response?.data);
                    // setBillOptions(response?.data.map(sale => ({label: sale.invoice_no, value: sale.invoice_no})));
                    const list = response?.data.map(bill =>({invoice_no: bill.invoice_no, bill_amount: bill.pending_amount, paid_amount: 0, pending_amount: 0}))
                    setBillList([...list]);
                    formik.setFieldValue('cust_pending_amount', totalPendingAmountPerVendor([...list]));
				} else {
					console.error(
						'Error retrieving customer list:',
						response.message
					);
				}
			})
			.catch((error) => {
				console.error('Error retrieving customer list:', error);
			});
	};

    const salesBillColumns = [
        {
			title: 'Bill Nr',
			dataIndex: 'invoice_no',
			key: 'invoice_no',
			align: 'center',
			width: 250
		},
        {
			title: 'Bill Amount',
			dataIndex: 'bill_amount',
			key: 'bill_amount',		
			align: 'center',
			width: 100
		},
        {
			title: 'Paid Amount',
			dataIndex: 'paid_amount',
			key: 'paid_amount',		
			align: 'center',
            editable: true,
			width: 150,
            // render(text, record) {
            //     return {
            //       props: {
            //         style: { background: record.paid_amount !== record.bill_amount ? "#FF414D" : null,  color: record.paid_amount !== record.bill_amount ? "#FFFFFF" : null}
            //       },
            //       children: <div>{text}</div>
            //     };
            // }
		},
        {
			title: 'Action',
			key: 'action',
			align: 'center',
			width: 150,
			render: (_, record) => {
				const editable = isEditing(record);
				return editable ? (
					<span>
						<Typography.Link
							onClick={() => update(record.invoice_no)}
							style={{
								marginRight: 8,
							}}
						> Save
						</Typography.Link>
						<Popconfirm title="Sure to cancel?" onConfirm={cancel}>
							<a>Cancel</a>
						</Popconfirm>
					</span>
				) : (
                    <EditOutlined
                    style={{
                        cursor: 'pointer',
                        fontSize: '20px',
                        color: '#08c',
                    }}
                    onClick={() => edit(record)}
                />
		    	);
            }		
		},
        {
			title: 'Status',
			dataIndex: 'status',
			key: 'status',		
			align: 'center',
			width: 150,
            render: (_, record) => {
                return record.bill_amount > record.paid_amount ? (
                    <Tag color='#f50'>PENDING</Tag>
                ) : (<Tag color="#87d068">FULLY PAID</Tag>);
            }
		},
	];

    const rowSelection = {
        hideSelectAll: true,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedBills(selectedRows);
        },
        onSelect: (record, selected, selectedRows) => {
            if(selected) {
                let possibleAmount = 0;
                let isPending = false;
                const totalAmtInCustomerWallet = totalAmountInCustomerWallet();
                const totalAmtPaidForSelectedBills = totalAmountPaidForSelectedBills();
                const selectedPaidAmt = record.bill_amount;

                if(totalAmtInCustomerWallet < (totalAmtPaidForSelectedBills + selectedPaidAmt)) {
                    openErrorNotification('Total Paid Amount Exceeds Customer Payment, So Updating Amount');
                    possibleAmount = totalAmtInCustomerWallet - totalAmtPaidForSelectedBills;
                    isPending = true;
                }

                billList.forEach(bill => {
                    if(bill.invoice_no === record.invoice_no) { 
                        if(isPending) {
                            bill.paid_amount = possibleAmount;
                            bill.pending_amount = bill.bill_amount - possibleAmount;
                        } else {
                            bill.paid_amount = bill.bill_amount;
                            bill.pending_amount = 0;
                        }
                    }
                });                
                setBillList([...billList]);
            } else {
                billList.forEach(bill => {
                    if(bill.invoice_no === record.invoice_no) {
                        bill.paid_amount = 0;
                        bill.pending_amount = bill.bill_amount;
                    }
                })
                setBillList([...billList]);
            }            
        }, 
    };

    const mergedColumns = salesBillColumns.map((col) => {
		if (!col.editable) {
		  return col;
		}
		return {
		  ...col,
		  onCell: (record) => ({
			record,
			inputType: 'text',
			dataIndex: col.dataIndex,            
			title: col.title,
			editing: isEditing(record),
		  }),
		};
	  });

    const salesBillTable = (datasoure) => {
        return datasoure.length > 0 ? (
            <>
                <Row gutter={16} className="gutter-row sales-list">
                    <Col span={24} className="gutter-box">
                    <Form form={form} component={false}>
                        <Table                        
                            rowSelection={{type: 'checkbox', ...rowSelection}}
                            components={{
                                body: {
                                    cell: EditableCell,
                                },
                            }}
                            dataSource={datasoure}
                            columns={mergedColumns}
                            pagination={false}
                            rowKey="invoice_no"
                            rowClassName="editable-row"
                            size='small'
                            bordered="true"						
                            scroll={{ y: 250 }}
                            style={{fontFamily: 'Poppins', fontSize: '18px', height: '250px'}}/>
                    </Form>

                    </Col>
                </Row>
            </>
        ) : null
    }

    const step1 = () => {
        return (
            <>
                <Row gutter={16} justify="center" className="gutter-row">
                    <Col span={24} className="gutter-box">
                        <div style={{textAlign: 'right'}}>
                            <span className="hintText color-black">Receipt No</span>                            
                        </div> 
                        <Input
                            type="text"
                            id="invoice_receipt_no"
                            disabled={true}
                            variant="borderless"
                            style={{ width: '100%', textAlign: 'right', color: '#000000', paddingLeft: '0px', fontSize: '14px'}}
                            value={formik.values['invoice_receipt_no']}
                        />                                
                    </Col>
                </Row>
                <Row gutter={16} justify="center" className="gutter-row">
                    <Col span={8} className="gutter-box">
                        <div>
                            <span className="hintText color-black">Customer</span>
                        </div>                                
                        <Select
                            id="customer_id"
                            placeholder="Select Customer"
                            style={{ width: '100%', height: '38px' }}
                            options={custNameOptions}
                            onChange={(e)=> {
                                formik.setFieldValue("customer_id", e);
                                const customer = customerList.find(cust => cust.id === e);
                                formik.setFieldValue("customer_advance_amount", parseFloat(customer.advance_amount || 0));                                
                                formik.setFieldValue("customer_name", customer.company_name);
                            }}
                        />
                    </Col>
                    <Col span={6} className="gutter-box">
                        <div>
                            <span className="hintText color-black">Total Pending Amount</span>
                        </div>                                
                        <Input
                            disabled={true}
                            type="text"
                            id="cust_pending_amount"
                            style={{ width: '100%', color: 'green', fontSize: '18px', height: '38px'}}
                            value={formik.values['cust_pending_amount']}
                        />
                    </Col>
                    <Col span={8} offset={2} className="gutter-box">
                        <div>
                            <span className="hintText color-black">Receipt Date</span>                            
                        </div> 
                        <DatePicker                                    
                            style={{ width: '100%', height: '38px' }}
                            format={{
                                format: 'YYYY-MM-DD',
                                type: 'mask',
                            }}
                            value={formik.values.invoice_receipt_date}
                            onChange={(date, dateString) => {
                                formik.setFieldValue("invoice_receipt_date", date);  
                            }}
                        />                             
                    </Col>   
                </Row>
                <Row gutter={16} justify="end" className="gutter-row">
                    <Col span={8} className="gutter-box">
                        <div>
                            <span className="hintText color-black" style={{fontWeight: '600'}}>PAYMENT MODE 1</span>                            
                        </div> 
                        <Radio.Group 
                            style={{marginTop: '5px', fontSize:' 16px'}}
                            onChange={(e) => {formik.setFieldValue('payment_mode_1', e.target.value)}} value={formik.values.payment_mode_1}>
                            {paymentOptions.map((option, index) => <Radio key={index} style={{fontSize:'13px', fontFamily:'poppins'}} value={option.mop}>{option.mop}</Radio>)}                
                        </Radio.Group>
                    </Col>
                    <Col span={8} className="gutter-box">
                        <div>
                            <span className="hintText color-black">Bank</span>                            
                        </div> 
                        <Select
                            id="account_1"
                            disabled={formik.values.payment_mode_1 === 'Cash'}
                            placeholder="Select an Account"
                            style={{ width: '75%', height: '38px' }}
                            options={accountList}
                            onChange={(e)=> {
                                formik.setFieldValue("account_1", e);
                            }}
                        />
                    </Col>
                    <Col span={8} className="gutter-box">
                        <div>
                            <span className="hintText color-black">Payment 1</span>
                        </div>                                
                        <Input
                            type="text"
                            prefix="₹"
                            placeholder='0.00'
                            id="paid_amount_1"
                            style={{ width: '100%', height: '38px'}}
                            value={formik.values['paid_amount_1']}
                            onChange={(e) => {
                                const value = e.target.value;
                                formik.setFieldValue('paid_amount_1', value);
                                const paid_amount_2 = parseFloat(formik.values.paid_amount_2 || 0);
                                formik.setFieldValue('total_paid_amount', paid_amount_2 + parseFloat(value || 0));
                            }}
                        />  
                    </Col>
                </Row>
                <Row gutter={16} justify="end" className="gutter-row">
                    <Col span={8} className="gutter-box">
                        <div>
                            <span className="hintText color-black" style={{fontWeight: '600'}}>PAYMENT MODE 2</span>  
                            <Switch checked={activatePM2} onChange={(e) => {
                                if(!e) {
                                    formik.setFieldValue("paid_amount_2", '');
                                    formik.setFieldValue("amount_2_received", '');
                                }
                                setActivatePM2(e);
                            }}>
                            </Switch>                    
                        </div> 
                        <Radio.Group 
                            disabled={!activatePM2}
                            size="large"                                    
                            style={{marginTop: '5px', fontFamily: 'Poppins'}}
                            onChange={(e) => {formik.setFieldValue('payment_mode_2', e.target.value)}} value={formik.values.payment_mode_2}>
                            {paymentOptions.map((option, index) => <Radio key={index} style={{fontSize:'13px', fontFamily:'poppins'}} value={option.mop}>{option.mop}</Radio>)}
                        </Radio.Group>
                    </Col>
                    <Col span={8} justify="center" className="gutter-box">
                        <div>
                            <span className="hintText color-black">Bank</span>                            
                        </div> 
                        <Select
                            id="account_2"
                            disabled={formik.values.payment_mode_2 === 'Cash' || !activatePM2}
                            placeholder="Select an Account"
                            style={{ width: '75%', height: '38px' }}
                            options={accountList}
                            value={formik.values.account_2}
                            onChange={(e)=> {
                                formik.setFieldValue("account_2", e);
                            }}
                        />
                    </Col>
                    <Col span={8} className="gutter-box">
                        <div>
                            <span className="hintText color-black">Payment 2</span>
                        </div>                                
                        <Input                                    
                            type="text"
                            disabled={!activatePM2}
                            prefix="₹"
                            placeholder='0.00'
                            id="paid_amount_2"
                            style={{ width: '100%', height: '38px'}}
                            value={formik.values['paid_amount_2']}
                            onChange={(e) => {
                                const value = e.target.value;
                                formik.setFieldValue('paid_amount_2', value);
                                const paid_amount_1 = parseFloat(formik.values.paid_amount_1 || 0);
                                formik.setFieldValue('total_paid_amount', paid_amount_1 + parseFloat(value || 0));
                            }}
                        />  
                    </Col>
                </Row>
                <Row gutter={16} justify="end" className="gutter-row">
                    <Col span={8} className="gutter-box">
                        <div>
                            <span className="hintText color-black">Total Amount To Pay</span>
                        </div>                                
                        <InputNumber
                            precision={3}
                            disabled={true}
                            id="total_paid_amount"                                    
                            style={{ width: '100%', color: '#000000', fontSize: '18px', height: '38px'}}
                            value={formik.values['total_paid_amount']}
                        />   
                    </Col>            
                </Row>
                
            </>
        )
    }

    const step2 = () => {
        return salesBillTable(billList);
    }

    const step3 = () => {
        return (
            <>
                <Row gutter={16} justify="center" className="gutter-row">
                    <Col span={12} className="gutter-box">
                        <div style={{textAlign: 'right', height: '40px', backgroundColor: '#d3d3d3', border: '1px solid #d3d3d3', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <span style={{fontFamily: 'poppins', fontSize: '15px', fontWeight: '700'}}>RECEIPT SUMMARY</span>                
                        </div>
                    </Col>
                </Row>
                <Row gutter={16} justify="center" className="gutter-row">
                    <Col span={10} className="gutter-box">
                        <Row gutter={16} className="gutter-row">
                            <Col span={12} style={{textAlign: 'left'}} className="gutter-box">
                                <div style={{fontSize: '14px', paddingBottom: '8px', fontWeight: '600'}}>Receipt No</div>
                                <span>{formik.values.invoice_receipt_no}</span>
                            </Col>
                            <Col span={12} style={{textAlign: 'right'}} className="gutter-box">
                                <div style={{fontSize: '14px', paddingBottom: '8px', fontWeight: '600'}}>Receipt Date</div>
                                <span>{moment(formik.values.invoice_receipt_date).format('YYYY-MM-DD')}</span>
                            </Col>
                        </Row>
                        <Row gutter={16} className="gutter-row">
                            <Col span={24} style={{textAlign: 'left'}} className="gutter-box">
                                <div style={{fontSize: '14px', paddingBottom: '8px', fontWeight: '600'}}>Customer Name</div>
                                <span>{formik.values.customer_name}</span>
                            </Col>
                        </Row>
                        <Row gutter={16} justify="center" className="gutter-row" style={{marginTop: '20px'}}>
                            <Col span={12} style={{fontSize: '14px', fontWeight: '700', textAlign: 'center', border: '1px solid #d3d3d3'}} className="gutter-box">
                                <span>Invoice Number</span>
                            </Col>
                            <Col span={12} style={{fontSize: '14px', fontWeight: '700', textAlign: 'center', border: '1px solid #d3d3d3'}} className="gutter-box">
                                <span>Paid Amount</span>
                            </Col>
                        </Row>
                        {billList.map(invoice => (
                            <Row gutter={16} justify="center" className="gutter-row">
                                <Col span={12} style={{textAlign: 'center', border: '1px solid #d3d3d3'}} className="gutter-box">
                                    <span>{invoice.invoice_no}</span>
                                </Col>
                                <Col span={12} style={{textAlign: 'center', border: '1px solid #d3d3d3'}} className="gutter-box">
                                    <span>{invoice.paid_amount}</span>
                                </Col>
                            </Row>
                        ))}
                        <Row gutter={16} justify="center" className="gutter-row" style={{marginTop: '1px'}}>
                            <Col span={12} style={{fontSize: '15px', fontWeight: '700', textAlign: 'center', border: '1px dotted #d3d3d3'}} className="gutter-box">
                                <span>Total Receipt Amount</span>
                            </Col>
                            <Col span={12} style={{fontSize: '15px', fontWeight: '700', textAlign: 'center', border: '1px dotted #d3d3d3'}} className="gutter-box">
                                <span>{formik.values.sales_receipt_amount}</span>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        )
    }
    const createNewFormSection = () => {
		return (
			<>
                {/* <Row gutter={16} justify="center" className="gutter-row">
                    <Col span={24} className="gutter-box">
                        <div style={{textAlign: 'right', height: '40px', backgroundColor: '#F5F5F5', border: '1px solid #d3d3d3', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <span style={{fontFamily: 'poppins', fontSize: '15px', fontWeight: '700'}}>SALES RECEIPT</span>                
                        </div>
                    </Col>
                </Row> */}
                <Row gutter={16} justify="center" className="gutter-row">
                    <Col span={24} className="gutter-box">
                        <Steps current={current} items={items} style={{margin: '15px 0px'}} />
                    </Col>
                </Row>
                <Row gutter={16} justify={"center"} className="gutter-row">
                    <Col span={24}>
                        {current === 0 && step1()}
                        {current === 1 && step2()}
                        {current === 2 && step3()}
                    </Col>
               </Row>
               <Row gutter={16} className="gutter-row">
                            <Col span={2} className="gutter-box">
                            {current > 0 && (<Button 
                                    type='primary' 
                                    style={{marginTop: '15px', width: '100%'}} 
                                    onClick={() => {
                                        prev()
                                    }}
                                    >
                                        Back
                                </Button>)}
                            </Col>
                            <Col span={2} offset={20} className="gutter-box">
                            {current < steps.length - 1 && (<Button 
                                    type='primary' 
                                    style={{marginTop: '15px', width: '100%'}} 
                                    onClick={() => {
                                        if(current === 0) {
                                            const custAmount = parseFloat(formik.values.total_paid_amount || 0);
                                            if(1 > custAmount) {
                                                openErrorNotification('Minimum Payment Should be > 1 INR');
                                                return;
                                            }
                                        }
                                        if(current === 1) {
                                           if(selectedBills.length === 0) {
                                                openErrorNotification('Please select a bill to proceed.');
                                                return;
                                           }
                                           const uList = billList.filter(bill => bill.paid_amount > 0);
                                           setBillList(uList);
                                        }
                                        next()
                                    }}
                                    >
                                        Next
                                </Button>)}
                            </Col>
                        </Row>
			</>
		);
	};

	return (
		<>
		<PageTitle
			imagesrc={SaleImage}
			pageTitle="Sales Receipt"
            buttonList={[{
                buttonName: 'Back',
                className: 'btn-back',
                url: '/slj/sales-receipt'
            }]}
		/>
		{contextHolder}
			<div className="content-area">
				<div className="formAreaStyle">
					{ createNewFormSection() }		
				</div>
			</div>
			<div className="formSubmitArea">
				<div className="formSubmitInnerArea">
					<Button
						type="primary"
						size="large"
						style={{ width: '170px', backgroundColor: 'grey' }}
						onClick={formik.handleReset}
					>
						Reset Fields
					</Button>
					<Button
						type="primary"
						size="large"
						style={{ width: '170px', backgroundColor: '#1f43e5' }}
						onClick={formik.handleSubmit}
					>
						Submit
					</Button>
				</div>
			</div>
		</>
	);


}

export default SalesReceiptCreate;