import React, { useState } from 'react';
import { Button, Checkbox, Col, Input, Row, notification, Select } from 'antd';
import { CloseCircleOutlined,CheckCircleFilled, WarningOutlined } from '@ant-design/icons';
import { useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import CostCenterImage from '../../assets/images/cost-center.svg';
import '../../css/container.css';
import '../../css/SmallComponents.css';
import httpCall from '../../utils/api';
import costCenterFormFields from './costCenterFormFields';
import PageTitle from '../common/PageTitle';

const { Option } = Select;

const CostCenterForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const [selectedDocumentType, setSelectedDocumentType] = useState(null);

  React.useEffect(() => {
    if (location?.state?.id) {
      httpCall({
        method: 'GET',
        url: `/api/costcenter/get?id=${location?.state?.id}`,
      })
        .then((response) => {
          formik.setValues(response.data[0]);
        })
        .catch((error) => {
          console.error(error);
          openErrorNotification('Failed to load cost center details');
        });
    }
  }, [location?.state?.id]);

  const openNotification = (description) => {
    api.open({
      message: 'Success!',
      duration: 2,
      description: description,
      icon: <CheckCircleFilled style={{ color: '#808000' }} />,
    });
  };

  const openErrorNotification = (description) => {
    api.error({
      message: 'Error!',
      duration: 4,
      description: description,
    });
  };

  const createCostCenter = async (formData, handleReset) => {
    try {
      const response = await httpCall({
        method: 'POST',
        url: '/api/costcenter/create',
        payload: formData,
      });

      if (response.data.message) {
        openNotification('Cost Center Created Successfully.');
        handleReset();
      }
    } catch (error) {
      console.error(error);
      const errorMessage = error.response?.data?.error || 'An unexpected error occurred.';
      openErrorNotification(errorMessage);
    }
  };

  const updateCostCenter = async (formData) => {
    try {
      const response = await httpCall({
        method: 'PUT',
        url: '/api/costcenter/edit',
        payload: formData,
      });

      if (response.data.success) {
        openNotification('Cost Center Details Updated Successfully.');
        setTimeout(() => navigate('/slj/cost-center'), 2500);
      }
    } catch (error) {
      console.error(error);
      const errorMessage = error.response?.data?.error || 'An unexpected error occurred.';
      openErrorNotification(errorMessage);
    }
  };

  const formik = useFormik({
    initialValues: {
      cost_center_name: '',
      address_1: '',
      address_2: '',
      phone: '',
      emergency_contact_no: '',
      email: '',
      pan_card_no: '',
      active: false,
      bank_details_1: '',
      account_details_1: '',
      bank_details_1_branch_details: '',
      bank_details_1_ifsc_code: '',
      prefix_no: '',
      document_type: '',
      document_file: null,
    },
    validate: (values) => {
      const errors = {};

      if (!values.cost_center_name) {
        errors.cost_center_name = 'Cost center name is required';
      }
      if (!/^\d{10}$/.test(values.emergency_contact_no)) {
        errors.emergency_contact_no = 'Emergency Contact No. must be exactly 10 digits';
      }
      if (!values.phone) {
        errors.phone = 'Phone number is required';
      } else if (!/^\d{10}$/.test(values.phone)) {
        errors.phone = 'Phone number must be exactly 10 digits';
      }
      if (selectedDocumentType && !values.document_file) {
        errors.document_file = 'Please upload the selected document';
      }

      return errors;
    },
    onSubmit: (values, { resetForm }) => {
      const formData = new FormData();
      // Object.keys(values).forEach((key) => {
      //   if (key === 'document_file') {
      //     formData.append(key, values[key]); // add file to form data
      //   } else {
      //     formData.append(key, values[key]);
      //   }
      // });

      if (location?.state?.id) {
        updateCostCenter(values); // Update function to handle FormData
      } else {
        createCostCenter(values, resetForm); // Create function to handle FormData
      }
    },
  });

  const renderFormFields = (fields) => {
    return (
      <>
        {fields.map((section, index) => (
          <Row key={index} gutter={16} className="gutter-row">
            {section.map((field, idx) => (
              <Col span={field.colSpan} className="gutter-box" key={idx}>
                <div>
                  <span className="hintText">{field.label}</span>
                  {field.isRequired && <span className="mandatory"></span>}
                </div>

                {field.type === 'file' ? (
                  <Input
                    type="file"
                    id="document_file"
                    style={{ width: '100%' }}
                    onChange={(event) => {
                      formik.setFieldValue('document_file', event.currentTarget.files[0]);
                    }}
                  />
                ) : (
                  // other field types (text, checkbox, etc.)
                  <Input
                    id={field.id}
                    style={{ width: '100%' }}
                    onChange={formik.handleChange}
                    value={formik.values[field.id]}
                  />
                )}

                {formik.touched[field.id] && formik.errors[field.id] && (
                  <div>
                    <WarningOutlined style={{ color: 'red' }} />
                    <span
                      style={{
                        fontFamily: 'Poppins',
                        fontSize: '12px',
                        color: 'red',
                        paddingLeft: '5px',
                      }}
                    >
                      {formik.errors[field.id]}
                    </span>
                  </div>
                )}
              </Col>
            ))}
          </Row>
        ))}
      </>
    );
  };

  return (
    <>
      <PageTitle
        imagesrc={CostCenterImage}
        pageTitle="Create Cost Center"
        buttonList={[
          {
            buttonName: "Back",
            className: "btn-back",
            url: "/slj/cost-center",
          },
        ]}
      />

      {contextHolder}

      <div className="content-area">
        <div className="formAreaStyle">
          {renderFormFields(costCenterFormFields.section1)}

          <Row gutter={16}>
            <Col span={12}>
              <span className="hintText">Document Type</span>
              <Select
                style={{ width: "100%" }}
                onChange={(value) => {
                  setSelectedDocumentType(value);
                  formik.setFieldValue("document_type", value);
                }}
                placeholder="Select Document Type"
              >
                <Option value="PAN">PAN</Option>
                <Option value="Driving Licence">Driving Licence</Option>
                <Option value="Passport">Passport</Option>
                <Option value="Aadhar">Aadhar</Option>
              </Select>
            </Col>

            {/* File Upload Field with Delete Icon */}
            {/* <Col span={12}>
              <span className="hintText">Upload Document</span>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Input
                  type="file"
                  id="document_file"
                  style={{ width: "calc(100% - 24px)" }}
                  onChange={(event) => {
                    formik.setFieldValue(
                      "document_file",
                      event.currentTarget.files[0]
                    );
                  }}
                />                
                {formik.values.document_file && (
                  <CloseCircleOutlined
                    style={{
                      fontSize: "20px",
                      color: "red",
                      marginLeft: "8px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      formik.setFieldValue("document_file", null);
                    }}
                  />
                )}
              </div>
            </Col> */}
          </Row>
        </div>
      </div>

      <div className="formSubmitArea">
        <div className="formSubmitInnerArea">
          <Button
            type="primary"
            size="large"
            style={{ width: "170px", backgroundColor: "grey" }}
            onClick={formik.handleReset}
          >
            Reset Fields
          </Button>
          <Button
            type="primary"
            size="large"
            style={{ width: "170px", backgroundColor: "#1f43e5" }}
            onClick={formik.handleSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
    </>
  );
};

export default CostCenterForm;
