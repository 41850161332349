import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Input, Row, Select, Space } from "antd";
import PurchaseImage from '../../assets/images/material-return.svg';
import "../../css/container.css";
import "../../css/SmallComponents.css";
import httpCall from "../../utils/api";
import { useFormik } from "formik";
import PageTitle from "../common/PageTitle";
import { useLocation, useNavigate } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { QRCodeSVG } from "qrcode.react";
import { QrcodeOutlined } from "@ant-design/icons";

const { Option } = Select;

const QrGeneratorForm = () => {
  const componentRef = useRef();
  const [itemDataList, setItemDataList] = useState([]);
  const [itemDataOptions, setItemDataOptions] = useState([]);

  const calculateFinalAmount = (values) => {
    // Parse all input values to ensure they're numbers
    const weight = parseFloat(values.weight || 0);
    const stoneWeight = parseFloat(values.stone_weight || 0);
    const stoneAmount = parseFloat(values.stone_amount || 0);
    const waste = parseFloat(values.waste || 0);
    const mcAmount = parseFloat(values.mc_amount || 0);
    const currentRate = parseFloat(values.current_rate || 0);
    const igst = parseFloat(values.igst || 0);
  
    // Calculate net weight
    const netWeight = weight - stoneWeight;
  
    // Calculate wastage
    let wastageAmount = 0;
    if (values.waste_unit === '%') {
      wastageAmount = (netWeight * waste) / 100;
    } else {
      wastageAmount = waste;
    }
  
    // Calculate final weight including wastage
    const finalWeight = netWeight + wastageAmount;
  
    // Calculate gold value
    const goldValue = finalWeight * currentRate;
  
    // Calculate subtotal (before GST)
    const subtotal = goldValue + stoneAmount + mcAmount;
  
    // Calculate CGST and SGST (each is half of IGST)
    // Note: IGST is the total GST percentage (e.g., 3% for 1.5% CGST + 1.5% SGST)
    const gstAmount = (subtotal * igst) / 100;
  
    // Calculate final total (including GST)
    const total = subtotal + gstAmount;
  
    return Math.round(total * 100) / 100; // Round to 2 decimal places
  }


  const itemFormik = useFormik({
    initialValues: {
      item_id: "",
      item_name: "",
      weight: "",
      net_weight: "",
      pcs: '',
      stone_weight: "",
      stone_amount: "",
      waste: "",
      igst: "", // Default GST rate
      waste_unit: "g",
      huid: "",
      mc_amount: "",
      current_rate: "",
      final_amount: "",
    },
    enableReinitialize: true,
  });

  // Effect to update final amount when any relevant value changes
  useEffect(() => {
    const finalAmount = calculateFinalAmount(itemFormik.values);
    itemFormik.setFieldValue('final_amount', finalAmount);
  }, [
    itemFormik.values.weight,
    itemFormik.values.stone_weight,
    itemFormik.values.stone_amount,
    itemFormik.values.waste,
    itemFormik.values.pcs,
    itemFormik.values.waste_unit,
    itemFormik.values.mc_amount,
    itemFormik.values.current_rate,
    itemFormik.values.igst,
  ]);

  // Fetch item data on component mount
  useEffect(() => {
    fetchItemDataList();
  }, []);

  const fetchItemDataList = async () => {
    try {
      const response = await httpCall({
        method: "GET",
        url: "/api/item/sale/list",
      });

      if (response?.data) {
        setItemDataList(response.data);
        setItemDataOptions(
          response.data.map((item) => ({
            label: item.itemname,
            value: item.itemid,
          }))
        );
      }
    } catch (error) {
      console.error("Error retrieving item data list:", error);
    }
  };

  const handleItemChange = (value) => {
    const selectedItem = itemDataList.find(item => item.itemid === value);
    if (selectedItem) {
      // Update all relevant fields with selected item data
      itemFormik.setValues({
        ...itemFormik.values,
        item_name: selectedItem.itemname,
        item_id: selectedItem.itemid,
        weight: selectedItem.weight || "",
        stone_weight: selectedItem.stone_per || "",
        stone_amount: selectedItem.stone_amount || "",
        current_rate: selectedItem.current_rate || "",
        waste: selectedItem.waste || "",
        waste_unit: selectedItem.waste_unit || "g",
        mc_amount:selectedItem.mc_amount || "",
        pcs:selectedItem.pcs || "",
      });
    }
  };

  const wasteTypes = () => (
    <Select
      value={itemFormik.values.waste_unit}
      style={{ width: 60 }}
      onChange={(value) => itemFormik.setFieldValue("waste_unit", value)}
    >
      <Option value="g">g</Option>
      <Option value="%">%</Option>
    </Select>
  );

  const qrCodeContainer = () => {
    // Calculate net weight for QR code
    const grossWeight = parseFloat(itemFormik.values.weight || 0);
    const stoneWeight = parseFloat(itemFormik.values.stone_weight || 0);
    const netWeight = grossWeight - stoneWeight;

    // Prepare QR data with all necessary values
    const qrData = JSON.stringify({
      item_id: itemFormik.values.item_id,
      item_name: itemFormik.values.item_name,
      pcs:itemFormik.values.pcs,
      gross_weight: grossWeight.toFixed(3),
      net_weight: netWeight.toFixed(3),
      stone_weight: stoneWeight.toFixed(3),
      stone_amount: itemFormik.values.stone_amount,
      waste: itemFormik.values.waste,
      waste_unit: itemFormik.values.waste_unit,
      huid: itemFormik.values.huid,
      mc_amount: itemFormik.values.mc_amount,
      current_rate: itemFormik.values.current_rate,
      final_amount: Math.round(itemFormik.values.final_amount)
    });
  
    return (
      <>
        <div style={{display: 'none'}}>
      <div
        ref={componentRef}
        style={{
          display: "flex",
          marginLeft: "10px",
          marginTop: "3px",
          gap: "55px"
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            fontSize: "7px",
            fontFamily: "Tahoma",
            fontWeight: "700",
          }}
        >

              <span>Gr.wt: {grossWeight.toFixed(3)} g</span>
              <span>Nt.wt: {netWeight.toFixed(3)} g</span>
              <span>St.Amt: ₹{itemFormik.values.stone_amount}</span>
              <span>MC: ₹{itemFormik.values.mc_amount}</span>
            </div>
            <div style={{ display: "flex", alignItems: "center", marginTop: "3px" }}>
              <QRCodeSVG value={qrData} size={30} />
              <div
 style={{
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  fontSize: "6px",
  marginLeft: "5px",
  fontFamily: "Tahoma",
  fontWeight: "700",
}}
              >
                <span style={{fontSize:"4.8px"}}>{itemFormik.values.item_name}</span>
                <span>
                  {itemFormik.values.item_id.length > 15
                    ? `${itemFormik.values.item_id.slice(0, 12)}`
                    : itemFormik.values.item_id}
                </span>
                {itemFormik.values.item_id.length > 15 && (
                  <>
                    <span>
                      {itemFormik.values.item_id.slice(12, 25)}
                    </span>
                    <span>
                      {itemFormik.values.item_id.slice(25, 36)}
                    </span>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  

  const renderForm = () => (
    <div style={{ width:"70%", display: 'flex', flexDirection: 'column', gap: '10px'}}>
      <Row gutter={16} className="gutter-row flex-ai-center">
        <Col span={6}>
          <div>
            <span className="lbl-black">Item Name</span>
            <span className="mandatory"></span>
          </div>
        </Col>
        <Col span={12}>
          <Select
            id="item_name"
            placeholder="Select an Item"
            style={{ width: "100%", height: "38px" }}
            options={itemDataOptions}
            value={itemFormik.values.item_id}
            onChange={handleItemChange}
          />
        </Col>
      </Row>
      <Row gutter={16} className="gutter-row flex-ai-center">
        <Col span={6}>
          <div>
            <span className="lbl-black">Gross Weight</span>
            <span className="mandatory"></span>
          </div>
        </Col>
        <Col span={6}>
          <Input
            type="number"
            id="weight"
            suffix="g"
            style={{ width: "100%" }}
            onChange={(e) => {
              itemFormik.handleChange(e);
            }}
            value={itemFormik.values.weight}
          />
        </Col>
        <Col span={6}>
          <div>
            <span className="lbl-black">PCS</span>
            <span className="mandatory"></span>
          </div>
        </Col>
        <Col span={6}>
          <Input
            type="number"
            id="pcs"
            style={{ width: "100%" }}
            onChange={(e) => {
              itemFormik.handleChange(e);
            }}
            value={itemFormik.values.pcs}
          />
        </Col>
      </Row>
      <Row gutter={16} className="gutter-row flex-ai-center">
        <Col span={6}>
          <div>
            <span className="lbl-black">Stone Weight</span>
            <span className="mandatory"></span>
          </div>
        </Col>
        <Col span={6}>
          <Input
            type="number"
            id="stone_weight"
            suffix="g"
            style={{ width: "100%" }}
            onChange={(e) => {
              itemFormik.handleChange(e);
            }}
            value={itemFormik.values.stone_weight}
          />
        </Col>
        <Col span={6}>
          <div>
            <span className="lbl-black">Stone Amount</span>
            <span className="mandatory"></span>
          </div>
        </Col>
        <Col span={6}>
          <Input
            type="text"
            id="stone_amount"
            prefix="₹"
            style={{ width: "100%", color: "#000000" }}
            onChange={itemFormik.handleChange}
            value={itemFormik.values.stone_amount}
          />
        </Col>
      </Row>

        <Row gutter={16} className="gutter-row flex-ai-center">
          <Col span={6}>
            <div>
            <span className="lbl-black">Net Weight</span>
              <span className="mandatory"></span>
            </div>
          </Col>
          <Col span={6}>
            <Input
              disabled
              type="number"
              suffix="g"
              id="net_weight"
              style={{ width: "100%" }}
              value={parseFloat(itemFormik.values.weight || 0) - parseFloat(itemFormik.values.stone_weight || 0)}
            />
          </Col>
          <Col span={6}>
            <div>
              <span className="lbl-black">Current Market Rate</span>
              <span className="mandatory"></span>
            </div>
          </Col>
          <Col span={6}>
            <Input
              type="text"
              prefix="₹"
              id="current_rate"
              style={{ width: "100%", color: "#000000", padding: '7px 11px' }}
              onChange={(e) => {
                itemFormik.handleChange(e);
              }}
              value={itemFormik.values.current_rate}
            />
          </Col>
        </Row>
        <Row gutter={16} className="gutter-row flex-ai-center">
          <Col span={6}>
            <div>
              <span className="lbl-black">Wastage</span>
              <span className="mandatory"></span>
            </div>
          </Col>
          <Col span={6}>
            <Input
              type="text"
              suffix="g"
              style={{ width: "100%", color: "#000000" }}
              addonAfter={wasteTypes(itemFormik)}
              id="waste"
              onChange={itemFormik.handleChange}
              value={itemFormik.values.waste}
            />
          </Col>
          <Col span={6}>
            <div>
              <span className="lbl-black">GST (SGST + CGST)</span>
              <span className="mandatory"></span>
            </div>
          </Col>
          <Col span={6}>
  <Input
    type="number"
    id="igst"
    style={{ width: "100%" }}
    suffix="%"
    onChange={(e) => {
      itemFormik.handleChange(e);
    }}
    value={itemFormik.values.igst}
  />
</Col>
        </Row>
        <Row gutter={16} className="gutter-row flex-ai-center">
          <Col span={6}>
            <div>
              <span className="lbl-black">MC Amount</span>
              <span className="mandatory"></span>
            </div>
          </Col>
          <Col span={6}>
            <Input
              type="number"
              id="mc_amount"
              prefix="₹"
              style={{ width: "100%" }}
              onChange={itemFormik.handleChange}
              value={itemFormik.values.mc_amount}
            />
          </Col>
          <Col span={6}>
            <div>
              <span className="lbl-black">HUID</span>
              <span className="mandatory"></span>
            </div>
          </Col>
          <Col span={6}>
            <Input
              type="text"
              id="huid"
              style={{ width: "100%" }}
              onChange={itemFormik.handleChange}
              value={itemFormik.values.huid}
            />
          </Col>
        </Row>
        <Row gutter={16} className="gutter-row flex-ai-center">
          <Col span={6}>
            <div>
              <span className="lbl-black">Final Amount</span>
              <span className="mandatory"></span>
            </div>
          </Col>
          <Col span={6}>
            <Input
              disabled
              type="number"
              prefix="₹"
              id="final_amount"
              style={{ width: "100%", color: "#000000", padding: '7px 11px' }}
              value={Math.round(itemFormik.values.final_amount)}
            />
          </Col>
        </Row>
        <Row gutter={16} className="gutter-row">
          <Col span={24} style={{textAlign: 'center'}}>
            <Space style={{ marginTop: "20px"}}>
              <ReactToPrint
                trigger={() => (
                  <Button
                    icon={<QrcodeOutlined />}
                    type="primary"
                    size="large"
                    style={{ width: '150px', backgroundColor: 'green', borderRadius: '4px',marginLeft:'12%' }}
                  >
                    GenerateQR
                  </Button>
                )}
                content={() => componentRef.current}
              />
            </Space>
          </Col>
        </Row>
      </div>
    );
  

  


  return (
    <>
      <PageTitle
        imagesrc={PurchaseImage}
        pageTitle="Item QR Generator"
        buttonList={[
          {
            buttonName: "Back",
            className: "btn-back",
            url: "/slj/purchase",
          },
        ]}
      />
      <div className="content-area">
        <div className="formAreaStyle" style={{display: 'flex', justifyContent: 'center'}}>
          {renderForm()}
          {qrCodeContainer()}
        </div>
      </div>
    </>
  );
};

export default QrGeneratorForm;